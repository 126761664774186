<template>
  <div id="loading" class="overlay" v-show="isRequestLoading">
    <div class="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <div class="container re-content-fixed">
    <!-- <div class="row">
      <div class="col-md-5">
        <h5 class="text-center">前回</h5>
      </div>
      <div class="col-md-2"></div>
      <div class="col-md-5">
        <h5 class="text-center">最新<small>(※変更分は赤)</small></h5>
      </div>
    </div> -->
    <div class="row">
      <div class="col-md-12">
        <div
          class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
        >
          <h5 class="h5">
            <img
              class="re-icon-lg justify-content-start"
              alt="輸入情報"
              src="../assets/ship.png"
            />
            輸入情報
          </h5>
        </div>
        <div class="row">
          <div class="col-md-5">
            <div class="card re-shadow">
              <h5 class="card-header text-center">前回</h5>
              <div class="card-body">
                <ul v-if="shipDataSet">
                  <li>JOB NO.</li>
                  <ul class="re-ul">
                    <li>{{ shipDataSet.jobNo }}</li>
                  </ul>
                  <li>SHIPPER</li>
                  <ul class="re-ul">
                    <li>{{ shipDataSet.shipper }}</li>
                  </ul>
                  <li>法人番号</li>
                  <ul class="re-ul">
                    <li>{{ shipDataSet.corpNo }}</li>
                  </ul>
                  <li>輸出入者符号</li>
                  <ul class="re-ul">
                    <li>{{ shipDataSet.shipperMark }}</li>
                  </ul>
                  <li>実輸入者名</li>
                  <ul class="re-ul">
                    <li>{{ shipDataSet.shipperName }}</li>
                  </ul>
                  <li>B/L番号</li>
                  <ul
                    class="re-ul"
                    v-for="(bl, blindex) in shipDataSet.blNo"
                    :key="blindex"
                  >
                    <li>{{ bl.no }}</li>
                  </ul>
                  <li>本船名</li>
                  <ul class="re-ul">
                    <li>{{ shipDataSet.voyageName }}</li>
                  </ul>
                  <li>Voyage</li>
                  <ul class="re-ul">
                    <li>{{ shipDataSet.voyage }}</li>
                  </ul>
                  <li>積み地</li>
                  <ul class="re-ul">
                    <li>{{ shipDataSet.pol }}</li>
                  </ul>
                  <li>揚げ地</li>
                  <ul class="re-ul">
                    <li>{{ shipDataSet.pod }}</li>
                  </ul>
                  <li>ETD</li>
                  <ul class="re-ul">
                    <li>{{ shipDataSet.etd }}</li>
                  </ul>
                  <li>ETA</li>
                  <ul class="re-ul">
                    <li>{{ shipDataSet.eta }}</li>
                  </ul>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-2 d-flex align-items-center">
            <img
              class="re-icon-lg mx-auto d-block"
              alt="check"
              src="../assets/right2.png"
            />
          </div>
          <div class="col-md-5">
            <div class="card re-shadow">
              <h5 class="card-header text-center">最新<small>(※変更分は赤)</small></h5>
              <div class="card-body">
                <ul v-if="newShipDataSet">
                  <li>JOB NO.</li>
                  <ul class="re-ul">
                    <li v-html="CompareItem('ship', 'jobNo')"></li>
                  </ul>
                  <li>SHIPPER</li>
                  <ul class="re-ul">
                    <li v-html="CompareItem('ship', 'shipper')"></li>
                  </ul>
                  <li>法人番号</li>
                  <ul class="re-ul">
                    <li v-html="CompareItem('ship', 'corpNo')"></li>
                  </ul>
                  <li>輸出入者符号</li>
                  <ul class="re-ul">
                    <li v-html="CompareItem('ship', 'shipperMark')"></li>
                  </ul>
                  <li>実輸入者名</li>
                  <ul class="re-ul">
                    <li v-html="CompareItem('ship', 'shipperName')"></li>
                  </ul>
                  <li>B/L番号</li>
                  <ul class="re-ul" v-for="(bl, blindex) in CompareBlNo()" :key="blindex">
                    <li v-html="bl"></li>
                  </ul>
                  <li>本船名</li>
                  <ul class="re-ul">
                    <li v-html="CompareItem('ship', 'voyageName')"></li>
                  </ul>
                  <li>Voyage</li>
                  <ul class="re-ul">
                    <li v-html="CompareItem('ship', 'voyage')"></li>
                  </ul>
                  <li>積み地</li>
                  <ul class="re-ul">
                    <li v-html="CompareItem('ship', 'pol')"></li>
                  </ul>
                  <li>揚げ地</li>
                  <ul class="re-ul">
                    <li v-html="CompareItem('ship', 'pod')"></li>
                  </ul>
                  <li>ETD</li>
                  <ul class="re-ul">
                    <li v-html="CompareItem('ship', 'etd')"></li>
                  </ul>
                  <li>ETA</li>
                  <ul class="re-ul">
                    <li v-html="CompareItem('ship', 'eta')"></li>
                  </ul>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div
          class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
        >
          <h5 class="h5">
            <img
              class="re-icon-lg justify-content-start"
              alt="商品情報"
              src="../assets/item.png"
            />
            商品情報
          </h5>
        </div>
        <div class="row">
          <div class="col-md-5">
            <div class="card re-shadow">
              <h5 class="card-header text-center">前回</h5>
              <div class="card-body">
                <ul v-if="itemDataSet">
                  <li>代表的品名</li>
                  <ul class="re-ul">
                    <li>{{ itemDataSet.name }}</li>
                  </ul>
                  <li>特別商品説明</li>
                  <ul class="re-ul">
                    <li>{{ itemDataSet.note }}</li>
                  </ul>
                  <li>個数1</li>
                  <ul class="re-ul">
                    <li>{{ itemDataSet.quantity }}</li>
                  </ul>
                  <li>個数2</li>
                  <ul class="re-ul">
                    <li>{{ itemDataSet.quantity2 }}</li>
                  </ul>
                  <li>メモ</li>
                  <ul class="re-ul">
                    <li>{{ itemDataSet.memo }}</li>
                  </ul>
                  <li>Weight</li>
                  <ul class="re-ul">
                    <li>{{ itemDataSet.weight }}</li>
                  </ul>
                  <li>Measure</li>
                  <ul class="re-ul">
                    <li>{{ itemDataSet.measure }}</li>
                  </ul>
                  <li>コンテナ</li>
                  <ul
                    class="re-ul"
                    v-for="(conteiner, cindex) in itemDataSet.containerDataSet"
                    :key="cindex"
                  >
                    <li>{{ conteiner.no }}({{ conteiner.type }})</li>
                  </ul>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-2 d-flex align-items-center">
            <img
              class="re-icon-lg mx-auto d-block"
              alt="check"
              src="../assets/right2.png"
            />
          </div>
          <div class="col-md-5">
            <div class="card re-shadow">
              <h5 class="card-header text-center">最新<small>(※変更分は赤)</small></h5>
              <div class="card-body">
                <ul v-if="newItemDataSet">
                  <li>代表的品名</li>
                  <ul class="re-ul">
                    <li v-html="CompareItem('item', 'name')"></li>
                  </ul>
                  <li>特別商品説明</li>
                  <ul class="re-ul">
                    <li v-html="CompareItem('item', 'note')"></li>
                  </ul>
                  <li>個数1</li>
                  <ul class="re-ul">
                    <li v-html="CompareItem('item', 'quantity')"></li>
                  </ul>
                  <li>個数2</li>
                  <ul class="re-ul">
                    <li v-html="CompareItem('item', 'quantity2')"></li>
                  </ul>
                  <li>メモ</li>
                  <ul class="re-ul">
                    <li v-html="CompareItem('item', 'memo')"></li>
                  </ul>
                  <li>Weight</li>
                  <ul class="re-ul">
                    <li v-html="CompareItem('item', 'weight')"></li>
                  </ul>
                  <li>Measure</li>
                  <ul class="re-ul">
                    <li v-html="CompareItem('item', 'measure')"></li>
                  </ul>
                  <li>コンテナ</li>
                  <ul
                    class="re-ul"
                    v-for="(conteiner, cindex) in CompareContainers()"
                    :key="cindex"
                  >
                    <li v-html="conteiner"></li>
                  </ul>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div
          class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
        >
          <h5 class="h5">
            <img
              class="re-icon-lg justify-content-start"
              alt="配送希望"
              src="../assets/delivery.png"
            />
            配送希望
          </h5>
        </div>
        <div class="row">
          <div class="col-md-5">
            <div class="card re-shadow">
              <h5 class="card-header text-center">前回</h5>
              <div class="card-body">
                <ul v-if="deliveryDataSet">
                  <li>納品希望日</li>
                  <ul class="re-ul">
                    <li>{{ deliveryDataSet.preferredDate }}</li>
                  </ul>
                  <li>納品希望時間帯</li>
                  <ul class="re-ul">
                    <li>{{ deliveryDataSet.preferredTime }}</li>
                  </ul>
                  <li>納品先</li>
                  <ul>
                    <li>会社名</li>
                    <ul class="re-ul">
                      <li>{{ deliveryDataSet.name }}</li>
                    </ul>
                    <li>郵便番号</li>
                    <ul class="re-ul">
                      <li>{{ deliveryDataSet.zip }}</li>
                    </ul>
                    <li>住所１</li>
                    <ul class="re-ul">
                      <li>{{ deliveryDataSet.adress1 }}</li>
                    </ul>
                    <li>住所２</li>
                    <ul class="re-ul">
                      <li>{{ deliveryDataSet.adress2 }}</li>
                    </ul>
                    <li>電話番号</li>
                    <ul class="re-ul">
                      <li>{{ deliveryDataSet.tel }}</li>
                    </ul>
                    <li>担当者</li>
                    <ul class="re-ul">
                      <li>{{ deliveryDataSet.person }}</li>
                    </ul>
                  </ul>
                  <li>備考</li>
                  <ul class="re-ul">
                    <li>{{ deliveryDataSet.note }}</li>
                  </ul>
                  <li>気付</li>
                  <ul class="re-ul">
                    <li>{{ deliveryDataSet.care }}</li>
                  </ul>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-2 d-flex align-items-center">
            <img
              class="re-icon-lg mx-auto d-block"
              alt="check"
              src="../assets/right2.png"
            />
          </div>
          <div class="col-md-5">
            <div class="card re-shadow">
              <h5 class="card-header text-center">最新<small>(※変更分は赤)</small></h5>
              <div class="card-body">
                <ul v-if="newDeliveryDataSet">
                  <li>納品希望日</li>
                  <ul class="re-ul">
                    <li v-html="CompareItem('delivery', 'preferredDate')"></li>
                  </ul>
                  <li>納品希望時間帯</li>
                  <ul class="re-ul">
                    <li v-html="CompareItem('delivery', 'preferredTime')"></li>
                  </ul>
                  <li>納品先</li>
                  <ul>
                    <li>会社名</li>
                    <ul class="re-ul">
                      <li v-html="CompareItem('delivery', 'name')"></li>
                    </ul>
                    <li>郵便番号</li>
                    <ul class="re-ul">
                      <li v-html="CompareItem('delivery', 'zip')"></li>
                    </ul>
                    <li>住所１</li>
                    <ul class="re-ul">
                      <li v-html="CompareItem('delivery', 'adress1')"></li>
                    </ul>
                    <li>住所２</li>
                    <ul class="re-ul">
                      <li v-html="CompareItem('delivery', 'adress2')"></li>
                    </ul>
                    <li>電話番号</li>
                    <ul class="re-ul">
                      <li v-html="CompareItem('delivery', 'tel')"></li>
                    </ul>
                    <li>担当者</li>
                    <ul class="re-ul">
                      <li v-html="CompareItem('delivery', 'person')"></li>
                    </ul>
                  </ul>
                  <li>備考</li>
                  <ul class="re-ul">
                    <li v-html="CompareItem('delivery', 'note')"></li>
                  </ul>
                  <li>気付</li>
                  <ul class="re-ul">
                    <li v-html="CompareItem('delivery', 'care')"></li>
                  </ul>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <br />
    <div class="row d-flex justify-content-center">
      <div class="col-md-4">
        <button
          type="button"
          class="btn btn-primary btn-lg btn-block re-shadow"
          @submit="save()"
          @click="save()"
        >
          確認
        </button>
      </div>
    </div>
  </div>
  <br />
  <br />
  <transition-group name="bounce" tag="div" appear>
    <messageModal
      v-if="messageModalStatus.show === true"
      :messageModalStatus="messageModalStatus"
      @messagemodal-close="messageModalClose"
    />
  </transition-group>
</template>
<script>
import HttpClient from "../modules/http";
import ReceptionDataSet from "../modules/receptionData";
import MessageModal from "./MessageModal.vue";

export default {
  components: { MessageModal },
  mixins: [],
  props: [],
  emits: [],
  computed: {
    isRequestLoading: function () {
      if (!this.isEditLoading) {
        return false;
      } else {
        return true;
      }
    },
  },
  data: function () {
    const initDataSet = new ReceptionDataSet();
    return {
      checkAuthShow: false,
      callType: "new",
      shipType: 1,
      receptionNo: "",
      readonly: false,
      receptionDataSet: initDataSet.makeInitReceptionDataSet(),
      newReceptionDataSet: initDataSet.makeInitReceptionDataSet(),
      shipDataSet: initDataSet.makeInitShipDataSet(),
      itemDataSet: initDataSet.makeInitItemDataSet(),
      deliveryDataSet: initDataSet.makeInitDeliveryDataSet(),
      newShipDataSet: initDataSet.makeInitShipDataSet(),
      newItemDataSet: initDataSet.makeInitItemDataSet(),
      newDeliveryDataSet: initDataSet.makeInitDeliveryDataSet(),
      isEditLoading: false,
      messageModalStatus: {
        show: false,
        message: [],
        messageType: "info",
        loading: false,
        button: false,
        redirect:""
      },
      httpClient: new HttpClient(),
      editCount:0,
    };
  },
  mounted: function () {},
  created: function () {
    this.checkAuth();
  },
  watch: {
    apiSendStatus: {
      handler: function () {},
      deep: true,
    },
  },
  methods: {
    checkAuth() {
      this.isLoading = true;
      this.checkAuthShow = true;
      const url =
        "/auth/check/?agentId=" +
        this.$cookie.getCookie("agentId") +
        "&accountName=" +
        this.$cookie.getCookie("accountName");
      this.httpClient.get(
        url,
        this.$cookie.getCookie("token"),
        this.onSuccessAuth,
        this.onErrorAuth
      );
    },
    onSuccessAuth() {
      this.isLoading = false;
      this.checkAuthShow = false;
      console.log("onSuccessAuth")
      console.log(this.$store.getters.receptionDetailData)
      if (this.$store.getters.receptionDetailData) {
        this.isLoading = true;
        this.receptionNo = this.$store.getters.receptionDetailData.receptionNo;
        this.callType = "edit";
        const url =
          "/rep/?agentId=" +
          this.$cookie.getCookie("agentId") +
          "&accountName=" +
          this.$cookie.getCookie("accountName") +
          "&receptionNo=" +
          this.receptionNo;
        this.httpClient.get(
          url,
          this.$cookie.getCookie("token"),
          this.onSuccessOldDetail,
          this.onErrorAuth
        );
      }
    },
    onErrorAuth(error) {
      this.isLoading = false;
      if (
        error != null &&
        error.response  != null &&
        "status" in error.response &&
        error.response.status == 401
      ) {
        this.messageModalStatus.message.push(
          "セッションの有効期限がきれました。再度ログインお願いします。"
        );
      } else {
        this.messageModalStatus.message.push(
          "エラーが発生しました。再度ログインお願いします。"
        );
      }
      this.messageModalStatus.redirect = "login"
      this.messageModalStatus.show = true;
      this.messageModalStatus.messageType = "warn";
      this.messageModalStatus.button = true;
    },
    messageModalClose() {
      this.messageModalStatus.show = false;
      this.$router.push({ name: this.messageModalStatus.redirect });
    },
    onSuccessOldDetail(response) {
      this.isLoading = false;
      this.receptionDataSet = JSON.stringify(response.data.link);
      this.receptionDataSet = JSON.parse(this.receptionDataSet);
      this.shipDataSet = response.data.link[0].ship[0];
      this.itemDataSet = response.data.link[0].item[0];
      this.deliveryDataSet = response.data.link[0].delivery[0];

      const url =
        "/repr/?agentId=" +
        this.$cookie.getCookie("agentId") +
        "&accountName=" +
        this.$cookie.getCookie("accountName") +
        "&receptionNo=" +
        this.receptionNo;
      this.httpClient.get(
        url,
        this.$cookie.getCookie("token"),
        this.onSuccessNewDetail,
        this.onErrorAuth
      );
    },
    onSuccessNewDetail(response) {
      this.isLoading = false;
      this.newReceptionDataSet = JSON.stringify(response.data.link);
      this.newReceptionDataSet = JSON.parse(this.newReceptionDataSet);
      this.newShipDataSet = response.data.link[0].ship[0];
      this.newItemDataSet = response.data.link[0].item[0];
      this.newDeliveryDataSet = response.data.link[0].delivery[0];
    },
    CompareItem(type, item) {
      switch (type) {
        case "ship":
          if(this.shipDataSet[item] == null){
            this.shipDataSet[item] = ""
          }
          if(this.newShipDataSet[item] == null){
            this.newShipDataSet[item] = ""
          }
          if (this.shipDataSet[item] != this.newShipDataSet[item]) {
            if (this.newShipDataSet[item] != "") {
              return (
                '<span class="re-font-color-red">' + this.newShipDataSet[item] + "</span>"
              );
            } else if (this.shipDataSet[item] != "" && this.newShipDataSet[item] == "") {
              return '<span class="re-font-color-red">-</span>';
            } else {
              return "";
            }
          } else {
            return this.newShipDataSet[item];
          }
        case "item":
          if (this.itemDataSet[item] != this.newItemDataSet[item]) {
            if (this.newItemDataSet[item] != "") {
              return (
                '<span class="re-font-color-red">' + this.newItemDataSet[item] + "</span>"
              );
            } else if (this.itemDataSet[item] != "" && this.newItemDataSet[item] == "") {
              return '<span class="re-font-color-red">-</span>';
            } else {
              return "";
            }
          } else {
            return this.newItemDataSet[item];
          }
        case "delivery":
          if (this.deliveryDataSet[item] != this.newDeliveryDataSet[item]) {
            if (this.newDeliveryDataSet[item] != "") {
              return (
                '<span class="re-font-color-red">' +
                this.newDeliveryDataSet[item] +
                "</span>"
              );
            } else if (
              this.deliveryDataSet[item] != "" &&
              this.newDeliveryDataSet[item] == ""
            ) {
              return '<span class="re-font-color-red">-</span>';
            } else {
              return "";
            }
          } else {
            return this.newDeliveryDataSet[item];
          }
      }
    },
    getLoopNum(type) {
      switch (type) {
        case "bl":
          if (this.shipDataSet["blNo"].length <= this.newShipDataSet["blNo"].length) {
            return this.newShipDataSet[type].length;
          } else {
            return this.shipDataSet[type].length;
          }
        case "container":
          if (
            this.itemDataSet["containerDataSet"].length <=
            this.newItemDataSet["containerDataSet"].length
          ) {
            return this.newItemDataSet["containerDataSet"].length;
          } else {
            return this.itemDataSet["containerDataSet"].length;
          }
      }
    },
    CompareBlNo() {
      const type = "blNo";
      let strArray = [];
      if (type in this.shipDataSet && type in this.newShipDataSet) {
        if (this.shipDataSet.blNo.length == 0 && this.newShipDataSet.blNo.length > 0) {
          for (let nidx in this.newShipDataSet.blNo) {
            strArray.push(
              '<span class="re-font-color-red">' +
                this.newShipDataSet.blNo[nidx].no +
                "</span>"
            );
          }
        }

        if (this.shipDataSet.blNo.length > 0 && this.newShipDataSet.blNo.length == 0) {
          for (let i = 0; i < this.shipDataSet.blNo.lenght; i++) {
            strArray.push('<span class="re-font-color-red">-</span>');
          }
        }

        if (this.shipDataSet.blNo.length > 0 && this.newShipDataSet.blNo.length > 0) {
          let same = false;
          let oSameArray = [];
          let nSameArray = [];
          let oSabunArray = [];
          let nSabunArray = [];
          for (let oidx in this.shipDataSet.blNo) {
            same = false;
            for (let nidx in this.newShipDataSet.blNo) {
              if (this.shipDataSet.blNo[oidx].no == this.newShipDataSet.blNo[nidx].no) {
                same = true;
              }
            }

            if (same) {
              oSameArray.push(this.shipDataSet.blNo[oidx].no);
            } else {
              oSabunArray.push(this.shipDataSet.blNo[oidx].no);
            }
          }

          for (let nidx in this.newShipDataSet.blNo) {
            same = false;
            for (let oidx in this.shipDataSet.blNo) {
              if (this.newShipDataSet.blNo[nidx].no == this.shipDataSet.blNo[oidx].no) {
                same = true;
              }
            }

            if (same) {
              nSameArray.push(this.newShipDataSet.blNo[nidx].no);
            } else {
              nSabunArray.push(this.newShipDataSet.blNo[nidx].no);
            }
          }
          for (let bl of oSameArray) {
            strArray.push(bl);
          }

          if (oSabunArray.length > 0) {
            for (let i = 0; i < oSabunArray.length; i++) {
              strArray.push('<span class="re-font-color-red">-</span>');
            }
          }

          if (nSabunArray.length > 0) {
            for (let i = 0; i < nSabunArray.length; i++) {
              strArray.push(
                '<span class="re-font-color-red">' + nSabunArray[i] + "</span>"
              );
            }
          }
          return strArray;
        }
      }
    },
    CompareContainers() {
      const type = "containerDataSet";
      let strArray = [];
      if (type in this.itemDataSet && type in this.newItemDataSet) {
        if (
          this.itemDataSet.containerDataSet.length == 0 &&
          this.newItemDataSet.containerDataSet.length > 0
        ) {
          for (let nidx in this.newItemDataSet.containerDataSet) {
            strArray.push(
              '<span class="re-font-color-red">' +
                this.newItemDataSet.containerDataSet[nidx].no +
                "(" +
                this.newItemDataSet.containerDataSet[nidx].type +
                ")" +
                "</span>"
            );
          }
        }

        if (
          this.itemDataSet.containerDataSet.length > 0 &&
          this.newItemDataSet.containerDataSet.length == 0
        ) {
          for (let i = 0; i < this.itemDataSet.containerDataSet.length; i++) {
            strArray.push('<span class="re-font-color-red">-</span>');
          }
        }

        if (
          this.itemDataSet.containerDataSet.length > 0 &&
          this.newItemDataSet.containerDataSet.length > 0
        ) {
          let same = false;
          let oSameArray = [];
          let nSameArray = [];
          let oSabunArray = [];
          let nSabunArray = [];
          for (let oidx in this.itemDataSet.containerDataSet) {
            same = false;
            for (let nidx in this.newItemDataSet.containerDataSet) {
              if (
                this.itemDataSet.containerDataSet[oidx].no ==
                  this.newItemDataSet.containerDataSet[nidx].no &&
                this.itemDataSet.containerDataSet[oidx].type ==
                  this.newItemDataSet.containerDataSet[nidx].type
              ) {
                same = true;
              }
            }

            if (same) {
              oSameArray.push(
                this.itemDataSet.containerDataSet[oidx].no +
                  "(" +
                  this.itemDataSet.containerDataSet[oidx].type +
                  ")"
              );
            } else {
              oSabunArray.push(
                this.itemDataSet.containerDataSet[oidx].no +
                  "(" +
                  this.itemDataSet.containerDataSet[oidx].type +
                  ")"
              );
            }
          }

          for (let nidx in this.newItemDataSet.containerDataSet) {
            same = false;
            for (let oidx in this.itemDataSet.containerDataSet) {
              if (
                this.newItemDataSet.containerDataSet[nidx].no ==
                  this.itemDataSet.containerDataSet[oidx].no &&
                this.itemDataSet.containerDataSet[oidx].type ==
                  this.newItemDataSet.containerDataSet[nidx].type
              ) {
                same = true;
              }
            }

            if (same) {
              nSameArray.push(
                this.newItemDataSet.containerDataSet[nidx].no +
                  "(" +
                  this.newItemDataSet.containerDataSet[nidx].type +
                  ")"
              );
            } else {
              nSabunArray.push(
                this.newItemDataSet.containerDataSet[nidx].no +
                  "(" +
                  this.newItemDataSet.containerDataSet[nidx].type +
                  ")"
              );
            }
          }
          for (let bl of oSameArray) {
            strArray.push(bl);
          }

          if (oSabunArray.length > 0) {
            for (let i = 0; i < oSabunArray.length; i++) {
              strArray.push('<span class="re-font-color-red">-</span>');
            }
          }

          if (nSabunArray.length > 0) {
            for (let i = 0; i < nSabunArray.length; i++) {
              strArray.push(
                '<span class="re-font-color-red">' + nSabunArray[i] + "</span>"
              );
            }
          }
          return strArray;
        }
      }
    },
    save() {
      this.messageModalStatus.message = [];
      this.messageModalStatus.message.push("差分反映中です。");
      this.messageModalStatus.messageType = "info";
      this.messageModalStatus.loading = true;
      this.messageModalStatus.button = false;
      this.messageModalStatus.show = true;

      this.editCount = 0;

      this.edit(this.receptionNo, "ships", this.newShipDataSet, "/ship/");
      this.edit(this.receptionNo, "items", this.newItemDataSet, "/item/");
      this.edit(this.receptionNo, "deliveries", this.newDeliveryDataSet, "/delivery/");
    },
    edit(receptionNo, type, dataSet, uri) {
      const convertType = { ships: "ship", items: "item", deliveries: "delivery" };
      const params = {
        agentId: this.$cookie.getCookie("agentId"),
        accountName: this.$cookie.getCookie("accountName"),
        receptionNo: receptionNo,
      };
      params[type] = [dataSet];

      if (this.receptionDataSet[0][convertType[type]].length > 0) {
        this.httpClient.edit(
          uri,
          this.$cookie.getCookie("token"),
          params,
          this.onSuccessEdit,
          this.onErrorAuth
        );
      } else {
        this.httpClient.save(
          uri,
          this.$cookie.getCookie("token"),
          params,
          this.onSuccessEdit,
          this.onErrorAuth
        );
      }
    },
    onSuccessEdit(){
      this.editCount ++;
      if(this.editCount >=3){
        this.messageModalStatus.message = [];
        this.messageModalStatus.message.push("差分反映しました。");
        this.messageModalStatus.messageType = "info";
        this.messageModalStatus.loading = false;
        this.messageModalStatus.button = true;
        this.messageModalStatus.show = true;
        this.messageModalStatus.redirect = "list"
      }
    }
  },
};
</script>
