<template>
  <br />
  <br />
  <br />
  <div class="d-block d-md-none container">
    <div class="row">
      <div class="col-md-9">
        <div
          class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3"
        >
          <h6 class="h6 re-shadow">
            一覧
            <img
              class="re-icon-btn-sm justify-content-start"
              @click="reloadList()"
              alt="更新"
              src="../../assets/reload.png"
            />
          </h6>
        </div>
      </div>
    </div>
    <div class="row">
      <table
        class="table table-striped table-hover table-bordered table-sm table-responsive"
        id="mobiletable"
      >
        <thead>
          <tr>
            <th
              class="align-middle re-list-head text-center mobilehandler"
              rowspan="2"
              scope="col"
              @click="sortList(null, 'receptionNo', String)"
            >
              No.
            </th>
            <th
              class="align-middle re-list-head text-center mobilehandler"
              rowspan="2"
              scope="col"
              @click="sortList(null, 'status', String)"
            >
              依頼状況
            </th>
            <th
              class="align-middle re-list-head text-center mobilehandler"
              @click="sortList('ship', 'pol', String)"
              scope="col"
            >
              出港日
            </th>
            <th
              class="align-middle re-list-head text-center mobilehandler"
              @click="sortList('ship', 'eta', String)"
              scope="col"
            >
              形態
            </th>
            <th
              class="align-middle re-list-head text-center mobilehandler"
              rowspan="2"
              @click="sortList('delivery', 'preferredDate', String)"
              scope="col"
            >
              配送希望日
            </th>
          </tr>
          <tr>
            <th
              class="align-middle re-list-head text-center mobilehandler"
              @click="sortList('ship', 'pod', String)"
              scope="col"
            >
              入港日
            </th>
            <th
              class="align-middle re-list-head text-center mobilehandler"
              @click="sortList('item', 'measure', Number)"
              scope="col"
            >
              M3
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="re-pointer"
            v-for="(link, lidx) in getDispList"
            :key="lidx"
            @click="toDetail(lidx)"
          >
            <td class="align-middle text-center" scope="row">
              {{ link.receptionNo }}
            </td>
            <td class="align-middle text-center" scope="row">
              <div v-html="dispConvertStatusName(link.managementStatus)"></div>
            </td>
            <td
              class="align-middle text-center"
              scope="row"
              v-if="link.ship.length > 0"
            >
              <div v-if="link.ship[0].eta == '1900-01-01'">-<br /></div>
              <div v-else>{{ link.ship[0].eta }}<br /></div>
              <div v-if="link.ship[0].etd == '1900-01-01'">-<br /></div>
              <div v-else>{{ link.ship[0].etd }}<br /></div>
            </td>
            <td class="align-middle text-center" scope="row" v-else>
              -<br />
              -
            </td>
            <td class="align-middle text-center" scope="row">
              <div v-if="link.ship.length > 0">
                <div v-if="link.ship[0].shipType == '1'">LCL<br /></div>
                <div v-if="link.ship[0].shipType == '2'">FCL<br /></div>
                <div v-if="link.ship[0].shipType == '3'">AIR<br /></div>
              </div>
              <div v-else>-</div>
              <br />
              <div v-if="link.item.length > 0">
                {{ link.item[0].measure }}
              </div>
              <div v-else>-</div>
            </td>
            <td
              class="align-middle text-center"
              scope="row"
              v-if="link.delivery.length > 0"
            >
              {{ link.delivery[0].preferredDate }} <br />
              {{ link.delivery[0].preferredTime }}
            </td>
            <td class="align-middle text-center" scope="row" v-else>
              希望日をご連絡ください
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="d-none d-md-block container col-md-9 col-xl-9">
    <div class="row">
      <div class="col-md-9 col-xl-9">
        <div
          class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-1"
        >
          <h6 class="h6 re-shadow">
            絞り込み条件
            <img
              v-show="!dispFilters"
              class="re-icon-btn-sm justify-content-start"
              @click="dispFilters = true"
              alt="表示"
              src="../../assets/plus.png"
            />
            <img
              v-show="dispFilters"
              class="re-icon-btn-sm justify-content-start"
              @click="dispFilters = false"
              alt="非表示"
              src="../../assets/minus.png"
            />
          </h6>
        </div>
      </div>
    </div>
    <div class="row" v-show="dispFilters">
      <form>
        <div class="form-row form-inline col-md-12">
          <div class="form-group col-md-6">
            <label for="inputEtaStart" class="col-md-3">入港日</label>
            <input
              type="text"
              class="form-control col-md-3"
              id="inputEtaStart"
              placeholder="yyyy-mm-dd"
              v-bind:readonly="readonly"
              :value="filterData.etaStart"
              @input="filterItem('etaStart', $event.target.value)"
            />
            ～
            <input
              type="text"
              class="form-control col-md-3"
              id="inputEtaEnd"
              placeholder="yyyy-mm-dd"
              v-bind:readonly="readonly"
              :value="filterData.etaEnd"
              @input="filterItem('etaEnd', $event.target.value)"
            />
          </div>
          <div class="form-group col-md-6">
            <label for="inputEtdStart" class="col-md-3">出港日</label>
            <input
              type="text"
              class="form-control col-md-3"
              id="inputEtdStart"
              placeholder="yyyy-mm-dd"
              v-bind:readonly="readonly"
              :value="filterData.etdStart"
              @input="filterItem('etdStart', $event.target.value)"
            />
            ～
            <input
              type="text"
              class="form-control col-md-3"
              id="inputEtdEnd"
              placeholder="yyyy-mm-dd"
              v-bind:readonly="readonly"
              :value="filterData.etdEnd"
              @input="filterItem('etdEnd', $event.target.value)"
            />
          </div>
        </div>
        <div class="form-row form-inline col-md-12">
          <div class="form-group col-md-6">
            <label for="inputMeasureStart" class="col-md-3">Measure</label>
            <input
              type="text"
              class="form-control col-md-3"
              id="inputMeasureStart"
              placeholder=""
              v-bind:readonly="readonly"
              :value="filterData.measureStart"
              @input="filterItem('measureStart', $event.target.value)"
            />
            ～
            <input
              type="text"
              class="form-control col-md-3"
              id="inputMeasureEnd"
              placeholder=""
              v-bind:readonly="readonly"
              :value="filterData.measureEnd"
              @input="filterItem('measureEnd', $event.target.value)"
            />
          </div>
          <div class="form-group col-md-6">
            <label for="inputQuantityStart" class="col-md-3">個数</label>
            <input
              type="text"
              class="form-control col-md-3"
              id="inputQuantityStart"
              placeholder=""
              v-bind:readonly="readonly"
              :value="filterData.quantityStart"
              @input="filterItem('quantityStart', $event.target.value)"
            />
            ～
            <input
              type="text"
              class="form-control col-md-3"
              id="inputQuantityEnd"
              placeholder=""
              v-bind:readonly="readonly"
              :value="filterData.quantityEnd"
              @input="filterItem('quantityEnd', $event.target.value)"
            />
          </div>
        </div>
        <div class="form-row form-inline col-md-12">
          <div class="form-group col-md-6">
            <label for="inputPOL" class="col-md-3">積み地</label>
            <input
              type="text"
              class="form-control col-md-6"
              id="inputPOL"
              placeholder=""
              v-bind:readonly="readonly"
              :value="filterData.pol"
              @input="filterItem('pol', $event.target.value)"
            />
          </div>
          <div class="form-group col-md-6">
            <label for="inputPOD" class="col-md-3">揚げ地</label>
            <input
              type="text"
              class="form-control col-md-6"
              id="inputPOD"
              placeholder=""
              v-bind:readonly="readonly"
              :value="filterData.pod"
              @input="filterItem('pod', $event.target.value)"
            />
          </div>
        </div>
        <div class="form-row form-inline col-md-12">
          <div class="form-group col-md-6">
            <label class="col-md-3" for="checkadAanceBill"> 立替請求書 </label>
            <input
              type="checkbox"
              class="col-md-6"
              id="checkadAanceBill"
              :disabled="readonly"
              :checked="filterData.advanceBill"
              @change="filterItem('advanceBill', $event.target.checked)"
            />
          </div>
          <div class="form-group col-md-6">
            <label class="col-md-3" for="checkBill"> 請求書 </label>
            <input
              type="checkbox"
              class="col-md-6"
              id="checkBill"
              :disabled="readonly"
              :checked="filterData.bill"
              @change="filterItem('bill', $event.target.checked)"
            />
          </div>
        </div>
      </form>
    </div>
    <hr class="re-shadow">
    <div class="row">
      <div class="col-md-12">
        <div
          class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3"
        >
          <h5 class="h5 re-shadow">
            一覧
            <img
              class="re-icon-btn-sm justify-content-start"
              @click="reloadList()"
              alt="更新"
              src="../../assets/reload.png"
            />
          </h5>
        <form class="form-inline">
          <div class="form-group">
            <label for="select-disprow">表示件数：</label>
            <select id="select-disprow" class="form-control" @change="dispRowChange($event.target.value)">
              <option>10</option>
              <option>20</option>
              <option>50</option>
              <option>100</option>
            </select>
          </div>        
        </form>
        </div>
      </div>
    </div>
    <div class="row">
      <table
        class="table table-striped table-hover table-bordered table-responsive-md table-sm row-scrollable"
        id="table"
      >
        <thead>
          <tr>
            <th
              class="align-middle re-list-head text-center handler"
              rowspan="2"
              scope="col"
              @click="sortList(null, 'receptionNo', String)"
            >
              No.
            </th>
            <th
              class="align-middle re-list-head text-center handler"
              rowspan="2"
              scope="col"
              @click="sortList(null, 'status', String)"
            >
              状況
            </th>
            <th
              class="align-middle re-list-head text-center handler"
              rowspan="2"
              scope="col"
              @click="sortList(null, 'ledgerNo', String)"
            >
              台帳番号
            </th>
            <th
              class="align-middle re-list-head text-center handler"
              rowspan="2"
              scope="col"
              @click="sortList('ship', 'shipType', String)"
            >
              形態
            </th>
            <th
              class="align-middle re-list-head text-center handler"
              rowspan="2"
              scope="col"
              @click="sortList('item', 'measure', Number)"
            >
              Measure
            </th>
            <th
              class="align-middle re-list-head text-center handler"
              rowspan="2"
              scope="col"
              @click="sortList('item', 'quantity', Number)"
            >
              個数
            </th>
            <th
              class="align-middle re-list-head text-center handler"
              rowspan="2"
              scope="col"
              @click="sortList('ship', 'bl', String)"
            >
              B/L番号
            </th>
            <th
              class="align-middle re-list-head text-center handler"
              scope="col"
              @click="sortList('ship', 'voyageName', String)"
            >
              本船名
            </th>
            <th
              class="align-middle re-list-head text-center handler"
              scope="col"
              @click="sortList('ship', 'voyageName', String)"
            >
              POL
            </th>
            <th
              class="align-middle re-list-head text-center handler"
              scope="col"
              @click="sortList('ship', 'voyageName', String)"
            >
              ETD
            </th>
            <th
              class="align-middle re-list-head text-center handler"
              rowspan="2"
              scope="col"
              @click="sortList(null, 'status', String)"
            >
              荷主
            </th>
            <th
              class="align-middle re-list-head text-center handler"
              rowspan="2"
              scope="col"
            >
              通関状況
            </th>
            <th
              class="align-middle re-list-head text-center handler"
              rowspan="2"
              scope="col"
            >
              通関番号<br />通関予定日
            </th>
            <th
              class="align-middle re-list-head text-center handler"
              rowspan="2"
              scope="col"
              @click="sortList('item', 'measure', Number)"
            >
              集荷日
            </th>
            <th
              class="align-middle re-list-head text-center handler"
              rowspan="2"
              scope="col"
              @click="sortList('item', 'measure', Number)"
            >
              納品日
            </th>
            <th
              class="align-middle re-list-head text-center handler"
              rowspan="2"
              scope="col"
            >
              ドライバー<br />送り状
            </th>
            <th
              class="align-middle re-list-head text-center handler"
              scope="col"
            >
              立替金請求書
            </th>
            <th
              class="align-middle re-list-head text-center handler"
              rowspan="2"
              scope="col"
            >
              担当者
            </th>
          </tr>
          <tr>
            <th
              class="align-middle re-list-head text-center"
              scope="col"
              @click="sortList('ship', 'etd', String)"
            >
              VOY
            </th>
            <th
              class="align-middle re-list-head text-center"
              scope="col"
              @click="sortList('ship', 'voyage', String)"
            >
              POD
            </th>
            <th
              class="align-middle re-list-head text-center"
              scope="col"
              @click="sortList('ship', 'pod', String)"
            >
              ETA
            </th>
            <th class="align-middle re-list-head text-center" scope="col">
              請求書
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="re-pointer"
            v-for="(link, lidx) in getDispList"
            :key="lidx"
            @click="toDetail(lidx)"
          >
            <td class="align-middle text-center" scope="row">
              {{ link.receptionNo }}
            </td>
            <td class="align-middle text-center" scope="row">
              <div v-html="dispConvertStatusName(link.managementStatus)"></div>
            </td>
            <td class="align-middle text-center" scope="row">
              {{ link.ledgerNo }}
            </td>
            <td
              class="align-middle text-center"
              scope="row"
              v-if="link.ship.length > 0"
            >
              <div v-if="link.ship[0].shipType == '1'">LCL<br /></div>
              <div v-if="link.ship[0].shipType == '2'">FCL<br /></div>
              <div v-if="link.ship[0].shipType == '3'">AIR<br /></div>
            </td>
            <td class="align-middle text-center" scope="row" v-else>-</td>
            <td
              class="align-middle text-center"
              scope="row"
              v-if="link.item.length > 0"
            >
              {{ link.item[0].measure }}
            </td>
            <td class="align-middle text-center" scope="row" v-else>-</td>
            <td
              class="align-middle text-center"
              scope="row"
              v-if="link.item.length > 0"
            >
              {{ link.item[0].quantity }}
            </td>
            <td class="align-middle text-center" scope="row" v-else>-</td>
            <td
              class="align-middle text-center"
              scope="row"
              v-if="link.ship.length > 0"
            >
              <div v-for="(bl, blidx) in link.ship[0].blNo" :key="blidx">
                <span v-if="bl.no != '' && bl.no != null">
                  {{ bl.no }}
                </span>
                <span v-else> - </span>
                <br />
              </div>
            </td>
            <td class="align-middle text-center" scope="row" v-else>-</td>
            <td
              class="align-middle text-center"
              scope="row"
              v-if="link.ship.length > 0"
            >
              {{ link.ship[0].voyageName }}<br />
              {{ link.ship[0].voyage }}
            </td>
            <td class="align-middle text-center" scope="row" v-else>
              -<br />
              -
            </td>
            <td
              class="align-middle text-center"
              scope="row"
              v-if="link.ship.length > 0"
            >
              {{ link.ship[0].pol }}<br />
              {{ link.ship[0].pod }}
            </td>
            <td class="align-middle text-center" scope="row" v-else>
              -<br />
              -
            </td>
            <td
              class="align-middle text-center"
              scope="row"
              v-if="link.ship.length > 0"
            >
              <div v-if="link.ship[0].eta == '1900-01-01'">-<br /></div>
              <div v-else>{{ link.ship[0].eta }}<br /></div>
              <div v-if="link.ship[0].etd == '1900-01-01'">-<br /></div>
              <div v-else>{{ link.ship[0].etd }}<br /></div>
            </td>
            <td class="align-middle text-center" scope="row" v-else>
              -<br />
              -
            </td>
            <td
              class="align-middle text-center"
              scope="row"
              v-if="link.company != null"
            >
              <div>{{ link.company.name }}</div>
            </td>
            <td class="align-middle text-center" scope="row" v-else>
              -
            </td>
            <td class="align-middle text-center" scope="row">
              <div v-html="dispGetParmitType(lidx)"></div>
            </td>
            <td class="align-middle text-center" scope="row" @click="toDetail(lidx)">
              <div v-html="dispGetParmitNo(lidx)"></div>
              <div v-html="dispGetParmitDate(lidx)"></div>
            </td>
            <td class="align-middle text-center" scope="row" @click="toDetail(lidx)">
              <div v-html="dispOrderDate(lidx)"></div>
            </td>
            <td class="align-middle text-center" scope="row" @click="toDetail(lidx)">
              <div v-html="dispDeliveryDate(lidx)"></div>
            </td>
            <td class="align-middle text-center" scope="row" @click="toDetail(lidx)">
              <div v-html="dispDriver(lidx)"></div>
              <div v-html="dispSend(lidx)"></div>
            </td>
            <td class="align-middle text-center" scope="row">
              <div v-html="dispInvoice(lidx, 2)"></div>
              <div v-html="dispInvoice(lidx, 0)"></div>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row re-table-pager col-md-12 justify-content-around">
    <div v-if="pageData.page > 1">
      <img
        class="re-icon-btn-md justify-content-start"
        @click="pageChange(-1)"
        alt="←"
        src="../../assets/left.png"
      />
    </div>
    <div v-else>
      <a> </a>
    </div>
    {{ pageData.page }}/{{ pageData.totalPage }}
    <div v-if="pageData.page != pageData.totalPage">
      <img
        class="re-icon-btn-md justify-content-start"
        @click="pageChange(1)"
        alt="→"
        src="../../assets/right.png"
      />
    </div>
    <div v-else>
      <a> </a>
    </div>
  </div>
</template>
<script>
import tableDragger from "table-dragger";
import HttpClient from "../../modules/http";
import Utility from "../../modules/utility";

export default {
  components: {},
  data: function () {
    return {
      readonly: false,
      dispFilters: false,
      listDataRaw: null,
      listData: null,
      pageData: {
        page: 1,
        totalPage: null,
        dispRow: 10,
      },
      filterData: {
        isFilter: false,
        etaStart: "",
        etaEnd: "",
        etdStart: "",
        etdEnd: "",
        measureStart: "",
        measureEnd: "",
        quantityStart: "",
        quantityEnd: "",
        pol: "",
        pod: "",
        advanceBill: false,
        bill: false,
      },
      filterFunction: {
        etaStart: { name: "eta", type: "ship" },
        etaEnd: { name: "eta", type: "ship" },
        etdStart: { name: "etd", type: "ship" },
        etdEnd: { name: "etd", type: "ship" },
        measureStart: { name: "measure", type: "item" },
        measureEnd: { name: "measure", type: "item" },
        quantityStart: { name: "quantity", type: "item" },
        quantityEnd: { name: "quantity", type: "item" },
        pol: { name: "pol", type: "ship" },
        pod: { name: "pod", type: "ship" },
        // advanceBill: false,
        // bill: false,
      },
      orderData: {
        isOrder: false,
        orderColumn: "",
        orderType: "",
        isAsc: false,
        orderFunction: String,
      },
      httpClient: new HttpClient(),
      columnOrder: {
        0: 0,
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
        6: 6,
        7: 7,
        8: 8,
        9: 9,
        10: 10,
        11: 11,
        12: 12,
        13: 13,
        14: 14,
        15: 15,
        16: 16,
      },
      statusNames: [
        "<span class='re-label-color-green'>保存中</span>",
        "<span class='re-label-color-red'>要確認</span>",
        "<span class='re-label-color-blue'>チェック中</span>",
        "<span class='re-label-color-green'>連携済</span>",
      ],
    };
  },
  mounted: function () {
    var el = document.getElementById("table");
    var dragger = tableDragger(el, {
      mode: "column",
      dragHandler: ".handler",
      animation: 300,
    });
    dragger.on("drop", function (from, to) {
      console.log(from);
      console.log(to);
    });
    var elMobile = document.getElementById("mobiletable");
    var draggerMobile = tableDragger(elMobile, {
      mode: "column",
      dragHandler: ".mobilehandler",
      animation: 300,
    });
    draggerMobile.on("drop", function (from, to) {
      console.log(from);
      console.log(to);
    });

    this.getList();
  },
  computed: {
    getDispList: function () {
      if (this.listData != null) {
        return this.listData.slice(
          (this.pageData.page - 1) * this.pageData.dispRow,
          this.pageData.page * this.pageData.dispRow
        );
      } else {
        return null;
      }
    },
  },
  methods: {
    toDetail(index) {
      this.$store.dispatch("doUpdateReceptionDetailData", this.listData[index]);
      this.$router.push({
        name: "admincheck",
        params: { paramReceptionNo: this.listData[index].receptionNo },
      });
    },
    pageChange(page) {
      this.pageData.page = this.pageData.page + page;
    },
    dispRowChange(value){
      this.pageData.dispRow = value;
    },
    getList() {
      const url =
        "/admin/list/?agentId=" +
        this.$cookie.getCookie("agentId") +
        "&accountName=" +
        this.$cookie.getCookie("accountName") +
        "&receptionNo=" +
        this.receptionNo;
      this.httpClient.get(
        url,
        this.$cookie.getCookie("token"),
        this.onSuccessGetList,
        this.onErrorAuth
      );
    },
    onSuccessGetList(response) {
      this.listData = response.data.link;
      this.listDataRaw = JSON.stringify(this.listData);
      this.listDataRaw = JSON.parse(this.listDataRaw);
      this.filterList();
    },
    onErrorAuth(error) {
      this.isLoading = false;
      if (
        error != null &&
        error.response  != null &&
        "status" in error.response &&
        error.response.status == 401
      ) {
        this.messageModalStatus.message.push(
          "セッションの有効期限がきれました。再度ログインお願いします。"
        );
      } else {
        this.messageModalStatus.message.push(
          "エラーが発生しました。再度ログインお願いします。"
        );
      }
      this.messageModalStatus.show = true;
      this.messageModalStatus.messageType = "warn";
      this.messageModalStatus.button = true;
    },
    reloadList() {
      this.pageData.page = 1;
      this.getList();
    },
    filterItem(name, value) {
      this.filterData.isFilter = true;
      this.filterData[name] = value;
      this.filterList();
    },
    filterList() {
      let listDataBuff = JSON.stringify(this.listDataRaw);
      listDataBuff = JSON.parse(listDataBuff);

      for (let filters in this.filterFunction) {
        if (
          this.filterFunction[filters].name != "pod" &&
          this.filterFunction[filters].name != "pol" &&
          filters != "advanceBill" && 
          filters != "bill"
        ) {
          if (
            this.filterData[this.filterFunction[filters].name + "Start"] != ""
          ) {
            listDataBuff = listDataBuff.filter((list) => {
              if (
                list[this.filterFunction[filters].type].length > 0 &&
                this.filterData[this.filterFunction[filters].name + "Start"] <=
                  list[this.filterFunction[filters].type][0][
                    this.filterFunction[filters].name
                  ]
              ) {
                return true;
              } else {
                return false;
              }
            });
          }
          if (
            this.filterData[this.filterFunction[filters].name + "End"] != ""
          ) {
            listDataBuff = listDataBuff.filter((list) => {
              if (
                list[this.filterFunction[filters].type].length > 0 &&
                this.filterData[this.filterFunction[filters].name + "End"] >=
                  list[this.filterFunction[filters].type][0][
                    this.filterFunction[filters].name
                  ]
              ) {
                return true;
              } else {
                return false;
              }
            });
          }
        }else if(filters == "advanceBill" || filters == "bill"){
          if(this.filterData["advanceBill"]){
            listDataBuff = listDataBuff.filter((list) => {
              if(list.regacyData.ChargeList && list.regacyData.ChargeList.length > 0){
                let chargeFlg = false
                for(let index in list.regacyData.ChargeList){
                  if(list.regacyData.ChargeList[index].ChargeType == 2){
                    chargeFlg = true
                    break
                  }
                }
                return chargeFlg;
              }else{
                return false;
              }
            })
          }
          if(this.filterData["bill"]){
            listDataBuff = listDataBuff.filter((list) => {
              console.log(list)
              if(list.regacyData.ChargeList && list.regacyData.ChargeList.length > 0){
                let chargeFlg = false
                for(let index in list.regacyData.ChargeList){
                  if(list.regacyData.ChargeList[index].ChargeType != 2){
                    chargeFlg = true
                    break
                  }
                }
                return chargeFlg;
              }else{
                return false;
              }
            })
          }
        } else {
          if (this.filterData[this.filterFunction[filters].name] != "") {
            listDataBuff = listDataBuff.filter((list) => {
              if (list[this.filterFunction[filters].type].length > 0){
                if (list[this.filterFunction[filters].type][0][this.filterFunction[filters].name].includes(this.filterData[this.filterFunction[filters].name])) {
                  return true;
                } else {
                  return false;
                }
              }else{
                return false;
              }
            });
          }
        }
      }
      this.listData = listDataBuff;
      this.pageData.totalPage = Math.ceil(
        this.listData.length / this.pageData.dispRow
      );
      if (this.pageData.totalPage == 0) {
        this.pageData.page = 1;
        this.pageData.totalPage = 1;
      } else {
        if (this.pageData.page > this.pageData.totalPage) {
          this.pageData.page = this.pageData.totalPage;
        }
      }
    },
    sortList(type, name, func, asc) {
      if (asc == undefined || asc == null) {
        this.orderData.isAsc = !this.orderData.isAsc;
      } else {
        this.orderData.isAsc = asc;
      }

      this.orderData.orderColumn = name;
      this.orderData.orderType = type;
      this.orderData.isOrder = true;
      this.orderData.orderFunction = func;

      this.filterList();

      this.listData = this.listData.sort((a, b) => {
        if (type == null) {
          a = a[name];
          b = b[name];
        } else {
          if (
            a[type].length > 0 &&
            a[type][0][name] !== "" &&
            a[type][0][name] !== null
          ) {
            a = a[type][0][name];
          } else {
            if (func == Number) {
              a = -1;
            } else {
              a = "-";
            }
          }
          if (
            b[type].length > 0 &&
            b[type][0][name] !== "" &&
            b[type][0][name] !== null
          ) {
            b = b[type][0][name];
          } else {
            if (func == Number) {
              b = -1;
            } else {
              b = "-";
            }
          }
        }
        return (
          (func(a) === func(b) ? 0 : func(a) > func(b) ? 1 : -1) *
          (this.orderData.isAsc ? 1 : -1)
        );
      });
    },
    dispConvertStatusName(status) {
      if (status == null) {
        status = 0;
      }
      return this.statusNames[status];
    },
    dispGetParmitNo(index, isMobile) {
      let str = "";
      if (
        this.listData.length > 0 &&
        this.listData[index] != null &&
        this.listData[index].regacyData != null
      ) {
        if (
          this.listData[index].regacyData.PermitNo != null &&
          this.listData[index].regacyData.PermitNo != ""
        ) {
          str = this.listData[index].regacyData.PermitNo;
        } else {
          str = "-";
        }
      } else {
        str = "-";
      }
      if (isMobile == 1) {
        str = "<small>" + str + "</small>";
      }

      return str;
    },
    dispGetParmitType(index, isMobile) {
      let str = "";
      if (
        this.listData.length > 0 &&
        this.listData[index] != null &&
        this.listData[index].regacyData != null
      ) {
        if (
          this.listData[index].regacyData.PermitNo != null &&
          this.listData[index].regacyData.PermitNo != "" &&
          this.listData[index].regacyData.FileName != null &&
          this.listData[index].regacyData.FileName != ""
        ) {
          if (this.listData[index].branchCode != null) {
            const branchCode = this.listData[index].branchCode.split("-");
            let baseUrl = "";
            if (branchCode[0] == "FC") {
              baseUrl =
                "http://www.fcstandard.co.jp/files/clear/" +
                this.listData[index].regacyData.FileName;
            } else {
              baseUrl =
                "https://online.chugai-kaiun.co.jp/files/CLEAR/" +
                this.listData[index].regacyData.FileName;
            }
            str =
              "<a href='" +
              baseUrl +
              ".pdf' target='_blank' rel='noopener noreferrer'><i class='far fa-file-pdf fa-3x'></i></a>";
          } else {
            str = "-";
          }
        } else if (
          this.listData[index].regacyData.CustomType != null &&
          this.listData[index].regacyData.CustomType != 0
        ) {
          str =
            '<span class="re-parmit-type' +
            this.listData[index].regacyData.CustomType +
            '"> 予備' +
            this.listData[index].regacyData.CustomType +
            "</span>";
        } else {
          str = "-";
        }
      } else {
        str = "-";
      }
      if (isMobile == 1) {
        str = "<small>" + str + "</small>";
      }

      return str;
    },
    dispGetParmitDate(index, isMobile) {
      let str = "";
      if (
        this.listData.length > 0 &&
        this.listData[index] != null &&
        this.listData[index].regacyData != null
      ) {
        if (
          this.listData[index].regacyData.PermitDate != null &&
          this.listData[index].regacyData.PermitDate != ""
        ) {
          str = Utility.convertDateAddHyphen(this.listData[index].regacyData.PermitDate);
        } else if (
          this.listData[index].regacyData.CustomDate != null &&
          this.listData[index].regacyData.CustomDate
        ) {
          str = Utility.convertDateAddHyphen(this.listData[index].regacyData.CustomDate);
        } else {
          str = "-";
        }
      } else {
        str = "-";
      }
      if (isMobile == 1) {
        str = "<small>" + str + "</small>";
      }

      return str;
    },
    dispDeliveryDate(index, isMobile) {
      let str = "";
      if (
        this.listData.length > 0 &&
        this.listData[index] != null &&
        this.listData[index].regacyData != null
      ) {
        if (
          this.listData[index].regacyData.DeliveryList != null &&
          this.listData[index].regacyData.DeliveryList.length > 0
        ) {
          let delivery_str = "";
          for (let findex in this.listData[index].regacyData.DeliveryList) {
            delivery_str +=
              "" +
              Utility.convertDateAddHyphen(
                this.listData[index].regacyData.DeliveryList[findex].DeliveryDate
              ) +
              " " +
              this.listData[index].regacyData.DeliveryList[findex].DeliveryTime +
              "<br>";
          }
          str = delivery_str;
        } else if (
          this.listData[index].regacyData.PermitNo != "" &&
          this.listData != null &&
          this.listData[index].delivery.length == 0
        ) {
          str = "<span class='re-font-color-red'>未定</span>";
        } else if (
          this.listData[index].regacyData.PermitNo != "" &&
          this.listData != null &&
          this.listData[index].delivery.length > 0
        ) {
          str =
            this.listData[index].delivery[0].preferredDate +
            " " +
            this.listData[index].delivery[0].preferredTime +
            "<br>";
        } else {
          str = "-";
        }
      } else {
        str = "-";
      }
      if (isMobile == 1) {
        str = "<small>" + str + "</small>";
      }

      return str;
    },
    dispOrderDate(index, isMobile) {
      let str = "";
      if (
        this.listData.length > 0 &&
        this.listData[index] != null &&
        this.listData[index].regacyData != null
      ) {
        if (
          this.listData[index].regacyData.DeliveryList != null &&
          this.listData[index].regacyData.DeliveryList.length > 0
        ) {
          let delivery_str = "";
          for (let findex in this.listData[index].regacyData.DeliveryList) {
            delivery_str +=
              "" +
              Utility.convertDateAddHyphen(
                this.listData[index].regacyData.DeliveryList[findex].OrderDate
              ) +
              " " +
              this.listData[index].regacyData.DeliveryList[findex].OrderTime +
              "<br>";
          }
          str = delivery_str;
        } else if (
          this.listData[index].regacyData.PermitNo != "" &&
          this.listData != null &&
          this.listData[index].delivery.length == 0
        ) {
          str = "<span class='re-font-color-red'>要手配</span>";
        } else {
          str = "-";
        }
      } else {
        str = "-";
      }
      if (isMobile == 1) {
        str = "<small>" + str + "</small>";
      }

      return str;
    },
    dispDriver(index) {
      let str = "";
      if (
        this.listData.length > 0 &&
        this.listData[index] != null &&
        this.listData[index].regacyData != null
      ) {
        if (
          this.listData[index].regacyData.DeliveryList != null &&
          this.listData[index].regacyData.DeliveryList.length > 0
        ) {
          let delivery_str = "";
          for (let findex in this.listData[index].regacyData.DeliveryList) {
            if(this.listData[index].regacyData.DeliveryList[findex].DeliveryBroker != null){
              delivery_str = "〇<br>";
            }
          }
          str = delivery_str;
        } else {
          str = "-";
        }
      } else {
        str = "-";
      }
      return str;
    },
    dispSend(index) {
      let str = "";
      if (
        this.listData.length > 0 &&
        this.listData[index] != null &&
        this.listData[index].regacyData != null
      ) {
        if (
          this.listData[index].regacyData.DeliveryList != null &&
          this.listData[index].regacyData.DeliveryList.length > 0
        ) {
          let delivery_str = "";
          for (let findex in this.listData[index].regacyData.DeliveryList) {
            if(this.listData[index].regacyData.DeliveryList[findex].DeliveryBroker != null){
              delivery_str = "〇<br>";
            }
          }
          str = delivery_str;
        } else {
          str = "-";
        }
      } else {
        str = "-";
      }
      return str;
    },
    dispInvoice(index, type, isMobile) {
      let str = "";
      if (
        this.listData.length > 0 &&
        this.listData[index] != null &&
        this.listData[index].regacyData != null
      ) {
        if (this.listData[index].branchCode != null) {
          const branchCode = this.listData[index].branchCode.split("-");
          let baseUrl = "";
          if (branchCode[0] == "FC") {
            baseUrl =
              "https://www.fcstandard.co.jp/scripts/FCS/Programs/customs/webprint.asp?no=";
          } else {
            baseUrl =
              "https://online.chugai-kaiun.co.jp/CHUGAI/CargoOne/customs/webprint.asp?no=";
          }
          if (type == 2) {
            for (let iindex in this.listData[index].regacyData.ChargeList) {
              if (this.listData[index].regacyData.ChargeList[iindex].ChargeType == 2) {
                str +=
                  "&nbsp;<a href='" +
                  baseUrl +
                  this.listData[index].regacyData.ChargeList[iindex].ChargeNo +
                  this.listData[index].regacyData.ChargeList[iindex].ChargeClient +
                "' target='_blank' rel='noopener noreferrer'><i class='far fa-file-alt fa-2x'></i></a>"
              }
            }
          } else {
            for (let iindex in this.listData[index].regacyData.ChargeList) {
              if (this.listData[index].regacyData.ChargeList[iindex].ChargeType != 2) {
                str +=
                  "&nbsp;<a href='" +
                  baseUrl +
                  this.listData[index].regacyData.ChargeList[iindex].ChargeNo +
                  this.listData[index].regacyData.ChargeList[iindex].ChargeClient +
                "' target='_blank' rel='noopener noreferrer'><i class='fas fa-receipt fa-2x'></i></a>"
              }
            }
          }
        }
      }

      if (isMobile == 1) {
        str = "<small>" + str + "</small>";
      }

      return str;
    },
  },
};
</script>
<style>
.row-scrollable {
  overflow-x: auto !important;
  white-space: nowrap !important;
}
.row-scrollable > .col-scrollable {
  display: inline-block !important;
  float: none !important;
}
</style>