export default class constTypes {
    constructor() {
        this.quantityTypes = [
            "CT",
            "PK",
            "PP",
            "CS",
            "BE",
            "PS",
            "RO",
            "SI",
            "BA",
            "BG",
            "BK",
            "BL",
            "BN",
            "BR",
            "BX",
            "CA",
            "CG",
            "CH",
            "CK",
            "CL",
            "CN",
            "CO",
            "CP",
            "CR",
            "CX",
            "CY",
            "DJ",
            "DP",
            "DR",
            "FB",
            "FL",
            "FR",
            "FT",
            "HD",
            "HG",
            "IN",
            "JR",
            "JG",
            "KG",
            "LG",
            "LZ",
            "MT",
            "NE",
            "NT",
            "PA",
            "PC",
            "PE",
            "PG",
            "PI",
            "PL",
            "PU",
            "PY",
            "PZ",
            "RL",
            "SA",
            "SF",
            "SK",
            "SB",
            "SS",
            "ST",
            "SV",
            "SZ",
            "TB",
            "TI",
            "TN",
            "VA",
            "VG",
            "VL",
            "VO",
            "VK",
            "VQ",
            "VR",
            "VY",
            "WD",
            "ZZ",
        ];
        this.fcCustomsCode=[
            {"code":"FCS15990","disp":"FCS"},
            {"code":"FCS28702","disp":"FCST"},
            {"code":"FCG11","disp":"CHUGAI"},
            {"code":"FCS27113","disp":"CHUGAIO"}
        ];
        this.chugaiCustomsCode=[
            {"code":"7013","disp":"FCS"},
            {"code":"CH000240","disp":"FCST"},
            {"code":"CH000001","disp":"HONSHA"},
            {"code":"CH000003","disp":"CHUGAIO"}
        ];

        this.containerTypes = [
            "20'GP",
            "40'GP",
            "40'HQ",
            "20'RF",
            "40'RF",
            "20'OT",
            "40'OT",
            "20'HQ",
            "タンク",
            "特殊",
          ];
    }

    makeInitQuantityTypes() {
        return this.quantityTypes;
    }

    makeInitFcCustomeCode(){
        return this.fcCustomsCode;
    }

    makeInitChugaiCustomeCode(){
        return this.chugaiCustomsCode;
    }

    makeInitContainerTypes(){
        return this.containerTypes;
    }
}