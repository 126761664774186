import axios from "axios";
import { decycle } from "json-cyclic";

const baseDomain = process.env.VUE_APP_TARGET_API_HOST;
const baseURL = baseDomain + "/api/v1/prealert";

export default class HttpClient {
    get(url, token, onSuccess, onError, onFinally) {
        console.log(url);
        axios
            .get(
                baseURL + url,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                    },
                    responseType: "json",
                } ,
            )
            .then((response) => {
                if (onSuccess !== undefined) {
                    console.log(response);
                    onSuccess(response);
                }
            })
            .catch((error) => {
                console.log(error);
                if (onError !== undefined) {
                    onError(error);
                }
            })
            .finally(
                function () {
                    if (onFinally !== undefined) {
                        onFinally();
                    }
                }
            );
    }
    save(url, token, params, onSuccess, onError, onFinally) {
        console.log(params);
        const save_params_json = JSON.stringify(decycle(params));
        axios
            .post(baseURL + url, save_params_json,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                    },
                    responseType: "json",
                }
            )
            .then((response) => {
                console.log(1)
                if (onSuccess !== undefined) {
                    onSuccess(response);
                }
            })
            .catch((error) => {
                console.log(2)
                if (onError !== undefined) {
                    onError(error);
                }
            })
            .finally(
                function () {
                    console.log(3)
                    if (onFinally !== undefined) {
                        onFinally();
                    }
                }
            );
    }
    edit(url, token, params, onSuccess, onError, onFinally) {
        console.log(params);
        const edit_params_json = JSON.stringify(decycle(params));
        axios
            .put(baseURL + url, edit_params_json,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                    },
                    responseType: "json",
                }
            )
            .then((response) => {
                if (onSuccess !== undefined) {
                    onSuccess(response);
                }
            })
            .catch((error) => {
                if (onError !== undefined) {
                    onError(error);
                }
            })
            .finally(
                function () {
                    if (onFinally !== undefined) {
                        onFinally();
                    }
                }
            );
    }
    checkServerInfo(url, token, params, onSuccess, onError, onFinally) {
        console.log(params);
        const save_params_json = JSON.stringify(decycle(params));
        axios
            .post(baseURL + url, save_params_json,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                    },
                    responseType: "json",
                }
            )
            .then((response) => {
                console.log(1)
                if (onSuccess !== undefined) {
                    onSuccess(response);
                }
            })
            .catch((error) => {
                console.log(2)
                if (onError !== undefined) {
                    onError(error);
                }
            })
            .finally(
                function () {
                    console.log(3)
                    if (onFinally !== undefined) {
                        onFinally();
                    }
                }
            );
    }
    deleteWithOutToken(url, onSuccess, onError, onFinally) {
        console.log(url);
        axios
            .delete(baseURL + url)
            .then((response) => {
                if (onSuccess !== undefined) {
                    onSuccess(response);
                }
            })
            .catch((error) => {
                if (onError !== undefined) {
                    onError(error);
                }
            })
            .finally(
                function () {
                    if (onFinally !== undefined) {
                        onFinally();
                    }
                }
            );
    }
    delete(url, token, onSuccess, onError, onFinally) {
        console.log(url);
        axios
            .delete(baseURL + url,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                    },
                    responseType: "json",
                }
            )
            .then((response) => {
                if (onSuccess !== undefined) {
                    onSuccess(response);
                }
            })
            .catch((error) => {
                if (onError !== undefined) {
                    onError(error);
                }
            })
            .finally(
                function () {
                    if (onFinally !== undefined) {
                        onFinally();
                    }
                }
            );
    }
    deleteWithParams(url, token, params, onSuccess, onError, onFinally) {
        console.log(params);
        axios
            .delete(baseURL + url, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
                responseType: "json",
                data: { params },
                body: { params }
            })
            .then((response) => {
                if (onSuccess !== undefined) {
                    onSuccess(response);
                }
            })
            .catch((error) => {
                if (onError !== undefined) {
                    onError(error);
                }
            })
            .finally(
                function () {
                    if (onFinally !== undefined) {
                        onFinally();
                    }
                }
            );
    }
    dl(url, token, onSuccess, onError, onFinally) {
        console.log(url);
        axios
            .get(
                baseURL + url,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                    },
                    responseType: "blob",
                } ,
            )
            .then((response) => {
                if (onSuccess !== undefined) {
                    console.log(response);
                    onSuccess(response);
                }
            })
            .catch((error) => {
                console.log(error);
                if (onError !== undefined) {
                    onError(error);
                }
            })
            .finally(
                function () {
                    if (onFinally !== undefined) {
                        onFinally();
                    }
                }
            );
    }
    getVesselList(url, token, onSuccess, onError, onFinally) {
        console.log(url);
        axios
            .get(
                baseURL + url,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                    },
                    responseType: "json",
                }
            )
            .then((response) => {
                if (onSuccess !== undefined) {
                    console.log(response);
                    onSuccess(response);
                }
            })
            .catch((error) => {
                console.log(error);
                if (onError !== undefined) {
                    onError(error);
                }
            })
            .finally(
                function () {
                    if (onFinally !== undefined) {
                        onFinally();
                    }
                }
            );
    }
}
