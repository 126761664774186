import PreAlertData from "../../modules/preAlertData";

export default {
  data: function () {
    return {
      preAlertDataSet: [],
      isloadingPreAlertState: false,
      isValitatePreAlertState: false,
      removePreAlertData: null,
    }
  },
  methods: {
    setPreAlert() {
      const preAlertData = new PreAlertData();
      let preAlertInitData = preAlertData.makeInitPrealertDataSet();
      preAlertInitData.pid = this.pid;
      this.preAlertDataSet.push(preAlertInitData);
      this.scrollTarget("preAlert-top");
    },
    deletePrealert(value) {
      if (this.callType === "edit") {
        this.removePrealertData = {
          pid: this.pid
        };
      }
      this.preAlertDataSet.splice(value, 1);
    },
    updatePrealert(index, value) {
      this.preAlertDataSet[index].preAlertData = value;
    },
    editPreAlert(pid) {
      this.isloadingPreAlertState = true;
      const params = {
        accountName: this.$cookie.getCookie("accountName"),
        pid: pid,
        preAlerts: this.preAlertDataSet,
      };
      this.httpClient.edit("/preAlert/", this.$cookie.getCookie("token"), params, this.onSuccessPrealert, this.onErrorPrealert);
    },
    clearPreAlert() {
      if (this.removePreAlertData != null) {
        this.isloadingPreAlertState = true;
        const params = "?accountName=" + this.$cookie.getCookie("accountName") +
          "&pid=" + this.removePrealertData.pid;
        this.httpClient.delete("/preAlert/" + params, this.$cookie.getCookie("token"), params, this.onSuccessPrealert, this.onErrorPrealert);
      } else {
        this.onSuccessPrealert();
      }
    },
    onSuccessPrealert() {
      this.isloadingPreAlertState = false;
      this.changeSendStatus("preAlert", 1);
      this.changeSendStatus("attached", 1);
    },
    onErrorPrealert() {
      this.isloadingPreAlertState = false;
      this.changeSendStatus("preAlert", 2);
    }
  },
};