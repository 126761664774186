<template>
  <div class="container">
    <header id="header">
      <nav id="header-nav" class="navbar navbar-expand-lg re-nav fixed-top">
        <img
          class="navbar-brand logo re-header-logo-md"
          alt="エフシースタンダードロジックス株式会社"
          src="../assets/logo.png"
        />
        <div class="re-header-str">PreAlert</div>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarResponsive">
          <ul class="navbar-nav ml-auto">
            <li v-if="!isLogin" class="nav-item active">
              <router-link tag="li" id="home-nav" to="/" exact
                ><a class="nav-link re-header">
                  <img
                    class="re-header-icon-md justify-content-start"
                    alt="home"
                    src="../assets/home.png"
                  />
                  home <span class="sr-only">(current)</span></a
                ></router-link
              >
            </li>
            <li v-else class="nav-item active">
              <router-link tag="li" id="home-nav" to="/main/info" exact
                ><a class="nav-link re-header">
                  <img
                    class="re-header-icon-md justify-content-start"
                    alt="home"
                    src="../assets/home.png"
                  />
                  home <span class="sr-only">(current)</span></a
                ></router-link
              >
            </li>
            <li v-if="!isLogin" class="nav-item">
              <router-link tag="li" id="login-nav" to="/login"
                ><a class="nav-link re-header">
                  <img
                    class="re-header-icon-md justify-content-start"
                    alt="Login"
                    src="../assets/login.png"
                  />

                  LOGIN</a
                ></router-link
              >
            </li>
            <li v-if="isLogin" class="nav-item">
              <router-link tag="li" id="login-nav" to="/login"
                ><a class="nav-link re-header">
                  <img
                    class="re-header-icon-md justify-content-start"
                    alt="LogOut"
                    src="../assets/logout.png"
                  />

                  LogOut</a
                ></router-link
              >
            </li>
            <li class="nav-item d-md-none" v-if="$store.getters.login">
              <div class="d-md-none border-bottom"></div>
              <a href="#" @click="goTo(1)" class="nav-link re-menu-font">
                <img
                  class="re-icon-md"
                  alt="Add New Pre-Alert"
                  src="../assets/pass.png"
                />
                Add New Pre-Alert
              </a>
            </li>
            <li class="nav-item d-md-none" v-if="$store.getters.login">
              <div class="d-md-none border-bottom"></div>
              <a href="#" @click="goTo(2)" class="nav-link re-menu-font">
                <img class="re-icon-md" alt="一覧" src="../assets/list2.png" />
                Modify A Pre-Alert
              </a>
            </li>
            <li class="nav-item d-md-none" v-if="$store.getters.login">
              <div class="d-md-none border-bottom"></div>
              <a href="#" @click="goTo(3)" class="nav-link re-menu-font">
                <img class="re-icon-md" alt="一覧" src="../assets/list2.png" />
                Upload B/L Doc
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  </div>
</template>
<script>
export default {
  name: "Header",
  data: function () {
    return {};
  },
  computed: {
    isLogin() {
      return this.$store.getters.login;
    },
  },
  methods: {
    goTo(type) {
      this.$store.dispatch("doUpdatePreAlertDetailData", null);
      this.$store.dispatch("doShowPreAlertShowData", null);
      if (type == 1) {
        this.$router.push({
          name: "request",
        });
      }
      if (type == 2) {
        this.$router.push({ name: "list" });
      }
      if (type == 3) {
        this.$router.push({ name: "upload" });
      }
    },
  },
};
</script>
