export default class preAlertData {
    constructor() {
        // ���t�̍��ڂɂ��ẮA�����l�Ƃ��ē������t��ݒ�
        this.preAlertDataSet = {
            pid: 0,
            showPid: 0,
            jobNo: "",
            shipper: "",
            consignee: "",
            customer: 0,
            notify: "",
            vessel: "",
            voyage: "",
            carrier: "",
            pattern: "LCL",
            pol: "",
            pod: "",
            etd: "",
            eta: "",
            commodity: "",
            quantity: 0,
            packages: "",
            grossWeight: 0,
            mesurement: 0,
            housBl: "",
            poNo: "",
            bookingDate: "",
            closeDate: "",
            container20: 0,
            container40: 0,
            hq: false,
            tradeTerms: "",
            specHandle: "",
            factory: "",
            marks: "",
            containerNo: "",
            delayInfo: "",
            fileName: "",
            checkBox: "",
        };
        this.preAlertBlDataSet = {
            pid: 0,
            fileName: "",
            fileType: "",
        };
    }
    makeInitpreAlertBlDataSet() {
        return this.preAlertBlDataSet;
    }
    makeInitPrealertDataSet() {
        return this.preAlertDataSet;
    }

}