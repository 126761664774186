import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import LogIn from '../views/LogIn.vue'
import Main from '../views/Main.vue'
import Request from '../components/Request.vue'
import List from '../components/List.vue'
import Upload from '../components/Upload.vue'
import AdminList from '../components/admin/List.vue'
import Info from '../components/Info.vue'
import Check from '../components/Check.vue'
import AdminCheck from '../components/admin/Check.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    component: LogIn
  },
  {
    path: '/main',
    name: 'main',
    component: Main,
    children: [
      {
        path: 'request/:type',
        name: 'request',
        component: Request,
        props: true
      },
      {
        path: 'list',
        name: 'list',
        component: List
      },
      {
        path: 'info',
        name: 'info',
        component: Info
      },
      {
        path: 'upload',
        name: 'upload',
        component: Upload
      },
      {
        path: 'check',
        name: 'check',
        component: Check
      },
      {
        path: 'admin/list',
        name: 'adminlist',
        component: AdminList
      },
      {
        path: 'admin/check',
        name: 'admincheck',
        component: AdminCheck
      }
    ],
    props: true
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
