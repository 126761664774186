<template>
  <div id="messages-content" class="border" @click="noClose()">
      <div class="row d-flex justify-content-center">
        メモ
      </div>
    <div class="container">
      <div class="row d-flex justify-content-start border-top" v-for="(messagesData, index) in messagesDataSet" :key="index">
          <div  data-toggle="tooltip" :title="messagesData.createDatetime">
          {{ messagesData.name }} > {{ messagesData.message }}
          </div>
          <br>
      </div>
      <br />
      <div class="col-md-12 d-flex justify-content-around">
        <div class="form-row form-inline">
          <div class="form-group col-md-10">
            <div class="input-group">
            <input
              type="text"
              class="form-control"
              id="inputNote"
              v-model="note"
            />
            <button
              v-on:click="postMessagesData()"
              type="button"
              class="btn btn-primary"
            >
              書込
            </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HttpClient from "../../modules/http";

export default {
  name: "Messages",
  props: ["receptionNo"],
  emits: [],
  data: function () {
    return {
      note: "",
      messagesDataSet: [],
      httpClient: new HttpClient(),
    };
  },
  created: function () {
    this.getMessagesData();
  },
  mounted:function(){
    $('[data-toggle="tooltip"]').tooltip();
  },
  methods: {
    getMessagesData() {
      const url =
        "/checkMessage/?agentId=" +
        this.$cookie.getCookie("agentId") +
        "&accountName=" +
        this.$cookie.getCookie("accountName") +
        "&receptionNo=" +
        this.receptionNo;
      this.httpClient.get(url, this.$cookie.getCookie("token"),this.onSuccess, this.onError);
    },
    onSuccess(response) {
      this.isLoading = false;
      this.messagesDataSet = JSON.stringify(response.data.checkMessages);
      this.messagesDataSet = JSON.parse(this.messagesDataSet);
    },
    onError() {},
    postMessagesData() {
      const params = {
        agentId: this.$cookie.getCookie("agentId"),
        accountName: this.$cookie.getCookie("accountName"),
        receptionNo: this.receptionNo,
        message: this.note,
      };
      this.httpClient.save(
        "/checkMessage/",
        this.$cookie.getCookie("token"),
        params,
        this.onSuccessPost
      );
    },
    onSuccessPost() {
      this.note = ""
      this.getMessagesData();
    },
    noClose() {
      event.stopPropagation();
    },
  },
};
</script>
<style>
#messages-content {
  display: inline-block;
  position: fixed;
  bottom: 16px;
  right: 20px;
  z-index: 10;
  width: 17%;
  padding: 1em;
  background: #fff;
  border-radius: 0%;
  border: 3px;
  border-color: black;
}
</style>