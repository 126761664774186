<template>
  <br />
  <br />
  <br />
  <div id="loading" class="overlay" v-if="isLoading">
    <div class="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <div class="d-block d-md-none container">
    <div class="row">
      <div class="col-md-9">
        <div
          class="
            d-flex
            justify-content-between
            flex-wrap flex-md-nowrap
            align-items-center
            pt-3
            pb-2
            mb-3
          "
        >
          <h6 class="h6">List</h6>
        </div>
      </div>
    </div>
    <div class="row">
      <table
        class="
          table table-striped table-hover table-bordered
          m
          table-sm table-responsive
        "
        id="mobiletable"
      >
        <thead>
          <tr>
            <th
              class="align-middle re-list-head text-center mobilehandler"
              rowspan="2"
              scope="col"
              @click="sortList(null, 'pid', Number)"
            >
              No.
            </th>
            <th
              class="align-middle re-list-head text-center mobilehandler"
              @click="sortList(null, 'houseBl', String)"
              rowspan="2"
              scope="col"
            >
              House B/L
            </th>
            <th
              class="align-middle re-list-head text-center mobilehandler"
              @click="sortList(null, 'etd', String)"
              scope="col"
            >
              ETD
            </th>
            <th
              class="align-middle re-list-head text-center mobilehandler"
              rowspan="2"
              @click="sortList(null, 'shipper', String)"
              scope="col"
            >
              Shipper
            </th>
            <th
              class="align-middle re-list-head text-center handler"
              @click="sortList(null, 'pod', String)"
              scope="col"
            >
              POD
            </th>
          </tr>
          <tr>
            <th
              class="align-middle re-list-head text-center mobilehandler"
              @click="sortList(null, 'eta', String)"
              scope="col"
            >
              ETA
            </th>
            <th
              class="align-middle re-list-head text-center mobilehandler"
              @click="sortList(null, 'quantity', String)"
              scope="col"
            >
              PKG
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="re-pointer"
            v-for="(link, lidx) in getDispList"
            :key="lidx"
            @click="toDetail(link, lidx)"
          >
            <td class="align-middle text-center" scope="row">
              {{ link.showPid }}
            </td>
            <td class="align-middle text-center" scope="row">
              {{ link.housBl }}
            </td>
            <td class="align-middle text-center" scope="row">
              <small>{{ link.etd }}</small> <br />
              <small>{{ link.eta }}</small>
            </td>
            <td class="align-middle text-center" scope="row">
              {{ link.shipper }}
            </td>
            <td class="align-middle text-center" scope="row">
              <small>{{ link.pod }} </small><br />
              <small>{{ link.quantity }}</small>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="d-none d-md-block container col-md-9 col-xl-9">
    <div class="row">
      <div class="col-md-9 col-xl-9">
        <div
          class="
            d-flex
            justify-content-between
            flex-wrap flex-md-nowrap
            align-items-center
            pt-3
            pb-2
            mb-1
          "
        >
          <h6 class="h6">conditions</h6>
        </div>
      </div>
    </div>
    <div class="row">
      <form>
        <div class="form-row form-inline col-md-12">
          <div class="form-group col-md-9">
            <div class="form-group">
              <label for="inputEtdStart" class="col-md-1">ETD</label>
              <div class="input-group">
                <input
                  type="text"
                  @keydown.enter="focusNext"
                  class="form-control col-md-6"
                  id="inputEtdStart"
                  placeholder="yyyy-mm-dd"
                  v-bind:readonly="readonly"
                  :value="filterData.etdStart"
                  @input="filterItem('etdStart', $event.target.value)"
                />
                <div id="etd1" class="input-group-append re-pointer">
                  <div class="input-group-text">
                    <i class="fa fa-calendar"></i>
                  </div>
                </div>
              </div>
              <span>～</span>
              <div class="input-group">
                <div class="input-group">
                  <input
                    type="text"
                    @keydown.enter="focusNext"
                    class="form-control col-md-6"
                    id="inputEtdEnd"
                    placeholder="yyyy-mm-dd"
                    v-bind:readonly="readonly"
                    :value="filterData.etdEnd"
                    @input="filterItem('etdEnd', $event.target.value)"
                  />
                  <div id="etd2" class="input-group-append re-pointer">
                    <div class="input-group-text">
                      <i class="fa fa-calendar"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3 form-inline">
            <input
              type="checkbox"
              class="form-control"
              id="all"
              :value="filterData.showAll"
              @change="filterItem('showAll', $event.target.checked)"
            />
            <label class="form-check-label" for="all">
              &nbsp; Show&nbsp;All
            </label>
          </div>
        </div>
        <div class="form-row form-inline col-md-12">
          <div class="form-group col-md-6">
            <label for="inputBillNo" class="col-md-4">House B/L</label>
            <input
              type="text"
              @keydown.enter="focusNext"
              class="form-control col-md-6"
              id="inputBillNo"
              v-bind:readonly="readonly"
              :value="filterData.hoseBl"
              @input="filterItem('hoseBl', $event.target.value)"
            />
          </div>
          <div class="form-group col-md-6">
            <label for="inputPid" class="col-md-3"> No.</label>
            <input
              type="text"
              @keydown.enter="focusNext"
              class="form-control col-md-5"
              id="inputPid"
              v-bind:readonly="readonly"
              :value="filterData.pid"
              @input="filterItem('pid', $event.target.value)"
            />
            <div class="col-md-1">
              <button
                type="button"
                class="btn btn-primary active re-shadow"
                @click="reloadList()"
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <hr />
    <div class="row">
      <div class="col-md-12">
        <div
          class="
            d-flex
            justify-content-between
            flex-wrap flex-md-nowrap
            align-items-center
            pt-3
            pb-2
            mb-3
          "
        >
          <h5 class="h5">List</h5>
          <form class="form-inline">
            &nbsp;&nbsp;&nbsp;
            <div class="form-group">
              <label for="select-disprow">Displayed results：</label>
              <select
                id="select-disprow"
                class="form-control"
                @change="dispRowChange($event.target.value)"
              >
                <option>10</option>
                <option>20</option>
                <option>50</option>
                <option>100</option>
              </select>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--div class="row">
      <button
        type="button"
        class="btn btn-info btn-lg btn-block re-delete-button re-shadow"
        @click="checkPreALlert()"
        v-if="listMode == 1"
      >
        削除する
      </button>
    </div-->
    <div class="row">
      <table
        class="
          table
          table-striped
          table-hover
          table-bordered
          table-responsive-md
          table-sm
          row-scrollable
          re-table-height
        "
        id="table"
      >
        <thead>
          <tr>
            <th
              class="align-middle re-list-head text-center handler"
              rowspan="2"
              scope="col"
              @click="sortList(null, 'showPid', Number)"
              width="75"
            >
              No.
            </th>
            <th
              class="align-middle re-list-head text-center handler"
              rowspan="2"
              scope="col"
              @click="sortList(null, 'housBl', String)"
              width="75"
            >
              House B/L
            </th>
            <th
              class="align-middle re-list-head text-center handler"
              rowspan="2"
              scope="col"
              @click="sortList(null, 'consignee', String)"
              width="120"
            >
              Consignee
            </th>
            <th
              class="align-middle re-list-head text-center handler"
              scope="col"
              @click="sortList(null, 'etd', String)"
              width="40"
            >
              ETD
            </th>
            <th
              class="align-middle re-list-head text-center handler"
              rowspan="2"
              scope="col"
              @click="sortList(null, 'vessel', String)"
              width="50"
            >
              Vessel
            </th>
            <th
              class="align-middle re-list-head text-center handler"
              rowspan="2"
              scope="col"
              @click="sortList(null, 'pod', String)"
              width="75"
            >
              POD
            </th>
            <th
              class="align-middle re-list-head text-center handler"
              scope="col"
              @click="sortList(null, 'quantity', Number)"
              width="50"
            >
              QTY
            </th>
            <th
              class="align-middle re-list-head text-center handler"
              rowspan="2"
              scope="col"
              @click="sortList(null, 'shipper', String)"
              width="120"
            >
              Shipper
            </th>
          </tr>
          <tr>
            <th
              class="align-middle re-list-head text-center handler"
              scope="col"
              @click="sortList(null, 'eta', String)"
              width="40"
            >
              ETA
            </th>
            <th
              class="align-middle re-list-head text-center handler"
              scope="col"
              @click="sortList(null, 'packages', String)"
              width="75"
            >
              PKG
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="re-pointer"
            v-for="(link, lidx) in getDispList"
            :key="lidx"
          >
            <!--td
              class="align-middle text-center"
              scope="row"
              v-if="listMode == 1"
              @click="toDetail(link, lidx)"
            >
              <div class="form-check">
                <input
                  class="form-check-input position-static"
                  type="checkbox"
                  v-if="listMode == 1"
                  :checked="checkItems.indexOf(link.pid) != -1"
                  @change="checkConfirm(link, $event.target.checked)"
                />
              </div>
            </td-->
            <td
              class="align-middle text-center"
              scope="row"
              @click="toDetail(link, lidx)"
            >
              {{ link.showPid }}
            </td>
            <td
              class="align-middle text-center"
              scope="row"
              @click="toDetail(link, lidx)"
            >
              {{ link.housBl }}
            </td>
            <td
              class="align-middle text-center"
              scope="row"
              @click="toDetail(link, lidx)"
            >
              {{ link.consignee }}
            </td>
            <td
              class="align-middle text-center"
              scope="row"
              @click="toDetail(link, lidx)"
            >
              {{ link.etd }} <br />
              {{ link.eta }}
            </td>
            <td
              class="align-middle text-center"
              scope="row"
              @click="toDetail(link, lidx)"
            >
              {{ link.vessel }}
            </td>
            <td
              class="align-middle text-center"
              scope="row"
              @click="toDetail(link, lidx)"
            >
              {{ link.pod }}
            </td>
            <td
              class="align-middle text-center"
              scope="row"
              @click="toDetail(link, lidx)"
            >
              {{ link.quantity }} <br />
              {{ link.packages }}
            </td>
            <td
              class="align-middle text-center"
              scope="row"
              @click="toDetail(link, lidx)"
            >
              {{ link.shipper }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row re-table-pager col-md-12 justify-content-around">
    <div v-if="pageData.page > 1">
      <img
        class="re-icon-btn-md justify-content-start"
        @click="pageChange(-1)"
        alt="←"
        src="../assets/left.png"
      />
    </div>
    <div v-else>
      <a> </a>
    </div>
    {{ pageData.page }}/{{ pageData.totalPage }}
    <div v-if="pageData.page != pageData.totalPage">
      <img
        class="re-icon-btn-md justify-content-start"
        @click="pageChange(1)"
        alt="→"
        src="../assets/right.png"
      />
    </div>
    <div v-else>
      <a> </a>
    </div>
  </div>
  <transition-group name="bounce" tag="div" appear>
    <messageModal
      v-if="messageModalStatus.show === true"
      :messageModalStatus="messageModalStatus"
      @messagemodal-close="messageModalClose"
    />
  </transition-group>
</template>
<script>
import tableDragger from "table-dragger";
import HttpClient from "../modules/http";
import MessageModal from "./MessageModal.vue";
import { checkNumber } from "../modules/validation";

export default {
  components: { MessageModal },
  data: function () {
    return {
      isLoading: true,
      readonly: false,
      listDataRaw: null,
      listData: null,
      pageData: {
        page: 1,
        totalPage: null,
        dispRow: 10,
      },
      validationSearchCondition: {
        pid: checkNumber,
      },
      filterData: {
        pid: "",
        etdStart: this.getDate(),
        etdEnd: "",
        hoseBl: "",
        showAll: false,
      },
      orderData: {
        isOrder: false,
        orderColumn: "",
        orderType: "",
        isAsc: false,
        orderFunction: String,
      },
      httpClient: new HttpClient(),
      columnOrder: {
        0: 0,
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
        6: 6,
        7: 7,
        8: 8,
        9: 9,
        10: 10,
        11: 11,
        12: 12,
        13: 13,
        14: 14,
        15: 15,
        16: 16,
      },
      messageModalStatus: {
        show: false,
        message: [],
        messageType: "info",
        loading: false,
        button: false,
      },
      checkAllFlag: false,
      checkItems: [],
      routePushName: "",
    };
  },
  mounted: function () {
    var el = document.getElementById("table");
    var dragger = tableDragger(el, {
      mode: "column",
      dragHandler: ".handler",
      animation: 300,
    });
    dragger.on("drop", function (from, to) {
      console.log(from);
      console.log(to);
    });
    var elMobile = document.getElementById("mobiletable");
    var draggerMobile = tableDragger(elMobile, {
      mode: "column",
      dragHandler: ".mobilehandler",
      animation: 300,
    });
    draggerMobile.on("drop", function (from, to) {
      console.log(from);
      console.log(to);
    });

    $("#etd1").daterangepicker(
      {
        autoUpdateInput: false,
        singleDatePicker: true,
        autoApply: true,
        locale: {
          format: "YYYY-MM-DD",
          separator: " - ",
        },
      },
      (start) => {
        this.filterItem("etdStart", start.format("YYYY-MM-DD"));
      }
    );
    $("#etd2").daterangepicker(
      {
        autoUpdateInput: false,
        singleDatePicker: true,
        autoApply: true,
        locale: {
          format: "YYYY-MM-DD",
          separator: " - ",
        },
      },
      (start) => {
        this.filterItem("etdEnd", start.format("YYYY-MM-DD"));
      }
    );
    this.checkAuth();
  },
  computed: {
    getDispList: function () {
      if (this.listData != null && this.listData.length > 0) {
        return this.listData.slice(
          (this.pageData.page - 1) * this.pageData.dispRow,
          this.pageData.page * this.pageData.dispRow
        );
      } else {
        return null;
      }
    },
  },
  methods: {
    checkAuth() {
      this.isLoading = true;
      const url =
        "/auth/check/?accountName=" + this.$cookie.getCookie("accountName");
      this.httpClient.get(
        url,
        this.$cookie.getCookie("token"),
        this.onSuccessAuth,
        this.onErrorAuth
      );
    },
    onSuccessAuth() {
      this.getList();
      this.isLoading = false;
    },
    onErrorAuth(error) {
      if (
        error != null &&
        error.response != null &&
        "status" in error.response &&
        error.response.status == 401
      ) {
        this.messageModalStatus.message.push(
          "The session has expired. Please log in again."
        );
      } else {
        this.messageModalStatus.message.push(
          "An error has occurred. Please log in again."
        );
      }
      this.messageModalStatus.show = true;
      this.messageModalStatus.messageType = "warn";
      this.messageModalStatus.button = true;
      this.routePushName = "login";
    },
    messageModalClose() {
      this.messageModalStatus.show = false;
      if (this.routePushName) {
        this.$router.push({ name: this.routePushName });
      }
    },
    toDetail(link, index) {
      let forwardName = "request";
      console.log(index * this.pageData.page);
      console.log(forwardName);
      console.log(link);

      this.$store.dispatch("doShowPreAlertShowData", link);
      console.log(this.$store);

      this.$router.push({
        name: forwardName,
        params: { paramPid: link.pid, type: "edit" },
      });
    },
    pageChange(page) {
      this.pageData.page = this.pageData.page + page;
    },
    dispRowChange(value) {
      this.pageData.dispRow = value;
      this.pageSettings();
    },
    getDate() {
      var dt = new Date();
      dt.setDate(dt.getDate() - 14);

      var todayString =
        dt.getFullYear() +
        "-" +
        ("00" + (dt.getMonth() + 1)).slice(-2) +
        "-" +
        ("00" + dt.getDate()).slice(-2);

      return todayString;
    },
    getList() {
      this.isLoading = true;
      const params = {
        accountName: this.$cookie.getCookie("accountName"),
        pid: this.filterData.pid,
        etdStart: this.filterData.etdStart,
        etdEnd: this.filterData.etdEnd,
        hoseBl: this.filterData.hoseBl,
        showAll: this.filterData.showAll,
      };
      this.httpClient.save(
        "/uplist/",
        this.$cookie.getCookie("token"),
        params,
        this.onSuccessGetList,
        this.onErrorAuth
      );
    },
    onSuccessGetList(response) {
      console.log(response.data);
      this.listData = response.data.prelist;
      this.listDataRaw = JSON.stringify(this.listData);
      this.listDataRaw = JSON.parse(this.listDataRaw);
      this.initOrder();
      this.pageSettings();
      this.isLoading = false;
      this.$forceUpdate();
    },
    initOrder() {
      let listDataBuff = JSON.stringify(this.listData);
      listDataBuff = JSON.parse(listDataBuff);
      console.log(listDataBuff);

      let upperList = [];
      for (let buff in listDataBuff) {
        if (listDataBuff[buff].managementStatus > 0) {
          console.log(listDataBuff[buff]);
          upperList.push(listDataBuff[buff]);
        }
      }
      console.log(upperList);

      let normalList = [];
      for (let buff in listDataBuff) {
        let check = false;
        for (let buff2 in upperList) {
          if (listDataBuff[buff].pid == upperList[buff2].pid) {
            check = true;
          }
        }
        if (!check) {
          normalList.push(listDataBuff[buff]);
        }
      }
      const buffList = upperList.concat(normalList);
      console.log(upperList);
      console.log(normalList);
      console.log(buffList);
      this.listData = buffList;
    },
    reloadList() {
      this.pageData.page = 1;
      this.getList();
    },
    filterItem(name, value) {
      console.log(name + ":" + value);
      this.filterData[name] = value;
      this.initOrder();
      this.pageSettings();
      // No.の値が数値かチェック
      if (name == "pid") {
        console.log(name + ":" + value);
        let validation = this.validationSearchCondition[name];
        // idのinputPidを設定
        const elementDiv = document.getElementById("inputPid");
        if (validation) {
          if (value != "") {
            if (!validation(value)) {
              elementDiv.classList.add("is-invalid");
              elementDiv.classList.remove("is-valid");
            } else {
              elementDiv.classList.remove("is-invalid");
              elementDiv.classList.add("is-valid");
            }
          }
        }
      }
    },
    pageSettings() {
      this.pageData.totalPage = Math.ceil(
        this.listData.length / this.pageData.dispRow
      );
      if (this.pageData.totalPage == 0) {
        this.pageData.page = 1;
        this.pageData.totalPage = 1;
      } else {
        if (this.pageData.page > this.pageData.totalPage) {
          this.pageData.page = this.pageData.totalPage;
        }
      }
    },
    sortList(type, name, func, asc) {
      console.log(type);
      this.checkItems = [];
      if (asc == undefined || asc == null) {
        this.orderData.isAsc = !this.orderData.isAsc;
      } else {
        this.orderData.isAsc = asc;
      }

      this.orderData.orderColumn = name;
      this.orderData.orderType = type;
      this.orderData.isOrder = true;
      this.orderData.orderFunction = func;

      this.listData = this.listData.sort((a, b) => {
        if (type == null) {
          a = a[name];
          b = b[name];
        } else {
          if (
            a[type].length > 0 &&
            a[type][0][name] !== "" &&
            a[type][0][name] !== null
          ) {
            a = a[type][0][name];
          } else {
            if (func == Number) {
              a = -1;
            } else {
              a = "-";
            }
          }
          if (
            b[type].length > 0 &&
            b[type][0][name] !== "" &&
            b[type][0][name] !== null
          ) {
            b = b[type][0][name];
          } else {
            if (func == Number) {
              b = -1;
            } else {
              b = "-";
            }
          }
        }
        return (
          (func(a) === func(b) ? 0 : func(a) > func(b) ? 1 : -1) *
          (this.orderData.isAsc ? 1 : -1)
        );
      });
    },
    // EnterキーでTab移動できる関数
    focusNext(e) {
      const inputs = Array.from(
        e.target.form.querySelectorAll('input[type="text"]')
      );
      const index = inputs.indexOf(e.target);

      if (index < inputs.length) {
        inputs[index + 1].focus();
      }
    },
  },
};
</script>
<style>
.row-scrollable {
  overflow-x: auto !important;
  white-space: nowrap !important;
}
.row-scrollable > .col-scrollable {
  display: inline-block !important;
  float: none !important;
}
</style>
