<template>
  <div id="customermodal-overlay">
    <div
      id="customermodal-content"
      class="border col-md-10 col-lg-9 overflow-auto"
      @click="noClose()"
    >
      <div class="container-fluid">
        <div
          class="
            d-flex
            justify-content-between
            flex-wrap flex-md-nowrap
            align-items-center
            pt-3
            pb-2
            mb-3
            border-bottom
          "
        >
          <p class="h5">{{ title }}</p>
          <div class="col-md-10 d-flex justify-content-end">
            <button
              v-on:click="closeModal"
              type="button"
              class="btn btn-primary col-md-2"
            >
              Cancel
            </button>
          </div>
        </div>
        <div class="row">
          <div class="container col-md-6">
            <p>Condition</p>
            <p style="color: red">{{ error_message }}</p>
            <form class="align-items-center">
              <div class="form-row align-items-center">
                <div class="form-group col-md-6">
                  <input
                    type="text"
                    class="form-control"
                    id="inputSearchName"
                    placeholder="Name"
                    :value="customerSerachName"
                    @input="inputSearch('name', $event.target.value)"
                  />
                </div>
                <div class="form-group col-md-6">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="getDestination()"
                  >
                    Search
                  </button>
                </div>
              </div>
            </form>
            <div v-if="customerListView.length > 0">
              <table
                class="
                  table table-hover table-bordered table-sm
                  row-scrollable
                  re-table-height
                "
              >
                <thead>
                  <tr>
                    <th
                      scope="col"
                      class="re-list-head"
                      @click="sortList('code')"
                    >
                      Code
                    </th>
                    <th
                      scope="col"
                      class="re-list-head"
                      @click="sortList('name')"
                    >
                      Name
                    </th>
                  </tr>
                </thead>
                <tbody v-for="(customer, a) in customerListView" :key="a">
                  <tr v-if="a < 5" @click="setInputForm(a)">
                    <td scope="row">{{ customer.code }}</td>
                    <td>{{ customer.name }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row col-md-12 justify-content-around">
              <div v-if="customerListPage > 1">
                <img
                  class="re-icon-btn-sm justify-content-start"
                  @click="pageChange(-1)"
                  alt="←"
                  src="../../assets/left.png"
                />
              </div>
              <div v-else>
                <a> </a>
              </div>
              {{ customerListPage }}
              <div v-if="isNextPage()">
                <img
                  class="re-icon-btn-sm justify-content-start"
                  @click="pageChange(1)"
                  alt="→"
                  src="../../assets/right.png"
                />
              </div>
              <div v-else>
                <a> </a>
              </div>
            </div>
          </div>
          <br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "CustomerModal",
  props: ["index", "readonly", "customerData"],
  emits: ["update-customermodal", "customermodal-close"],
  data: function () {
    return {
      title: "■Customer Search",
      customerSerachName: "",
      error_message: "",
      customerList: [],
      customerListView: [],
      customerListViewFiltered: [],
      customerListPage: 1,
      cusomerListPageTarget: 0,
      isAsc: true,
      newCustomerData: this.customerData,
    };
  },
  methods: {
    closeModal() {
      this.$emit("customermodal-close");
    },
    noClose() {
      event.stopPropagation();
    },
    setInputForm(index) {
      const customerData = this.customerListView[index];
      document.getElementById("customer").value = customerData.code.replace(
        "FCS",
        ""
      );
      document.getElementById("consignee").value = customerData.name;
      console.log(this.preAlertDataSet);
      this.closeModal();
    },
    sortList(name) {
      this.isAsc = !this.isAsc;

      if (this.customerListPageTarget == 0) {
        this.customerList = this.customerList.sort((a, b) => {
          a = a[name];
          b = b[name];
          return (a === b ? 0 : a > b ? 1 : -1) * (this.isAsc ? 1 : -1);
        });
      } else {
        this.customerListViewFiltered = this.customerListViewFiltered.sort(
          (a, b) => {
            a = a[name];
            b = b[name];
            return (a === b ? 0 : a > b ? 1 : -1) * (this.isAsc ? 1 : -1);
          }
        );
      }

      this.pageChange(this.customerListPage * -1 + 1);
    },
    inputSearch(name, value) {
      if (name == "name") {
        this.customerSerachName = value;
      }
      this.customerListPageTarget = 0;
    },
    isNextPage() {
      if (this.customerListPageTarget == 0) {
        if (this.customerList.length > this.customerListPage * 5) {
          return true;
        } else {
          return false;
        }
      } else {
        if (this.customerListViewFiltered.length > this.customerListPage * 5) {
          return true;
        } else {
          return false;
        }
      }
    },
    pageChange(page) {
      this.customerListPage = this.customerListPage + page;
      if (this.customerListPageTarget == 0) {
        if (this.customerListPage * 5 > this.customerList.length) {
          this.customerListView = this.customerList.slice(
            (this.customerListPage - 1) * 5,
            this.customerList.length
          );
        } else {
          this.customerListView = this.customerList.slice(
            (this.customerListPage - 1) * 5,
            (this.customerListPage - 1) * 5 + 5
          );
        }
      } else {
        if (this.customerListPage * 5 > this.customerListViewFiltered.length) {
          this.customerListView = this.customerListViewFiltered.slice(
            (this.customerListPage - 1) * 5,
            this.customerList.length
          );
        } else {
          this.customerListView = this.customerListViewFiltered.slice(
            (this.customerListPage - 1) * 5,
            (this.customerListPage - 1) * 5 + 5
          );
        }
      }
    },
    getDestination() {
      this.error_message = "";
      if (this.customerSerachName == "" || this.customerSerachName == null) {
        this.error_message = "please input name";
        return "";
      }

      const get_dest_url =
        process.env.VUE_APP_TARGET_API_HOST +
        "/api/v1/recept/dest/list/?accountName=" +
        this.$cookie.getCookie("accountName") +
        "&name=" +
        this.customerSerachName;

      axios
        .get(get_dest_url, {
          headers: {
            Authorization: this.$cookie.getCookie("token"),
          },
          responseType: "json",
        })
        .then((response) => {
          // handle success
          if (200 <= response.status && response.status < 300) {
            if (response.data.cnt == 0) {
              this.error_message = "No Data";
            } else if (response.data.cnt > 50) {
              this.error_message =
                "There are more than 50 cases. Please add a condition.";
            }

            this.customerListPage = 1;
            this.customerList = response.data.customers;
            if (this.customerList.length > 8) {
              this.customerListView = this.customerList.slice(0, 5);
            } else {
              this.customerListView = this.customerList;
            }
          }
        })
        .catch((error) => {
          // handle error
          console.log(error);
          // if (error.status == 400) {
          // } else {
          // }
        })
        .finally(function () {});
    },
  },
};
</script>
<style>
#customermodal-content {
  z-index: 10001;
  padding: 1em;
  background: #fff;
  border-radius: 5%;
  border: 3px;
  border-color: black;
}

#customermodal-overlay {
  z-index: 10000;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);

  display: flex;
  align-items: center;
  justify-content: center;
}
</style>