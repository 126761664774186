import ConstType from "./constTypes"

export default class receptionData {
    constructor() {

        const constType = new ConstType();

        this.rceptionDataSet = {
            link: [],
        };
        this.linkDataSet = {
            linkId: "",
            receptionNo: "",
            companyId: "",
            userId: "",
            shipType: "",
            managementType: "",
            managementId: "",
            managementStatus: "",
            picId: "",
            branchCode: "",
            brokerCode: "",
            ledgerNo: "",
            is_cooperation: "",
            cooperationDatetime: "",
            customerLock: 0,
            company: {
                adress1: "",
                adress2: "",
                agentId: "",
                chugaiCustomerCode: "",
                clientCode: "",
                code: "",
                companyNo: "",
                countryCode: "",
                fax: "",
                fcCustomerCode: "",
                mail: "",
                name: "",
                name2: "",
                name3: "",
                tel: "",
                zip: "",
            },
            ship: [],
            item: [],
            delivery: [],
            files: [],
            regacyData: [],
        };
        this.shipDataSet = {
            shipId: "",
            receptionNo: "",
            shipType: "",
            jobNo: "",
            shipper: "",
            shipperName: "",
            corpNo: "",
            shipperMark: "",
            blNo: [],
            air: "",
            voyageName: "",
            voyage: "",
            surrendered: 0,
            jobCustomerCode: "",
            blCustomerCode: "",
            pol: "",
            pod: "",
            eta: "",
            etd: "",
        };
        this.itemDataSet = {
            itemId: "",
            receptionNo: "",
            invoiceNo: "",
            eta: "",
            etd: "",
            insuranceType: "",
            containerDataSet: [],
            quantity: "",
            quantityType: constType.makeInitQuantityTypes()[0],
            quantity2: "",
            quantityType2: constType.makeInitQuantityTypes()[1],
            memo: "",
            packing: "",
            weight: "",
            weightType: "KG",
            measure: "",
            measureType: "M3",
            name: "",
            note: "",
            applyTypes: [
                "syokuhin1",
                "syokuken1",
                "douken1",
                "syokuhin2",
                "syokuken2",
                "douken2",
                "olt",
                "zanpach",
                "jyo14",
                "jyo17",
                "kanwari",
            ],
        };
        this.preAlertBlDataSet = {
            fileId: "",
            companyId: "",
            userId: "",
            receptionNo: "",
            attachedTypes: "",
            fileName: "",
            fileType: "",
        };
        this.deliveryDataSet = {
            deliveryId: "",
            receptionNo: "",
            sendType: "",
            preferredDate: "",
            preferredTime: "",
            nums: "",
            name: "",
            tel: "",
            fax: "",
            mail: "",
            zip: "",
            countryCode: "JP",
            adress1: "",
            adress2: "",
            slipNumber: "",
            otherManagemantNo: "",
            care: "",
            note: "",
            deliveryFrom: "",
            broker: "",
            deliveryType: "",
            isNewRegist: 0,
        };
        //        this.init();
    }

    makeInitReceptionDataSet() {
        this.rceptionDataSet.link.push(this.makeInitLinkDataSet)
        return this.rceptionDataSet;
    }

    makeInitLinkDataSet() {
        this.linkDataSet.ship.push(this.shipDataSet);
        this.linkDataSet.item.push(this.itemDataSet);
        this.linkDataSet.files.push(this.attachDataSet);
        this.linkDataSet.files.push(this.preAlertBlDataSet);
        this.linkDataSet.delivery.push(this.deliveryDataSet);
        return this.linkDataSet;
    }

    makeInitShipDataSet() {
        return this.shipDataSet;
    }

    makeInitItemDataSet() {
        return this.itemDataSet;
    }

    makeInitpreAlertBlDataSet() {
        return this.preAlertBlDataSet;
    }

    makeInitDeliveryDataSet() {
        return this.preAlertBlDataSet;
    }

}