import axios from "axios";
import { decycle } from "json-cyclic";

export default {
    data: function () {
        return {
            attachFilesVisible: true,
            attachFilesDataset: [],
            // attachFileTypes: {
            //     invoice: false,
            //     packing: false,
            //     bl: false,
            //     arraival: false,
            //     picturePattern: false,
            //     certificateOfOrigin: false,
            //     evaluationDocuments: false,
            //     evaluationStatement: false,
            //     contract: false,
            //     cuttingReport: false,
            //     confirmationReport: false,
            //     other: false,
            // },
        };
    },
    methods: {
        deleteAttached() {
            this.attachFilesVisible = false;
            this.attachFiles = [];
            // this.attachFileTypes = {
            //     invoice: false,
            //     packing: false,
            //     bl: false,
            //     arraival: false,
            //     picturePattern: false,
            //     certificateOfOrigin: false,
            //     evaluationDocuments: false,
            //     evaluationStatement: false,
            //     contract: false,
            //     cuttingReport: false,
            //     confirmationReport: false,
            //     other: false,
            // };
        },
        changeAttachedVisible() {
            this.attachFilesVisible = !this.attachFilesVisible;
            this.scrollTarget("attach-top");
        },
        updateFileattachedType(value) {
            this.attachFileTypes = value;
        },
        deleteFileattached(value) {
            this.attachFilesDataset.splice(value, 1);
        },
        updateFileattached(value) {
            this.attachFilesDataset = value;
        },
        // buildAttachFileTypesParam() {
        //     let param = "";
        //     // const typeNames = [
        //     //     "invoice",
        //     //     "packing",
        //     //     "bl",
        //     //     "arraival",
        //     //     "picturePattern",
        //     //     "certificateOfOrigin",
        //     //     "evaluationDocuments",
        //     //     "evaluationStatement",
        //     //     "contract",
        //     //     "cuttingReport",
        //     //     "confirmationReport",
        //     //     "other",
        //     // ];

        //     typeNames.forEach((typeName) => {
        //         if (this.attachFileTypes[typeName]) {
        //             param = param + typeName + ",";
        //         }
        //     });

        //     if (param.length > 2) {
        //         return param.substring(0, param.length - 1);
        //     } else {
        //         return param;
        //     }
        // },

        saveAttachedFiles(pid) {
            const save_file_url =
                process.env.VUE_APP_TARGET_API_HOST +
                "/api/v1/prealert/attached/";
            this.attachFilesDataset.forEach((file) => {
                var reader = new FileReader();
                var p = new Promise(function (resolve, reject) {
                    // 1秒以上かかった場合、エラーとする
                    window.setTimeout(function () {
                        reject("timeout");
                    }, 1000);
                    reader.onload = (function () {
                        return function (e) {
                            // 「data:text/plain;base64,xxxxx」がresult、なのでカンマで区切って内容を取得
                            var fileBase64 = e.target.result.split(",")[1];
                            resolve(fileBase64);
                        };
                    })();
                });
                // 選択したファイルを読込む
                reader.readAsDataURL(file.file);
                p.then(
                    (fileBase64) => {
                        var data = {
                            fileName: file.fileName,
                            fileType: file.fileType,
                            file: fileBase64,
                        };
                        //const attachedTypes = this.buildAttachFileTypesParam();
                        // ファイル読み込みが終了してから、通信開始
                        const save_file_params = {
                            agentId: this.$cookie.getCookie("agentId"),
                            accountName: this.$cookie.getCookie("accountName"),
                            pid: pid,
                            //attachedTypes: attachedTypes,
                            file: data,
                        };

                        const save_file_params_json = JSON.stringify(
                            decycle(save_file_params)
                        );

                        axios
                            .post(save_file_url, save_file_params_json, {
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: this.$cookie.getCookie("token"),
                                },
                                responseType: "json",
                            })
                            .then((response) => {
                                // handle success
                                if (200 <= response.status && response.status < 300) {
                                    this.apiSendStatus.attached = 1;
                                }
                            })
                            .catch((error) => {
                                // handle error
                                console.log(error);
                                this.apiSendStatus.attached = 2;
                                // if (error.status == 400) {
                                // } else {
                                // }
                            })
                            .finally(function () { });
                    },
                    function (errMsg) {
                        var msg = document.getElementById("uploadMsg");
                        msg.innerHTML = errMsg;
                        msg.classList.add("label-warning");
                    }
                );
            });
        },
        editAttachedFiles(pid) {
            const save_file_url =
                process.env.VUE_APP_TARGET_API_HOST +
                "/api/v1/prealert/attached/";
            this.attachFilesDataset.forEach((file) => {
                if (file.file == null) {
                    //const attachedTypes = this.buildAttachFileTypesParam();
                    // ファイル読み込みが終了してから、通信開始
                    const save_file_params = {
                        //agentId: this.$cookie.getCookie("agentId"),
                        accountName: this.$cookie.getCookie("accountName"),
                        pid: pid,
                        //attachedTypes: attachedTypes,
                        file: file,
                    };

                    const save_file_params_json = JSON.stringify(
                        decycle(save_file_params)
                    );

                    axios
                        .put(save_file_url, save_file_params_json, {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: this.$cookie.getCookie("token"),
                            },
                            responseType: "json",
                        })
                        .then((response) => {
                            // handle success
                            if (200 <= response.status && response.status < 300) {
                                this.apiSendStatus.attached = 1;
                            }
                        })
                        .catch((error) => {
                            // handle error
                            console.log(error);
                            this.apiSendStatus.attached = 2;
                            // if (error.status == 400) {
                            // } else {
                            // }
                        })
                        .finally(function () { });
                } else {
                    var reader = new FileReader();
                    var p = new Promise(function (resolve, reject) {
                        // 1秒以上かかった場合、エラーとする
                        window.setTimeout(function () {
                            reject("timeout");
                        }, 1000);
                        reader.onload = (function () {
                            return function (e) {
                                // 「data:text/plain;base64,xxxxx」がresult、なのでカンマで区切って内容を取得
                                var fileBase64 = e.target.result.split(",")[1];
                                resolve(fileBase64);
                            };
                        })();
                    });
                    // 選択したファイルを読込む
                    reader.readAsDataURL(file.file);
                    p.then(
                        (fileBase64) => {
                            var data = {
                                fileName: file.fileName,
                                //fileType: file.fileType,
                                file: fileBase64,
                            };
                            //const attachedTypes = this.buildAttachFileTypesParam();
                            // ファイル読み込みが終了してから、通信開始
                            const save_file_params = {
                                //agentId: this.$cookie.getCookie("agentId"),
                                accountName: this.$cookie.getCookie("accountName"),
                                pid: pid,
                                //attachedTypes: attachedTypes,
                                file: data,
                            };

                            const save_file_params_json = JSON.stringify(
                                decycle(save_file_params)
                            );

                            axios
                                .put(save_file_url, save_file_params_json, {
                                    headers: {
                                        "Content-Type": "application/json",
                                        Authorization: this.$cookie.getCookie("token"),
                                    },
                                    responseType: "json",
                                })
                                .then((response) => {
                                    // handle success
                                    if (200 <= response.status && response.status < 300) {
                                        this.apiSendStatus.attached = 1;
                                    }
                                })
                                .catch((error) => {
                                    // handle error
                                    console.log(error);
                                    this.apiSendStatus.attached = 2;
                                    // if (error.status == 400) {
                                    // } else {
                                    // }
                                })
                                .finally(function () { });
                        },
                        function (errMsg) {
                            var msg = document.getElementById("uploadMsg");
                            msg.innerHTML = errMsg;
                            msg.classList.add("label-warning");
                        }
                    );
                }
            });
        },
        deleteAttachedFiles() {
            for (let index = 0; index < this.editDataSet[0].files.length; index++) {
                if (!this.checkRemoveFile(this.editDataSet[0].files[index].fileId)) {
                    const save_file_url =
                        process.env.VUE_APP_TARGET_API_HOST +
                        "/api/v1/prealert/attached/?accountName=" + this.$cookie.getCookie("accountName")
                        + "&fileId=" + this.editDataSet[0].files[index].fileId
                        ;
                    axios.delete(save_file_url, {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: this.$cookie.getCookie("token"),
                        },
                        responseType: "json",
                    })
                        .then((response) => {
                            // handle success
                            console.log(response);
                            if (200 <= response.status && response.status < 300) {
                                console.log(response);
                            }
                        })
                        .catch((error) => {
                            // handle error
                            console.log(error);
                            // if (error.status == 400) {
                            // } else {
                            // }
                        })
                        .finally(function () { });
                }
            }
        },
        checkRemoveFile(fileId) {
            let result = false;
            this.attachFilesDataset.forEach(
                element => {
                    if (element.fileId == fileId) {
                        result = true;
                    }
                }
            );

            return result;
        },
    },
};