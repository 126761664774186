<template>
  <div id="messagemodal-overlay">
    <div id="messagemodal-content" class= "border col-md-1 col-lg-3" @click="noClose()">
      <div class="container">
        <br />
        <br />
        <br />
        <div class="row d-flex justify-content-center">
        <img
          v-if="messageModalStatus.messageType == 'info'"
          class="re-icon-btn-md justify-content-center"
          alt="情報"
          src="../assets/info.png"
        />
        <img
          v-if="messageModalStatus.messageType == 'warn'"
          class="re-icon-btn-md justify-content-center"
          alt="注意"
          src="../assets/warn.png"
        />
        </div>
        <br />
        <div>
          <div class="row d-flex justify-content-center" v-for="(message,idx) in messageModalStatus.message" :key="idx">
            <p>{{ message }}</p>
          </div>
        </div>
        <br />
        <div class="row d-flex justify-content-center">
          <div v-if="messageModalStatus.loading === true" class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <br />
        <div class="col-md-12 d-flex justify-content-around">
          <button
            v-if="messageModalStatus.button === true"
            v-on:click="closeModal"
            type="button"
            class="btn btn-primary col-md-3"
          >
            OK
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MessageModal",
  props: ["messageModalStatus"],
  emits: ["messagemodal-close"],
  data: function () {
    return {};
  },
  methods: {
    closeModal() {
      this.$emit("messagemodal-close");
    },
    noClose() {
      event.stopPropagation();
    },
  },
};
</script>
<style>
#messagemodal-content {
  z-index: 10003;
  /* width: 20%; */
  padding: 1em;
  background: #fff;
  border-radius: 10%;
  border: 3px;
  border-color: black;
}

#messagemodal-overlay {
  z-index: 10002;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);

  display: flex;
  align-items: center;
  justify-content: center;
}
</style>