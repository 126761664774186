export default class Utility {
    static capitalize(str) {
        if (!str || typeof str !== 'string') return str;
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    static decodeFileName(str){
        // let fileName = str.substring(str.indexOf("''") + 2,
        // str.length
        // );
        // fileName = decodeURI(fileName).replace(/\+/g, " ");
        let fileName = str.split("=");

        return decodeURI(fileName[1]);
    }

    static convertDateAddHyphen(target){
        if(target == null || target == ""){
            return ""
        }

        if(target.length == 8){
            return target.slice(0,4) + "-" + target.slice(4,6)+ "-" + target.slice(6,8)
        }else{
            return target;
        }
    }
}