<template>
  <div
    class="attach item container-fluid border rounded-lg shadow border-dark re-content-body"
  >
    <br />
    <div class="container-fluid d-flex flex-row">
      <div class="container">
        <img
          v-if="readonly === false"
          class="re-icon-btn-md"
          @click="removeAttached()"
          alt="削除"
          src="../../assets/minus.png"
        />

        <div
          v-if="readonly === false"
          class="drop_area"
          @dragenter="dragEnter"
          @dragleave="dragLeave"
          @dragover.prevent
          @drop.prevent="dropFile($event.dataTransfer.files)"
          :class="{ enter: isEnter }"
        >
          file upload<br />
          (Drag and drop the file here)
        </div>
        <div v-for="(attachFile, f) in attachFiles" :key="f">
          <img
            v-if="readonly === false"
            class="re-icon-btn-sm"
            id="inputDelete"
            @click="deleteAttachFile(f)"
            alt="削除"
            src="../../assets/minus.png"
          />
          {{ attachFile.fileName }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AttachFiles",
  props: [
    "index",
    "readonly",
    "attachFiles",
    "attachFileTypes",
    "preAlertBlDataSet",
  ],
  emits: [
    "updateAttachedfile",
    "updateAttachedtype",
    "deleteAttachedfile",
    "deleteAttached",
  ],
  data: function () {
    return {
      isEnter: false,
      newAttachFiles: [],
      newAttachFilesType: null,
    };
  },
  methods: {
    dragEnter() {
      this.isEnter = true;
    },
    dragLeave() {
      this.isEnter = false;
    },
    dropFile(value) {
      // value.forEach(element => {
      this.newAttachFiles = this.attachFiles;
      this.newAttachFiles.shift();
      const file = {
        fileName: value[0].name,
        fileType: value[0].type,
        fileSize: value[0].size1,
        file: value[0],
      };
      this.newAttachFiles.push(file);
      //});
      this.isEnter = false;
      this.updateFileattached();
    },
    removeAttached() {
      this.$emit("delete-attached");
    },
    deleteAttachFile(index) {
      this.$emit("delete-fileattached", index);
    },
    updateFileattached() {
      this.$emit("update-attachedfile", this.newAttachFiles);
    },
    checkAttachFileTypes(name, value) {
      this.newAttachFilesType = this.attachFileTypes;
      this.newAttachFilesType[name] = value;
      this.$emit("update-attachedtype", this.newAttachFilesType);
    },
  },
};
</script>
<style>
.drop_area {
  color: gray;
  font-weight: bold;
  font-size: 1.2em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 280px;
  border: 5px solid gray;
  border-radius: 15px;
  text-align: center;
}
.enter {
  border: 10px dotted powderblue;
}
</style>