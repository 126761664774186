<template>
  <br />
  <br />
  <div v-show="checkAuthShow">認証中</div>
  <div v-show="!checkAuthShow">
    <div class="container border rounded-lg shadow border-dark re-content-body">
      <br />
      <table
        width="96%"
        Border="0"
        Cellspacing="0"
        CellPadding="0"
        class="pt9n"
      >
        <tr>
          <td class="regacy-td" width="35%">
            ■受付番号：&nbsp;{{ linkDataSet.receptionNo }}
          </td>
        </tr>
      </table>
      <hr width="96%" size="1" color="blue" />
      <table
        width="96%"
        Border="0"
        Cellspacing="0"
        CellPadding="0"
        class="pt9n"
      >
        <tr>
          <td class="regacy-td" width="35%">■取り扱い&nbsp;</td>
        </tr>
      </table>
      <hr width="96%" size="1" color="blue" />
      <table width="96%" Border="0" Cellspacing="0" CellPadding="0" class="pt9">
        <tr>
          <td>
            <span class="pt9r">支店：&nbsp;</span>
            <select
              name="branchCode"
              v-model="linkDataSet.branchCode"
              @change="onChangeBranchCode($event.target.value)"
            >
              <option value="FC-O">FC大阪</option>
              <option value="FC-T">FC東京</option>
              <option value="FC-N">FC名古屋</option>
              <option value="FC-K">FC海上</option>
              <option value="CH-O">中外大阪</option>
              <option value="CH-K">中外神戸</option>
              <option value="CH-T">中外東京</option>
              <option value="CH-N">中外名古屋</option>
              <option value="CH-S">中外大阪倉庫</option>
              <option value="CH-D">中外尼崎倉庫</option>
              <option value="CH-C">中外東京倉庫</option>
              <option value="CH-M">中外摩耶倉庫</option>
            </select>
          </td>
          <td>
            <span class="pt9r">通関業者コード：&nbsp;</span>
            <select name="branchCode" v-model="linkDataSet.brokerCode">
              <option value="">選択してください。</option>
              <option
                v-for="(custom, idx) in dispCustomCode"
                :key="idx"
                :value="custom.code"
              >
                {{ custom.disp }}
              </option>
            </select>
          </td>
        </tr>
      </table>
      <br />
      <table
        width="96%"
        Border="0"
        Cellspacing="0"
        CellPadding="0"
        class="pt9n"
      >
        <tr>
          <td class="regacy-td" width="35%">■荷主情報&nbsp;</td>
          <td width="40%" class="regacy-td pt9g"></td>
        </tr>
      </table>
      <hr width="96%" size="1" color="blue" />
      <table width="96%" Border="0" Cellspacing="0" CellPadding="0" class="pt9">
        <tr height="22">
          <td class="regacy-td">台帳番号</td>
          <td class="regacy-td">
            <input
              class="si"
              name="JobCode"
              size="12"
              maxlength="10"
              v-model="linkDataSet.ledgerNo"
              v-bind:readonly="readonly"
            />
          </td>
        </tr>
        <tr height="22">
          <td class="regacy-td">請求荷主</td>
          <td class="regacy-td">
            <input
              type="text"
              class="si"
              name="ClientName"
              id="inputClientName"
              size="40"
              maxlength="100"
              v-bind:readonly="true"
              :value="shipDataSet.shippingCompanies"
              @input="inputShip('shippingCompanies', $event.target.value)"
            />&nbsp;<span class="pt9r">コード：&nbsp;</span
            ><input
              class="si"
              name="BlCustomerCode"
              size="10"
              maxlength="10"
              v-model="shipDataSet.jobCustomerCode"
              v-bind:readonly="readonly"
            />
          </td>
        </tr>
        <tr height="22">
          <td class="regacy-td">実輸入者</td>
          <td class="regacy-td">
            <input
              type="text"
              class="si"
              name="ShipperName"
              size="40"
              id="inputShipperName"
              maxlength="100"
              autocomplete="on"
              :value="shipDataSet.shipperName"
              @input="inputShip('ShipperName', $event.target.value)"
              v-bind:readonly="readonly"
            /><i class="fas fa-search" @click="getCustomerList()"></i
            >&nbsp;<span class="pt9r">コード：&nbsp;</span
            ><input
              class="si"
              name="ShipperBlCustomerCode"
              size="10"
              maxlength="10"
              v-model="shipDataSet.blCustomerCode"
              v-bind:readonly="readonly"
            />
          </td>
        </tr>
        <tr height="22">
          <td class="regacy-td">TEL</td>
          <td class="regacy-td">
            <input
              class="si"
              name="PIC_TEL"
              size="20"
              maxlength="50"
              v-model="linkDataSet.company.tel"
              v-bind:readonly="readonly"
            />
            FAX&nbsp;<input
              class="si"
              name="PIC_FAX"
              size="20"
              maxlength="50"
              :value="linkDataSet.company.fax"
              v-bind:readonly="readonly"
            />
          </td>
        </tr>
        <tr height="22">
          <td class="regacy-td">SHIPPER</td>
          <td class="regacy-td" colspan="3">
            <input
              class="si"
              name="JobShipperInfo"
              size="66"
              maxlength="100"
              v-model="shipDataSet.shipper"
            />
          </td>
        </tr>
      </table>
      <br />
      <table width="96%" Border="0" Cellspacing="0" CellPadding="0">
        <tr>
          <td class="regacy-td" width="45%" valign="top">
            <table
              width="100%"
              Border="0"
              Cellspacing="0"
              CellPadding="0"
              class="pt9"
            >
              <tr height="20">
                <td nowrap class="regacy-td pt9n">■B/L情報&nbsp;</td>
              </tr>
              <tr>
                <td colspan="2"><hr width="98%" size="1" color="blue" /></td>
              </tr>
            </table>
            <table
              width="100%"
              Border="0"
              Cellspacing="0"
              CellPadding="0"
              class="pt9"
            >
              <tr height="22">
                <td class="regacy-td" colspan="2">
                  <span class="pt9r">POLN</span>
                  <input
                    class="si"
                    name="POL"
                    size="11"
                    maxlength="50"
                    :value="shipDataSet.pol"
                    @input="filterIata('pol', $event.target.value)"
                    v-bind:readonly="readonly"
                  />
                  ({{ dispPol }})
                </td>
                <td rowspan="10">
                  <table width="85%" cellspacing="0" border="1" class="pt9n">
                    <tr align="center">
                      <th></th>
                      <th align="center" class="regacy-td">申請</th>
                      <th align="center" class="regacy-td">検査</th>
                    </tr>
                    <tr align="center">
                      <td class="regacy-td">食品</td>
                      <td class="regacy-td">
                        <input
                          type="checkbox"
                          name="Status1"
                          :checked="applyTypes.syokuhin1"
                        />
                      </td>
                      <td class="regacy-td">
                        <input
                          type="checkbox"
                          name="Status1"
                          :checked="applyTypes.syokuhin2"
                        />
                      </td>
                    </tr>
                    <tr align="center">
                      <td class="regacy-td">植検</td>
                      <td class="regacy-td">
                        <input
                          type="checkbox"
                          name="Status2"
                          :checked="applyTypes.syokuken1"
                        />
                      </td>
                      <td class="regacy-td">
                        <input
                          type="checkbox"
                          name="Status2"
                          :checked="applyTypes.syokuken2"
                        />
                      </td>
                    </tr>
                    <tr align="center">
                      <td class="regacy-td">動検</td>
                      <td class="regacy-td">
                        <input
                          type="checkbox"
                          name="Status3"
                          :checked="applyTypes.douken1"
                        />
                      </td>
                      <td class="regacy-td">
                        <input
                          type="checkbox"
                          name="Status3"
                          :checked="applyTypes.douken2"
                        />
                      </td>
                    </tr>
                  </table>
                  <br />
                  <table width="85%" cellspacing="0" border="1" class="pt9n">
                    <tr align="center">
                      <td class="regacy-td">OLT (保税運送)</td>
                      <td class="regacy-td">
                        <input
                          type="checkbox"
                          name="Status4"
                          :checked="applyTypes.olt"
                        />
                      </td>
                    </tr>
                    <tr align="center">
                      <td class="regacy-td">
                        暫八 <br />(関税暫定措置法 第8条 加工再輸入減免税)
                      </td>
                      <td class="regacy-td">
                        <input
                          type="checkbox"
                          name="Status5"
                          :checked="applyTypes.zanpach"
                        />
                      </td>
                    </tr>
                    <tr align="center">
                      <td class="regacy-td">
                        14条 <br />(関税定率法 第14条 再輸入免税)
                      </td>
                      <td class="regacy-td">
                        <input
                          type="checkbox"
                          name="Status6"
                          :checked="applyTypes.jyo14"
                        />
                      </td>
                    </tr>
                    <tr align="center">
                      <td class="regacy-td">
                        17条 <br />(関税定率法 第17条 再輸出免税)
                      </td>
                      <td class="regacy-td">
                        <input
                          type="checkbox"
                          name="Status7"
                          :checked="applyTypes.jyo17"
                        />
                      </td>
                    </tr>
                    <tr align="center">
                      <td class="regacy-td">関割 (関税割当)</td>
                      <td class="regacy-td">
                        <input
                          type="checkbox"
                          name="Status8"
                          :checked="applyTypes.kanwari"
                        />
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr height="22">
                <td class="regacy-td" colspan="2">
                  <span class="pt9r">PODN</span>
                  <input
                    class="si"
                    name="POL"
                    size="11"
                    maxlength="50"
                    :value="shipDataSet.pod"
                    @input="filterIata('pod', $event.target.value)"
                    v-bind:readonly="readonly"
                  />
                  ({{ dispPod }})
                </td>
              </tr>
              <tr height="22">
                <td class="regacy-td">VESSEL</td>
                <td class="regacy-td">
                  <input
                    class="si"
                    name="Vessel"
                    size="15"
                    maxlength="50"
                    v-model="shipDataSet.voyageName"
                  />
                  VOY&nbsp;<input
                    class="si"
                    name="Voy"
                    size="8"
                    maxlength="20"
                    v-model="shipDataSet.voyage"
                  />
                </td>
              </tr>
              <tr height="22">
                <td class="regacy-td">ETD</td>
                <td class="regacy-td">
                  <input
                    class="si"
                    name="ETD"
                    size="10"
                    maxlength="10"
                    v-model="shipDataSet.etd"
                  /><i id="inputETD" class="fa fa-calendar"></i> ETA<input
                    class="si"
                    name="ETA"
                    size="10"
                    maxlength="10"
                    v-model="shipDataSet.eta"
                  /><i id="inputETA" class="fa fa-calendar"></i>
                </td>
              </tr>
              <tr height="22">
                <td class="">B/L</td>
                <td class="regacy-td">
                  <input
                    v-for="(bl, blindex) in shipDataSet.blNo"
                    :key="blindex"
                    class="si"
                    :name="'MBL' + blindex"
                    size="15"
                    maxlength="50"
                    v-model="bl.no"
                  />
                </td>
              </tr>
              <tr height="22">
                <td class="">FCSL Job№</td>
                <td class="regacy-td">
                  <input
                    class="si"
                    name="JobNo"
                    size="15"
                    maxlength="50"
                    v-model="shipDataSet.jobNo"
                  />
                </td>
              </tr>
              <tr height="22">
                <td class="regacy-td">品名</td>
                <td class="regacy-td">
                  <input
                    class="si"
                    name="Commodity"
                    size="32"
                    maxlength="100"
                    v-model="itemDataSet.name"
                  />
                </td>
              </tr>
              <tr height="22">
                <td class="regacy-td">INV№</td>
                <td class="regacy-td">
                  <input
                    class="si"
                    name="InvoiceNo"
                    size="32"
                    maxlength="60"
                    v-model="itemDataSet.invoiceNo"
                  />
                </td>
              </tr>
              <tr height="22">
                <td class="regacy-td">個数1</td>
                <td class="regacy-td">
                  <input
                    class="sn"
                    name="Quantity"
                    v-model="itemDataSet.quantity"
                    size="9"
                    maxlength="10"
                  />
                  荷姿&nbsp;<input
                    class="si"
                    name="Package"
                    v-model="itemDataSet.quantityType"
                    size="10"
                    maxlength="20"
                  />&nbsp;
                </td>
              </tr>
              <tr height="22">
                <td class="regacy-td">個数2</td>
                <td class="regacy-td">
                  <input
                    class="sn"
                    name="Quantity"
                    v-model="itemDataSet.quantity2"
                    size="9"
                    maxlength="10"
                  />
                  荷姿&nbsp;<input
                    class="si"
                    name="Package"
                    v-model="itemDataSet.quantityType2"
                    size="10"
                    maxlength="20"
                  />&nbsp;
                </td>
              </tr>
              <tr height="22">
                <td class="regacy-td">メモ</td>
                <td class="regacy-td">
                  <input
                    class="si"
                    name="Quantity"
                    v-model="itemDataSet.memo"
                    size="32"
                    maxlength="10"
                  />
                </td>
              </tr>
              <tr height="22">
                <td class="regacy-td">WEIGHT</td>
                <td class="regacy-td">
                  <input
                    class="sn"
                    name="GW"
                    v-model="itemDataSet.weight"
                    size="9"
                    maxlength="10"
                  />&nbsp;KGS
                  <input
                    class="sn"
                    name="RTON"
                    value="0.000"
                    size="8"
                    maxlength="10"
                  />
                  R/TON
                </td>
              </tr>
              <tr height="22">
                <td class="regacy-td">MEASURE</td>
                <td class="regacy-td">
                  <input
                    class="sn"
                    name="CBM"
                    v-model="itemDataSet.measure"
                    size="9"
                    maxlength="10"
                  />&nbsp;M<sup>3</sup> (<input
                    class="sn"
                    name="SAI"
                    value="0.000"
                    size="10"
                  />KGS)
                </td>
              </tr>
              <tr height="22">
                <td class="regacy-td">ｺﾝﾃﾅ№</td>
                <td class="regacy-td">
                  <div
                    v-for="(conte, cindex) in itemDataSet.containerDataSet"
                    :key="cindex"
                  >
                    <input
                      class="si"
                      name="CBM"
                      v-model="conte.no"
                      size="9"
                      maxlength="10"
                    />
                    ｺﾝﾃﾅｻｲｽﾞ
                    <select name="DeliveryType" v-model="conte.type">
                      <option value="">選択してください。</option>
                      <option
                        v-for="(contype, idx) in containerTypes"
                        :key="idx"
                        :value="idx"
                      >
                        {{ contype }}
                      </option>
                    </select>
                  </div>
                </td>
              </tr>
            </table>
          </td>
          <td class="regacy-td" width="20%" valign="top">
            <table
              width="100%"
              Border="0"
              Cellspacing="0"
              CellPadding="0"
              class="pt9"
            >
              <tr height="20">
                <td nowrap class="regacy-td pt9n">
                  ■添付書類(※添付書類はデータ連携を行いません。)
                  <input type="hidden" name="JobOperator" value="101" />
                </td>
                <td nowrap class="regacy-td pt9n" align="right"></td>
              </tr>
              <tr>
                <td class="regacy-td" colspan="2">
                  <hr width="98%" size="1" color="blue" />
                </td>
              </tr>
              <tr>
                <td>
                  <table cellspacing="0" border="1" class="pt9n">
                    <tr>
                      <td>
                        <tr align="center">
                          <td class="regacy-td">絵型</td>
                          <td class="regacy-td">
                            <input
                              type="checkbox"
                              name="Status1"
                              :checked="attachFileTypes.picturePattern"
                            />
                          </td>
                          <td class="regacy-td">原産地証明書</td>
                          <td class="regacy-td">
                            <input
                              type="checkbox"
                              name="Status2"
                              :checked="attachFileTypes.certificateOfOrigin"
                            />
                          </td>
                          <td class="regacy-td">評価書類</td>
                          <td class="regacy-td">
                            <input
                              type="checkbox"
                              name="Status3"
                              :checked="attachFileTypes.evaluationDocuments"
                            />
                          </td>
                          <td class="regacy-td">評価計算書</td>
                          <td class="regacy-td">
                            <input
                              type="checkbox"
                              name="Status4"
                              :checked="attachFileTypes.evaluationStatement"
                            />
                          </td>
                        </tr>
                        <tr align="center"></tr>
                        <tr align="center"></tr>
                        <tr align="center"></tr>
                        <tr align="center">
                          <td class="regacy-td">契約書</td>
                          <td class="regacy-td">
                            <input
                              type="checkbox"
                              name="Status5"
                              :checked="attachFileTypes.contract"
                            />
                          </td>
                          <td class="regacy-td">裁断報告書</td>
                          <td class="regacy-td">
                            <input
                              type="checkbox"
                              name="Status6"
                              :checked="attachFileTypes.cuttingReport"
                            />
                          </td>
                          <td class="regacy-td">確認申告書</td>
                          <td class="regacy-td">
                            <input
                              type="checkbox"
                              name="Status7"
                              :checked="attachFileTypes.confirmationReport"
                            />
                          </td>
                          <td class="regacy-td">その他</td>
                          <td class="regacy-td">
                            <input
                              type="checkbox"
                              name="Status8"
                              :checked="attachFileTypes.other"
                            />
                          </td>
                        </tr>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <br />
            <table>
              <tr>
                <td v-if="attachFilesDataSet.length > 0">
                  <div
                    v-for="(filedata, findex) in attachFilesDataSet"
                    :key="findex"
                  >
                    <a href="#" @click="download(filedata.fileId)">
                      {{ filedata.fileName }}
                    </a>
                  </div>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      <br />
      <table
        width="96%"
        Border="0"
        Cellspacing="0"
        CellPadding="0"
        class="pt9n"
      >
        <tr>
          <td class="regacy-td" width="35%">■納品情報</td>
        </tr>
      </table>
      <hr width="96%" size="1" color="blue" />
      <table width="90%" Border="0" Cellspacing="0" CellPadding="0" class="pt9">
        <tr height="22">
          <td class="regacy-td pt9g">希望納品日</td>
          <td class="regacy-td">
            <input
              class="si"
              name="preferredDate"
              v-model="deliveryDataSet.preferredDate"
            /><i id="inputOrderDate1" class="fa fa-calendar"></i>
            希望納品時間帯&nbsp;
            <input
              class="si"
              name="preferredTime"
              v-model="deliveryDataSet.preferredTime"
            />
          </td>
        </tr>
        <tr height="22">
          <td class="regacy-td">納品先名&nbsp;</td>
          <td>
            <input class="si" name="name" v-model="deliveryDataSet.name" />
          </td>
        </tr>
        <tr height="22">
          <td class="regacy-td">Tel.&nbsp;</td>
          <td class="regacy-td">
            <input class="si" name="name" v-model="deliveryDataSet.tel" />
            FAX.&nbsp;
            <input class="si" name="name" v-model="deliveryDataSet.fax" />
          </td>
        </tr>
        <tr height="22">
          <td>〒&nbsp;</td>
          <td class="regacy-td">
            <input class="si" name="name" v-model="deliveryDataSet.zip" />
          </td>
        </tr>
        <tr height="22">
          <td class="regacy-td">住所１&nbsp;</td>
          <td>
            <input class="si" name="name" v-model="deliveryDataSet.adress1" />
          </td>
        </tr>
        <tr height="22">
          <td class="regacy-td">住所２&nbsp;</td>
          <td>
            <input class="si" name="name" v-model="deliveryDataSet.adress" />
          </td>
        </tr>
        <tr height="22">
          <td class="regacy-td">備考&nbsp;</td>
          <td class="regacy-td">
            <input class="si" name="name" v-model="deliveryDataSet.note" />
          </td>
        </tr>
        <tr height="22">
          <td class="regacy-td">気付&nbsp;</td>
          <td>
            <input class="si" name="name" v-model="deliveryDataSet.care" />
          </td>
        </tr>
        <tr height="22">
          <td class="regacy-td pt9g">集荷日&nbsp;</td>
          <td>
            <input
              class="si"
              name="name"
              v-model="deliveryDataSet.orderDate"
            /><i id="inputOrderDate2" class="fa fa-calendar"></i>
            集荷時間&nbsp;
            <input class="si" name="name" v-model="deliveryDataSet.orderTime" />
          </td>
        </tr>
        <tr height="22">
          <td class="regacy-td pt9g">納品方法&nbsp;</td>
          <td>
            <select name="DeliveryType" v-model="deliveryDataSet.deliveryType">
              <option value="">選択してください。</option>
              <option value="CY 直納">CY 直納</option>
              <option value="CFSﾄﾗｯｸ">CFSﾄﾗｯｸ</option>
              <option value="ﾊﾞﾝ出ｵﾝﾄﾗｯｸ">ﾊﾞﾝ出ｵﾝﾄﾗｯｸ</option>
              <option value="混載便">混載便</option>
              <option value="2T車">2T車</option>
              <option value="4T車">4T車</option>
              <option value="10T車">10T車</option>
              <option value="20T車">20T車</option>
              <option value="ﾁｬｰﾀｰ便">ﾁｬｰﾀｰ便</option>
              <option value="2Tｳｨﾝｸﾞ車">2Tｳｨﾝｸﾞ車</option>
              <option value="4Tｳｨﾝｸﾞ車">4Tｳｨﾝｸﾞ車</option>
              <option value="10Tｳｨﾝｸﾞ車">10Tｳｨﾝｸﾞ車</option>
              <option value="ﾄﾚｰﾗｰ">ﾄﾚｰﾗｰ</option>
              <option value="先方引取り">先方引取り</option>
            </select>
          </td>
        </tr>
      </table>
      <br />
      <br />
      <table
        width="100%"
        Border="0"
        Cellspacing="0"
        CellPadding="0"
        class="pt9"
      >
        <tr height="22" align="center">
          <td class="regacy-td" colspan="2">
            <input
              type="checkbox"
              id="customer-data-lock"
              v-model="linkDataSet.customerLock"
            />
            <label for="customer-data-lock"
              >顧客に対してデータをロックする</label
            >
            <br /><br />
          </td>
        </tr>
        <tr height="22" align="center">
          <td class="regacy-td">
            <button
              type="button"
              class="btn btn-info re-shadow"
              data_but="btn-xs"
              @click="mail()"
            >
              <i class="far fa-envelope"></i>
              お客様にメール連絡する。
            </button>
          </td>
          <td class="regacy-td">
            <button
              type="button"
              class="btn btn-primary re-shadow"
              data_but="btn-xs"
              @click="save()"
            >
              <i class="far fa-thumbs-up"></i>
              基幹システムで台帳発行する。
            </button>
          </td>
        </tr>
      </table>
      <br />
      <br />
    </div>
  </div>
  <datalist id="client-option"></datalist>
  <datalist id="shipper-option"></datalist>
  <transition-group name="bounce" tag="div" appear>
    <messageModal
      v-if="messageModalStatus.show === true"
      :messageModalStatus="messageModalStatus"
      @messagemodal-close="messageModalClose"
    />
  </transition-group>
  <messages :receptionNo="receptionNo" />
  <sendMail
    v-if="showSendMailModal === true"
    :receptionNo="receptionNo"
    @mailmodal-close="mailModalClose"
  />
</template>
<script>
import MessageModal from "../../components/MessageModal.vue";
import Messages from "../admin/Messages";
import SendMail from "../admin/SendMail";
import HttpClient from "../../modules/http";
import ReceptionData from "../../modules/receptionData";
import { saveAs } from "file-saver";
import Utility from "../../modules/utility";
import ConstType from "../../modules/constTypes";

export default {
  components: {
    messageModal: MessageModal,
    messages: Messages,
    sendMail: SendMail,
  },
  mixins: [],
  props: ["paramReceptionNo"],
  emits: [],
  mounted() {
    this.datepicker();
    this.checkAuth();
  },
  computed: {
    isRequestLoading: function () {
      if (!this.isEditLoading && !this.isRegacyLoading) {
        return false;
      } else {
        return true;
      }
    },
  },
  data: function () {
    const constType = new ConstType();
    return {
      readonly: false,
      editDataSet: null,
      linkDataSet: null,
      shipDataSet: null,
      itemDataSet: null,
      deliveryDataSet: null,
      attachFilesDataSet: null,
      isEditLoading: false,
      regacyDataSet: null,
      isRegacyLoading: false,
      checkAuthShow: true,
      messageShow: true,
      apiSendStatus: {
        count: 0,
        error: 0,
      },
      messageModalStatus: {
        show: false,
        message: [],
        messageType: "info",
        loading: false,
        button: false,
      },
      applyTypes: {
        syokuhin: false,
        syokuken: false,
        douken: false,
        olt: false,
        zanpach: false,
        jyo14: false,
        jyo17: false,
        kanwari: false,
      },
      attachFileTypes: {
        invoice: false,
        packing: false,
        bl: false,
        arraival: false,
        picturePattern: false,
        certificateOfOrigin: false,
        evaluationDocuments: false,
        evaluationStatement: false,
        contract: false,
        cuttingReport: false,
        confirmationReport: false,
        other: false,
      },
      company: {
        adress1: "",
        adress2: "",
        agentId: "",
        chugaiCustomerCode: "",
        clientCode: "",
        code: "",
        companyNo: "",
        countryCode: "",
        fax: "",
        fcCustomerCode: "",
        mail: "",
        name: "",
        name2: "",
        name3: "",
        tel: "",
        zip: "",
      },
      containerTypes: constType.makeInitContainerTypes(),
      dispCustomCode: constType.makeInitFcCustomeCode(),
      fcCustomCode: constType.makeInitFcCustomeCode(),
      chugaiCustomCode: constType.makeInitChugaiCustomeCode(),
      iataCodes: "",
      dispPol: "",
      dispPod: "",
      validateError: true,
      showSendMailModal: false,
      httpClient: new HttpClient(),
      customerList: null,
      customerListRaw: null,
      customerListFiltered: null,
      customerListFiltered2: null,
    };
  },
  created: function () {
    const receptionInitData = new ReceptionData();
    this.linkDataSet = receptionInitData.makeInitLinkDataSet();
    this.shipDataSet = receptionInitData.makeInitShipDataSet();
    this.itemDataSet = receptionInitData.makeInitItemDataSet();
    this.deliveryDataSet = receptionInitData.makeInitDeliveryDataSet();
    this.attachFilesDataSet = receptionInitData.makeInitAttachDataSet();
  },
  watch: {
    apiSendStatus: {
      handler: function () {
        if (this.apiSendStatus.count >= 3) {
          this.messageModalStatus.message = [];
          this.messageModalStatus.message.push(
            "基幹システムとのデータ連携を開始します。"
          );
          this.updateJobCodeInfo();
        } else if (this.apiSendStatus.error == 1) {
          this.messageModalStatus.messageType = "warn";
          this.messageModalStatus.message.push("登録できませんでした。");
          this.messageModalStatus.loading = false;
        }
      },
      deep: true,
    },
  },
  methods: {
    datepicker() {
      $("#inputETA").daterangepicker(
        {
          autoUpdateInput: false,
          singleDatePicker: true,
          autoApply: true,
          locale: {
            format: "YYYY-MM-DD",
            separator: " - ",
          },
        },
        (start) => {
          this.shipDataSet.eta = start.format("YYYY-MM-DD");
        }
      );
      $("#inputETD").daterangepicker(
        {
          autoUpdateInput: false,
          singleDatePicker: true,
          autoApply: true,
          locale: {
            format: "YYYY-MM-DD",
            separator: " - ",
          },
        },
        (start) => {
          this.shipDataSet.etd = start.format("YYYY-MM-DD");
        }
      );
      $("#inputOrderDate1").daterangepicker(
        {
          autoUpdateInput: false,
          singleDatePicker: true,
          autoApply: true,
          locale: {
            format: "YYYY-MM-DD",
            separator: " - ",
          },
        },
        (start) => {
          this.deliveryDataSet.preferredDate = start.format("YYYY-MM-DD");
        }
      );
      $("#inputOrderDate2").daterangepicker(
        {
          autoUpdateInput: false,
          singleDatePicker: true,
          autoApply: true,
          locale: {
            format: "YYYY-MM-DD",
            separator: " - ",
          },
        },
        (start) => {
          this.deliveryDataSet.orderDate = start.format("YYYY-MM-DD");
        }
      );
    },
    setShipperOptions() {
      var input = document.getElementById("inputShipperName");
      console.log(input);
      input.autocomplete = true;
      input.setAttribute("list", "shipper-option");
      var filtered = [];

      // datalistタグの作成
      this.shipperOption = document.getElementById("shipper-option");
      for (
        var idx = this.shipperOption.childNodes.length - 1;
        idx >= 0;
        idx--
      ) {
        this.shipperOption.removeChild(this.shipperOption.childNodes[idx]);
      }
      for (var i = 0; i < this.customerListFiltered.length; i++) {
        if (!filtered.includes(this.customerListFiltered[i].CTM_NAME)) {
          filtered.push(this.customerListFiltered[i].CTM_NAME);
          var option = document.createElement("option");
          option.value = this.customerListFiltered[i].CTM_NAME;
          this.shipperOption.appendChild(option);
        }
      }
      console.log(this.shipperOption);
      console.log(this.customerListFiltered);
    },
    getCustomerList() {
      if (
        this.linkDataSet.branchCode != "" &&
        this.shipDataSet.shipperName.length > 0
      ) {
        const branch = this.linkDataSet.branchCode.split("-");
        this.isLoading = true;
        const url =
          "/append/searchCustomer/?agentId=" +
          this.$cookie.getCookie("agentId") +
          "&accountName=" +
          this.$cookie.getCookie("accountName") +
          "&type=" +
          branch[0] +
          "&name=" +
          this.shipDataSet.shipperName;
        this.httpClient.get(
          url,
          this.$cookie.getCookie("token"),
          this.onSuccessGetCustomer,
          this.onErrorAuth
        );
      } else {
        this.messageModalStatus.message = [];
        this.messageModalStatus.messageType = "warn";
        this.messageModalStatus.message.push(
          "取り扱い支店の選択と実輸入者名の１文字以上の入力が必要です。"
        );
        this.messageModalStatus.loading = false;
        this.messageModalStatus.button = true;
        this.messageModalStatus.show = true;
        this.messageShow = true;
      }
    },
    onSuccessGetCustomer(response) {
      let resData = JSON.stringify(response.data);
      resData = JSON.parse(resData);
      if (resData.list != null) {
        this.customerList = resData.list;
        let resData2 = JSON.stringify(response.data.list);
        this.customerListRaw = JSON.parse(resData2);
        this.customerListFiltered = JSON.parse(resData2);
        this.setShipperOptions();
      } else {
        this.messageModalStatus.message = [];
        this.messageModalStatus.messageType = "warn";
        this.messageModalStatus.message.push(
          "該当の顧客名は見つかりませんでした。"
        );
        this.messageModalStatus.loading = false;
        this.messageModalStatus.button = true;
        this.messageModalStatus.show = true;
        this.messageShow = true;
      }
    },
    checkAuth() {
      this.isLoading = true;
      this.checkAuthShow = true;
      const url =
        "/auth/checka/?agentId=" +
        this.$cookie.getCookie("agentId") +
        "&accountName=" +
        this.$cookie.getCookie("accountName");
      this.httpClient.get(
        url,
        this.$cookie.getCookie("token"),
        this.onSuccessAuth,
        this.onErrorAuth
      );
    },
    onSuccessAuth() {
      this.checkAuthShow = false;
      if (this.$route.query != null && this.$route.query.recept != null) {
        this.isEditLoading = true;
        this.receptionNo = this.$route.query.recept;
        const url =
          "/admin/rep/?agentId=" +
          this.$cookie.getCookie("agentId") +
          "&accountName=" +
          this.$cookie.getCookie("accountName") +
          "&receptionNo=" +
          this.receptionNo;
        this.httpClient.get(
          url,
          this.$cookie.getCookie("token"),
          this.onSuccessDetail,
          this.onErrorAuth
        );
      } else if (this.$store.getters.receptionDetailData != null) {
        this.isEditLoading = true;
        this.receptionNo = this.$store.getters.receptionDetailData.receptionNo;
        const url =
          "/admin/rep/?agentId=" +
          this.$cookie.getCookie("agentId") +
          "&accountName=" +
          this.$cookie.getCookie("accountName") +
          "&receptionNo=" +
          this.receptionNo;
        this.httpClient.get(
          url,
          this.$cookie.getCookie("token"),
          this.onSuccessDetail,
          this.onErrorAuth
        );
      } else {
        this.messageModalStatus.button = true;
        this.messageModalStatus.messageType = "warn";
        this.messageModalStatus.message.push(
          "受付情報が取得できませんでした。"
        );
      }

      const url =
        "/append/iata/?agentId=" +
        this.$cookie.getCookie("agentId") +
        "&accountName=" +
        this.$cookie.getCookie("accountName");
      this.httpClient.get(
        url,
        this.$cookie.getCookie("token"),
        this.onSuccessIata,
        this.onErrorAuth
      );
    },
    onErrorAuth(error) {
      this.isLoading = false;
      if (
        error != null &&
        error.response != null &&
        "status" in error.response &&
        error.response.status == 401
      ) {
        this.messageModalStatus.message.push(
          "セッションの有効期限がきれました。再度ログインお願いします。"
        );
      } else {
        this.messageModalStatus.message.push(
          "エラーが発生しました。再度ログインお願いします。"
        );
      }
      this.messageModalStatus.show = true;
      this.messageModalStatus.messageType = "warn";
      this.messageModalStatus.button = true;
    },
    scrollTarget: function (id) {
      var target = document.getElementById(id);
      $("html, body").animate(
        {
          scrollTop: target.offsetTop - 80,
        },
        500
      );
    },
    messageModalClose() {
      this.messageModalStatus.show = false;
      if (!this.messageShow) {
        if (!this.checkAuthShow) {
          if (this.apiSendStatus.finish == 1) {
            this.$router.push({ name: "list" });
          }
        } else {
          this.checkAuthShow = false;
          this.$router.push({ name: "login" });
        }
      }

      this.messageShow = false;
    },
    mailModalClose() {
      this.showSendMailModal = false;
    },
    back() {
      this.readonly = false;
      this.scrollTarget("request-top");
      //this.$router.go(-1);
    },
    check() {
      this.readonly = true;
      this.scrollTarget("request-top");
    },
    regist() {
      this.readonly = true;
    },
    changeSendStatus(type, value) {
      this.apiSendStatus[type] = value;
    },
    validation() {
      this.messageModalStatus.message = [];
      if (this.linkDataSet.branchCode == "") {
        this.messageModalStatus.message.push(
          "支店コードが選択されていません。"
        );
      }
      if (this.linkDataSet.brokerCode == "") {
        this.messageModalStatus.message.push("通関業者が選択されていません。");
      }
      if (this.shipDataSet.jobCustomerCode == "") {
        this.messageModalStatus.message.push(
          "請求荷主ｺｰﾄﾞが入力されていません。"
        );
      }
      if (this.shipDataSet.BlCustomerCode == "") {
        this.messageModalStatus.message.push(
          "実輸入者ｺｰﾄﾞが入力されていません。"
        );
      }
      if (this.dispPol == "") {
        this.messageModalStatus.message.push(
          "POLNが正しく入力されていません。"
        );
      }
      if (this.dispPod == "") {
        this.messageModalStatus.message.push(
          "PODNが正しく入力されていません。"
        );
      }

      if (this.messageModalStatus.message.length > 0) {
        this.validateError = true;
      } else {
        this.validateError = false;
      }

      this.messageModalStatus.messageType = "warn";
      this.messageModalStatus.loading = false;
      this.messageModalStatus.button = true;
      this.messageModalStatus.show = true;
    },
    mail() {
      this.showSendMailModal = true;
    },
    save() {
      this.validation();
      if (!this.validateError) {
        this.apiSendStatus.count = 0;
        this.messageModalStatus.message = [];
        this.messageModalStatus.message.push("登録中です。");
        this.messageModalStatus.messageType = "info";
        this.messageModalStatus.loading = true;
        this.messageModalStatus.button = false;
        this.messageModalStatus.show = true;

        if (
          this.linkDataSet.ledgerNo == null ||
          this.linkDataSet.ledgerNo == ""
        ) {
          this.numberingJobcode(this.receptionNo);
        } else {
          let shipDataSetBuff = JSON.stringify(this.shipDataSet);
          shipDataSetBuff = JSON.parse(shipDataSetBuff);

          let itemDataSetBuff = JSON.stringify(this.itemDataSet);
          itemDataSetBuff = JSON.parse(itemDataSetBuff);
          itemDataSetBuff.applyTypes = this.buildApplyTypesParam();

          let deliveryDataSetBuff = JSON.stringify(this.deliveryDataSet);
          deliveryDataSetBuff = JSON.parse(deliveryDataSetBuff);

          this.edit(this.receptionNo, "ships", shipDataSetBuff, "/ship/");
          this.edit(this.receptionNo, "items", itemDataSetBuff, "/item/");
          this.edit(
            this.receptionNo,
            "deliveries",
            deliveryDataSetBuff,
            "/delivery/"
          );
        }
      }
    },
    numberingJobcode() {
      const params = {
        agentId: this.$cookie.getCookie("agentId"),
        accountName: this.$cookie.getCookie("accountName"),
        companyId: this.linkDataSet.companyId,
        userId: this.linkDataSet.userId,
        receptionNo: this.linkDataSet.receptionNo,
        branchCode: this.linkDataSet.branchCode,
        brokerCode: this.linkDataSet.brokerCode,
        jobCustomerCode: this.shipDataSet.jobCustomerCode,
        blCustomerCode: this.shipDataSet.blCustomerCode,
        pol: this.shipDataSet.pol,
        pod: this.shipDataSet.pod,
        jobType: "I",
      };

      this.httpClient.save(
        "/admin/number",
        this.$cookie.getCookie("token"),
        params,
        this.onSuccessNumberingJobcode,
        this.onErrorNumberingJobcode
      );
    },
    updateJobCodeInfo() {
      const params = {
        agentId: this.$cookie.getCookie("agentId"),
        accountName: this.$cookie.getCookie("accountName"),
        companyId: this.linkDataSet.companyId,
        userId: this.linkDataSet.userId,
        receptionNo: this.linkDataSet.receptionNo,
        lock: this.linkDataSet.customerLock,
      };

      this.httpClient.edit(
        "/admin/rep/",
        this.$cookie.getCookie("token"),
        params,
        this.onSuccessUpdateJobCodeInfo,
        this.onErrorUpdateJobCodeInfo
      );
    },
    onSuccessUpdateJobCodeInfo() {
      this.messageModalStatus.message = [];
      this.messageModalStatus.message.push(
        "基幹システムとのデータ連携が完了しました。"
      );
      this.messageModalStatus.messageType = "info";
      this.messageModalStatus.loading = false;
      this.messageModalStatus.button = true;
    },
    onErrorUpdateJobCodeInfo(error) {
      this.messageModalStatus.message = [];
      if (
        error != null &&
        error.response != null &&
        "status" in error.response &&
        error.response.status == 401
      ) {
        this.messageModalStatus.message.push(
          "セッションの有効期限がきれました。再度ログインお願いします。"
        );
      } else {
        this.messageModalStatus.message.push(
          "基幹システムとのデータ連携に失敗しました。"
        );
      }
      this.messageModalStatus.show = true;
      this.messageModalStatus.messageType = "warn";
      this.messageModalStatus.button = true;
    },
    onSuccessDetail(response) {
      const receptionInitData = new ReceptionData();

      this.isEditLoading = false;
      this.editDataSet = JSON.stringify(response.data.link);
      this.editDataSet = JSON.parse(this.editDataSet);
      this.linkDataSet = response.data.link[0];
      if (
        this.linkDataSet.branchCode == "" ||
        this.linkDataSet.branchCode == null
      ) {
        this.linkDataSet.branchCode = "CH-K";
        this.linkDataSet.brokerCode = "HONSHA";
      }
      if (response.data.link[0].ship.length == 0) {
        this.shipDataSet = receptionInitData.makeInitShipDataSet();
      } else {
        this.shipDataSet = response.data.link[0].ship[0];
      }
      if (response.data.link[0].item.length == 0) {
        this.itemDataSet = receptionInitData.makeInitItemDataSet();
      } else {
        this.itemDataSet = response.data.link[0].item[0];
        const applyTypesData = this.itemDataSet.applyTypes.split(",");
        this.itemDataSet.applyTypes = {
          syokuhin1: false,
          syokuken1: false,
          douken1: false,
          syokuhin2: false,
          syokuken2: false,
          douken2: false,
          olt: false,
          zanpach: false,
          jyo14: false,
          jyo17: false,
          kanwari: false,
        };
        for (let i in applyTypesData) {
          this.applyTypes[applyTypesData[i]] = true;
        }
      }
      if (response.data.link[0].delivery.length == 0) {
        this.deliveryDataSet = receptionInitData.makeInitDeliveryDataSet();
      } else {
        this.deliveryDataSet = response.data.link[0].delivery[0];
      }

      if (response.data.link[0].files.length == 0) {
        this.attachFilesDataSet = receptionInitData.makeInitAttachDataSet();
      } else {
        this.attachFilesDataSet = response.data.link[0].files;
        const attachedTypesData =
          this.attachFilesDataSet[0].attachedTypes.split(",");
        for (let i in attachedTypesData) {
          this.attachFileTypes[attachedTypesData[i]] = true;
        }
      }
    },
    download(fid) {
      const url =
        "/files/dl/?agentId=" +
        this.$cookie.getCookie("agentId") +
        "&accountName=" +
        this.$cookie.getCookie("accountName") +
        "&receptionNo=" +
        this.receptionNo +
        "&fid=" +
        fid;
      this.httpClient.dl(
        url,
        this.$cookie.getCookie("token"),
        this.onSuccessDownload,
        this.onErrorDownload
      );
    },
    onSuccessDownload(response) {
      const blob = new Blob([response.data], {
        type: response.data.type,
      });

      //レスポンスヘッダからファイル名を取得します
      const contentDisposition = response.headers["content-disposition"];
      const fileName = Utility.decodeFileName(contentDisposition);

      //ダウンロードします
      saveAs(blob, fileName);
    },
    onErrorDownload() {
      this.messageModalStatus.message = [];
      this.messageModalStatus.message.push(
        "ファイルダウンロードに失敗しました。"
      );
      this.messageModalStatus.messageType = "warn";
      this.messageModalStatus.button = true;
      this.messageModalStatus.show = true;
    },
    onSuccessNumberingJobcode(response) {
      this.linkDataSet.ledgerNo = response.data.jobCode;

      let shipDataSetBuff = JSON.stringify(this.shipDataSet);
      shipDataSetBuff = JSON.parse(shipDataSetBuff);

      let itemDataSetBuff = JSON.stringify(this.itemDataSet);
      itemDataSetBuff = JSON.parse(itemDataSetBuff);
      itemDataSetBuff.applyTypes = this.buildApplyTypesParam();

      let deliveryDataSetBuff = JSON.stringify(this.deliveryDataSet);
      deliveryDataSetBuff = JSON.parse(deliveryDataSetBuff);

      this.edit(this.receptionNo, "ships", shipDataSetBuff, "/ship/");
      this.edit(this.receptionNo, "items", itemDataSetBuff, "/item/");
      this.edit(
        this.receptionNo,
        "deliveries",
        deliveryDataSetBuff,
        "/delivery/"
      );
    },
    onErrorNumberingJobcode() {
      this.messageModalStatus.message = [];
      this.messageModalStatus.message.push("台帳番号発行に失敗しました。");
      this.messageModalStatus.messageType = "warn";
      this.messageModalStatus.loading = false;
      this.messageModalStatus.button = true;
    },
    edit(receptionNo, type, dataSet, uri) {
      const convertType = {
        ships: "ship",
        items: "item",
        deliveries: "delivery",
      };
      const params = {
        agentId: this.$cookie.getCookie("agentId"),
        accountName: this.$cookie.getCookie("accountName"),
        receptionNo: receptionNo,
      };
      params[type] = [dataSet];

      if (this.editDataSet[0][convertType[type]].length > 0) {
        this.httpClient.edit(
          uri,
          this.$cookie.getCookie("token"),
          params,
          this.onSuccessEdit,
          this.onErrorAuth
        );
      } else {
        this.httpClient.save(
          uri,
          this.$cookie.getCookie("token"),
          params,
          this.onSuccessEdit,
          this.onErrorAuth
        );
      }
    },
    onSuccessEdit() {
      this.apiSendStatus.count++;
    },
    onSuccessIata(response) {
      this.iataCodes = response.data.iataCode;
      this.filterIata("pol", this.shipDataSet.pol);
      this.filterIata("pod", this.shipDataSet.pod);
      this.$forceUpdate();
    },
    filterIata(type, value) {
      if (type == "pol") {
        this.shipDataSet.pol = value.toUpperCase();
        if (this.shipDataSet.pol != "") {
          this.dispPol = "";
          for (let polidx in this.iataCodes) {
            if (this.iataCodes[polidx].locode == this.shipDataSet.pol) {
              this.dispPol = this.iataCodes[polidx].localName1;
              break;
            }
          }
        }
      }
      if (type == "pod") {
        this.shipDataSet.pod = value.toUpperCase();
        if (this.shipDataSet.pod != "") {
          this.dispPod = "";
          for (let podidx in this.iataCodes) {
            if (this.iataCodes[podidx].locode == this.shipDataSet.pod) {
              this.dispPod = this.iataCodes[podidx].localName1;
              break;
            }
          }
        }
      }
    },
    onChangeBranchCode(value) {
      if (value != "") {
        const branch = value.split("-");
        if (branch[0] == "FC") {
          this.dispCustomCode = this.fcCustomCode;
          this.shipDataSet.jobCustomerCode =
            this.linkDataSet.company.fcCustomerCode;
        } else {
          this.dispCustomCode = this.chugaiCustomCode;
          this.shipDataSet.jobCustomerCode =
            this.linkDataSet.company.chugaiCustomerCode;
        }

        if (
          this.shipDataSet.shipperName == this.shipDataSet.shippingCompanies
        ) {
          this.shipDataSet.blCustomerCode = this.shipDataSet.jobCustomerCode;
        } else {
          this.getCustomerList();
        }
      }
    },
    inputShip(name, value) {
      if (name == "shippingCompanies") {
        this.shipDataSet.shippingCompanies = value;
      } else {
        this.shipDataSet.shipperName = value;
        if (this.customerListFiltered != null) {
          this.customerListFiltered = this.customerListFiltered.filter(
            (client) => {
              if (
                client.CTM_NAME != null &&
                client.CTM_NAME.indexOf(value.toUpperCase()) > -1
              ) {
                return true;
              } else {
                return false;
              }
            }
          );
          if (this.customerListFiltered.length == 1) {
            this.shipDataSet.blCustomerCode =
              this.customerListFiltered[0].CTM_CD;
          }
        }
      }
    },
    buildApplyTypesParam() {
      let param = "";
      const typeNames = [
        "syokuhin1",
        "syokuken1",
        "douken1",
        "syokuhin2",
        "syokuken2",
        "douken2",
        "olt",
        "zanpach",
        "jyo14",
        "jyo17",
        "kanwari",
      ];

      typeNames.forEach((typeName) => {
        if (this.itemDataSet.applyTypes[typeName]) {
          param = param + typeName + ",";
        }
      });

      if (param.length > 2) {
        return param.substring(0, param.length - 1);
      } else {
        return param;
      }
    },
  },
};
</script>
