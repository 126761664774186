<template>
  <Menu />
  <main role="main">
    <router-view />
  </main>
</template>

<script>
import Menu from "../components/Menu.vue";
import firebase from "firebase";

export default {
  props: {
    agentId: {
      type: String,
      default: "",
    },
    accountName: {
      type: String,
      default: "",
    },
  },
  components: {
    Menu,
  },
  mounted: function () {
    if (firebase.apps.length === 0) {
      var firebaseConfig = {
        apiKey: "AIzaSyCBWEjd36oPfH4TlHySF0KmE5iLPnyXuIE",
        authDomain: "reception-project-fcsl2020.firebaseapp.com",
        projectId: "reception-project-fcsl2020",
        storageBucket: "reception-project-fcsl2020.appspot.com",
        messagingSenderId: "417559478858",
        appId: "1:417559478858:web:4375b7a6ab2be41dcddcb3",
      };
      firebase.initializeApp(firebaseConfig);
      const messaging = firebase.messaging();
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          // 通知を許可した場合
          console.log("Notification permission granted.");
          messaging.getToken().then((currentToken) => {
            if (currentToken) {
              // トークン取得成功
              console.log("currentToken:");
              console.log(currentToken);
            } else {
              // トークン取得失敗
            }
          });
        } else {
          // 通知を拒否した場合
          console.log("Unable to get permission to notify.");
        }
      });
    }
  },
  methods: {
    checkSession() {
      if (
        this.$cookie.getCookie("accountName") &&
        this.$cookie.getCookie("token") &&
        this.$store.getters.userInfo != null
      ) {
        this.$router.push({ name: "info" });
      }else{
        this.$router.push({ name: "login" });
      }
    },
  },
};
</script>
