<template>
  <div id="mailmodal-overlay">
    <div id="mailmodal-content" class="border" @click="noClose()">
      <div class="row d-flex justify-content-center">お客様連絡用メール送信</div>
      <div v-if="send" class="container">
        <div class="row d-flex justify-content-around">
          <div v-if="send">
            {{ dispMessage }}
            <div class="form-group col-md-12">
              <button v-on:click="closeModal()" type="button" class="btn btn-primary">
                閉じる。
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="container">
        <div class="form-row justify-content-center">
          <div class="form-group col-md-12">
            <label for="inputSubject">件名</label>
            <input
              type="text"
              class="form-control"
              id="inputSubject"
              v-model="subject"
            />
          </div>
        </div>
        <div class="form-row justify-content-center">
          <div class="form-group col-md-12">
            <label for="inputMessage">本文</label>
            <textarea rows="5" class="form-control" id="inputMessage" v-model="message" />
          </div>
        </div>
        <div class="form-row form-inline justify-content-center">
          <div class="form-group">
            <input type="checkbox" id="check" name="check" v-model="check" />
            <label class="form-check-label" for="check">
              送信内容チェックOK
            </label>
          </div>
        </div>
        <div class="form-row form-inline justify-content-around">
          <div class="form-group">
            <button
              v-on:click="postMessagesData()"
              :disabled="!check"
              type="button"
              class="btn btn-primary"
            >
              送信
            </button>
          </div>
          <div class="form-group">
            <button
              v-on:click="closeModal()"
              type="button"
              class="btn btn-secondary"
            >
              閉じる
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HttpClient from "../../modules/http";

export default {
  name: "Messages",
  props: ["receptionNo"],
  emits: ["mailmodal-close"],
  data: function () {
    return {
      subject: "",
      message: "",
      check: false,
      send: false,
      httpClient: new HttpClient(),
    };
  },
  created:function () {
    this.subject = "受付番号：" + this.receptionNo
  },
  methods: {
    postMessagesData() {
      if(this.subject == "" || this.message == ""){
        alert("件名もしくは、本文が入力されていません。")
      }else{
        const params = {
          agentId: this.$cookie.getCookie("agentId"),
          accountName: this.$cookie.getCookie("accountName"),
          receptionNo: this.receptionNo,
          subject: this.subject,
          message: this.message,
        };
        this.httpClient.save(
          "/mail/",
          this.$cookie.getCookie("token"),
          params,
          this.onSuccessPost,
          this.onErrorPost
        );
      }
    },
    onSuccessPost() {
      this.send = true;
      this.dispMessage = "送信しました。";
    },
    onErrorPost() {
      this.send = true;
      this.dispMessage = "送信に失敗しました。";
    },
    closeModal() {
      this.$emit("mailmodal-close");
    },
    noClose() {
      event.stopPropagation();
    },
  },
};
</script>
<style>
#mailmodal-content {
  z-index: 10003;
  width: 30%;
  height: 40%;
  padding: 1em;
  background: #fff;
  border-radius: 10%;
  border: 3px;
  border-color: black;
}

#mailmodal-overlay {
  z-index: 10002;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);

  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
