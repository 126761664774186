<template>
  <br />
  <br />
  <br />
  <div id="loading" class="overlay" v-if="isLoading">
    <div class="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <div class="d-block d-md-none container">
    <div class="row">
      <div class="col-md-9">
        <div
          class="
            d-flex
            justify-content-between
            flex-wrap flex-md-nowrap
            align-items-center
            pt-3
            pb-2
            mb-3
          "
        >
          <h6 class="h6">
            List
            <img
              class="re-icon-btn-sm justify-content-start"
              @click="reloadList()"
              alt="更新"
              src="../assets/reload.png"
            />
          </h6>
        </div>
      </div>
    </div>
    <div class="row">
      <table
        class="
          table table-striped table-hover table-bordered
          m
          table-sm table-responsive
        "
        id="mobiletable"
      >
        <thead>
          <tr>
            <th
              class="align-middle re-list-head text-center mobilehandler"
              rowspan="2"
              scope="col"
              @click="sortList(null, 'pid', Number)"
            >
              No.
            </th>
            <th
              class="align-middle re-list-head text-center mobilehandler"
              @click="sortList(null, 'jobNo', String)"
              rowspan="2"
              scope="col"
            >
              JOB No.
            </th>
            <th
              class="align-middle re-list-head text-center mobilehandler"
              @click="sortList(null, 'housBl', String)"
              scope="col"
            >
              House B/L
            </th>
            <th
              class="align-middle re-list-head text-center mobilehandler"
              rowspan="2"
              @click="sortList(null, 'consignee', String)"
              scope="col"
            >
              Consignee
            </th>
            <th
              class="align-middle re-list-head text-center handler"
              @click="sortList(null, 'pol', String)"
              scope="col"
            >
              POL
            </th>
          </tr>
          <tr>
            <th
              class="align-middle re-list-head text-center mobilehandler"
              @click="sortList(null, 'etd', String)"
              scope="col"
            >
              ETD
            </th>
            <th
              class="align-middle re-list-head text-center mobilehandler"
              @click="sortList(null, 'pod', String)"
              scope="col"
            >
              POD
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="re-pointer"
            v-for="(link, lidx) in getDispList"
            :key="lidx"
            @click="toDetail(link, lidx)"
          >
            <td class="align-middle text-center" scope="row">
              {{ link.showPid }}
            </td>
            <td class="align-middle text-center" scope="row">
              {{ link.jobNo }}
            </td>
            <td class="align-middle text-center" scope="row">
              <small>{{ link.housBl }}</small> <br />
              <small>{{ link.etd }}</small>
            </td>
            <td class="align-middle text-center" scope="row">
              {{ link.consignee }}
            </td>
            <td class="align-middle text-center" scope="row">
              <small>{{ link.pol }} </small><br />
              <small>{{ link.pod }}</small>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="d-none d-md-block container col-md-9 col-xl-9">
    <div class="row">
      <div class="col-md-9 col-xl-9">
        <div
          class="
            d-flex
            justify-content-between
            flex-wrap flex-md-nowrap
            align-items-center
            pt-3
            pb-2
            mb-1
          "
        >
          <h6 class="h6">
            Narrowing down conditions
            <img
              v-show="!dispFilters"
              class="re-icon-btn-sm justify-content-start"
              @click="dispFilters = true"
              alt="表示"
              src="../assets/plus.png"
            />
            <img
              v-show="dispFilters"
              class="re-icon-btn-sm justify-content-start"
              @click="dispFilters = false"
              alt="非表示"
              src="../assets/minus.png"
            />
          </h6>
        </div>
      </div>
    </div>
    <div class="row" v-show="dispFilters">
      <form>
        <div class="form-row form-inline col-md-12">
          <div class="form-group col-md-6">
            <label for="inputPid" class="col-md-4">No.</label>
            <input
              type="text"
              @keydown.enter="focusNext"
              class="form-control col-md-8"
              id="inputPid"
              v-bind:readonly="readonly"
              :value="filterData.showPid"
              @input="filterItem('showPid', $event.target.value)"
            />
          </div>
          <div class="form-group col-md-6">
            <label for="inputJobNo" class="col-md-4">JOB&nbsp;No.</label>
            <input
              type="text"
              @keydown.enter="focusNext"
              class="form-control col-md-8"
              id="inputJobNo"
              v-bind:readonly="readonly"
              :value="filterData.jobNo"
              @input="filterItem('jobNo', $event.target.value)"
            />
          </div>
        </div>
        <div class="form-row form-inline col-md-12">
          <div class="form-group col-md-6">
            <div class="input-group">
              <label for="inputETD" class="col-md-4">ETD</label>
              <input
                type="text"
                @keydown.enter="focusNext"
                class="form-control col-md-8"
                id="inputETD"
                placeholder="YYYY-MM-DD"
                v-bind:readonly="readonly"
                :value="filterData.etd"
                @input="filterItem('etd', $event.target.value)"
              />
              <div
                v-show="readonly == false"
                id="etd2"
                class="input-group-append re-pointer"
              >
                <div class="input-group-text">
                  <i class="fa fa-calendar"></i>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group col-md-6">
            <label for="inputConsignee" class="col-md-4">Consignee</label>
            <input
              type="text"
              @keydown.enter="focusNext"
              class="form-control col-md-8"
              id="inputConsignee"
              v-bind:readonly="readonly"
              :value="filterData.consignee"
              @input="filterItem('consignee', $event.target.value)"
            />
          </div>
        </div>
      </form>
    </div>
    <hr />
    <div class="row">
      <div class="col-md-12">
        <div
          class="
            d-flex
            justify-content-between
            flex-wrap flex-md-nowrap
            align-items-center
            pt-3
            pb-2
            mb-3
          "
        >
          <h5 class="h5">
            List
            <img
              class="re-icon-btn-sm justify-content-start"
              @click="reloadList()"
              alt="更新"
              src="../assets/reload.png"
            />
          </h5>
          <form class="form-inline">
            <div class="form-group">
              <label for="select-mode">mode：</label>
              <select
                id="select-mode"
                class="form-control"
                @change="modeChange($event.target.value)"
              >
                <option value="0">Browsing</option>
                <option value="1">Check</option>
              </select>
            </div>
            &nbsp;&nbsp;&nbsp;
            <div class="form-group">
              <label for="select-disprow">Displayed results：</label>
              <select
                id="select-disprow"
                class="form-control"
                @change="dispRowChange($event.target.value)"
              >
                <option>10</option>
                <option>20</option>
                <option>50</option>
                <option>100</option>
              </select>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="row">
      <table
        class="
          table
          table-striped
          table-hover
          table-bordered
          table-responsive-md
          table-sm
          row-scrollable
          re-table-height
        "
        id="table"
      >
        <thead>
          <tr>
            <th
              class="align-middle re-list-head text-center mobilehandler"
              scope="col"
              @click="checkAll()"
              v-if="listMode == 1"
              width="50"
            >
              CHK
            </th>
            <th
              class="align-middle re-list-head text-center handler"
              scope="col"
              @click="sortList(null, 'showPid', Number)"
              width="75"
            >
              No.
            </th>
            <th
              class="align-middle re-list-head text-center handler"
              scope="col"
              @click="sortList(null, 'jobNo', String)"
              width="75"
            >
              JOB No.
            </th>
            <th
              class="align-middle re-list-head text-center handler"
              scope="col"
              @click="sortList(null, 'housBl', String)"
              width="75"
            >
              House B/L
            </th>
            <th
              class="align-middle re-list-head text-center handler"
              scope="col"
              @click="sortList(null, 'consignee', Number)"
              width="120"
            >
              Consignee
            </th>
            <th
              class="align-middle re-list-head text-center handler"
              scope="col"
              @click="sortList(null, 'pol', String)"
              width="50"
            >
              POL
            </th>
            <th
              class="align-middle re-list-head text-center handler"
              scope="col"
              @click="sortList(null, 'etd', String)"
              width="75"
            >
              ETD
            </th>
            <th
              class="align-middle re-list-head text-center handler"
              scope="col"
              @click="sortList(null, 'pod', String)"
              width="75"
            >
              POD
            </th>
            <th
              class="align-middle re-list-head text-center handler"
              scope="col"
              @click="sortList(null, 'pattern', String)"
              width="50"
            >
              TYP
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="re-pointer"
            v-for="(link, lidx) in getDispList"
            :key="lidx"
            :class="{ cancel: link.factory == 'CANCEL' }"
          >
            <td
              class="align-middle text-center"
              scope="row"
              v-if="listMode == 1"
              @click="toDetail(link, lidx)"
            >
              <div class="form-check">
                <input
                  class="form-check-input position-static"
                  type="checkbox"
                  v-if="listMode == 1"
                  :checked="checkItems.indexOf(link.pid) != -1"
                  @change="checkConfirm(link, $event.target.checked)"
                />
              </div>
            </td>
            <td
              class="align-middle text-center"
              scope="row"
              @click="toDetail(link, lidx)"
            >
              {{ link.showPid }}
            </td>
            <td
              class="align-middle text-center"
              scope="row"
              @click="toDetail(link, lidx)"
            >
              {{ link.jobNo }}
            </td>
            <td
              class="align-middle text-center"
              scope="row"
              @click="toDetail(link, lidx)"
            >
              {{ link.housBl }}
            </td>
            <td
              class="align-middle text-center"
              scope="row"
              @click="toDetail(link, lidx)"
            >
              {{ link.consignee }}
            </td>
            <td
              class="align-middle text-center"
              scope="row"
              @click="toDetail(link, lidx)"
            >
              {{ link.pol }}
            </td>
            <td
              class="align-middle text-center"
              scope="row"
              @click="toDetail(link, lidx)"
            >
              {{ link.etd }}
            </td>
            <td
              class="align-middle text-center"
              scope="row"
              @click="toDetail(link, lidx)"
            >
              {{ link.pod }}
            </td>
            <td
              class="align-middle text-center"
              scope="row"
              @click="toDetail(link, lidx)"
            >
              {{ link.pattern }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <button
        type="button"
        class="btn btn-info btn-lg re-delete-button re-shadow"
        @click="checkPreAlert()"
        v-if="listMode == 1"
      >
        To check
      </button>
    </div>
  </div>
  <div class="row re-table-pager col-md-12 justify-content-around">
    <div v-if="pageData.page > 1">
      <img
        class="re-icon-btn-md justify-content-start"
        @click="pageChange(-1)"
        alt="←"
        src="../assets/left.png"
      />
    </div>
    <div v-else>
      <a> </a>
    </div>
    {{ pageData.page }}/{{ pageData.totalPage }}
    <div v-if="pageData.page != pageData.totalPage">
      <img
        class="re-icon-btn-md justify-content-start"
        @click="pageChange(1)"
        alt="→"
        src="../assets/right.png"
      />
    </div>
    <div v-else>
      <a> </a>
    </div>
  </div>
  <transition-group name="bounce" tag="div" appear>
    <messageModal
      v-if="messageModalStatus.show === true"
      :messageModalStatus="messageModalStatus"
      @messagemodal-close="messageModalClose"
    />
  </transition-group>
</template>
<script>
import tableDragger from "table-dragger";
import HttpClient from "../modules/http";
import MessageModal from "./MessageModal.vue";

export default {
  components: { MessageModal },
  data: function () {
    return {
      isLoading: true,
      readonly: false,
      dispFilters: true,
      listDataRaw: null,
      listData: null,
      pageData: {
        page: 1,
        totalPage: null,
        dispRow: 10,
      },
      filterData: {
        isFilter: true,
        showPid: "",
        jobNo: "",
        consignee: "",
        etd: "",
      },
      orderData: {
        isOrder: false,
        orderColumn: "",
        orderType: "",
        isAsc: false,
        orderFunction: String,
      },
      httpClient: new HttpClient(),
      columnOrder: {
        0: 0,
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
        6: 6,
        7: 7,
        8: 8,
        9: 9,
        10: 10,
        11: 11,
        12: 12,
        13: 13,
        14: 14,
        15: 15,
        16: 16,
      },
      messageModalStatus: {
        show: false,
        message: [],
        messageType: "info",
        loading: false,
        button: false,
      },
      listMode: 0,
      checkAllFlag: false,
      checkItems: [],
      routePushName: "",
      selectedMode: "",
    };
  },
  mounted: function () {
    var el = document.getElementById("table");
    var dragger = tableDragger(el, {
      mode: "column",
      dragHandler: ".handler",
      animation: 300,
    });
    dragger.on("drop", function (from, to) {
      console.log(from);
      console.log(to);
    });
    var elMobile = document.getElementById("mobiletable");
    var draggerMobile = tableDragger(elMobile, {
      mode: "column",
      dragHandler: ".mobilehandler",
      animation: 300,
    });
    draggerMobile.on("drop", function (from, to) {
      console.log(from);
      console.log(to);
    });

    $("#etd2").daterangepicker(
      {
        autoUpdateInput: false,
        singleDatePicker: true,
        autoApply: true,
        locale: {
          format: "YYYY-MM-DD",
          separator: " - ",
        },
      },
      (start) => {
        this.filterItem("etd", start.format("YYYY-MM-DD"));
      }
    );

    this.checkAuth();
  },
  computed: {
    getDispList: function () {
      if (this.listData != null && this.listData.length > 0) {
        return this.listData.slice(
          (this.pageData.page - 1) * this.pageData.dispRow,
          this.pageData.page * this.pageData.dispRow
        );
      } else {
        return null;
      }
    },
  },
  methods: {
    modeChange(selectedVal) {
      this.listMode = selectedVal;
      console.log(selectedVal);
      this.selectedMode = selectedVal;
      // modeが切り替わったタイミングで再検索
      // ->mode:Checkの場合、すでにCheck済みの値は表示しない。
      this.getListWithMode();
    },
    checkConfirm(link, value) {
      if (value) {
        this.checkItems.push(link.pid);
      } else {
        const index = this.checkItems.indexOf(link.pid);
        this.checkItems.splice(index, 1);
      }
    },
    checkAll() {
      if (!this.checkAllFlag) {
        const list = this.listData.slice(
          (this.pageData.page - 1) * this.pageData.dispRow,
          this.pageData.page * this.pageData.dispRow
        );

        for (let buff of list) {
          this.checkItems.push(buff.pid);
        }

        this.checkAllFlag = true;
      } else {
        this.checkItems = [];
        this.checkAllFlag = false;
      }
    },
    checkPreAlert() {
      if (this.checkItems.length > 0) {
        this.messageModalStatus.message = [];
        this.messageModalStatus.message.push("Processing");
        this.messageModalStatus.messageType = "info";
        this.messageModalStatus.loading = true;
        this.messageModalStatus.button = false;
        this.messageModalStatus.show = true;

        const params = {
          accountName: this.$cookie.getCookie("accountName"),
          preAlertNos: this.checkItems,
        };
        this.httpClient.edit(
          "/check/",
          this.$cookie.getCookie("token"),
          params,
          this.onSuccessCheck,
          this.onErrorCheck
        );
      }
    },
    onSuccessCheck() {
      this.messageModalStatus.message = [];
      this.messageModalStatus.message.push("Completed");
      this.messageModalStatus.messageType = "info";
      this.messageModalStatus.loading = false;
      this.messageModalStatus.button = true;
      this.routePushName = "";
      this.getListWithMode();
    },
    onErrorCheck() {
      this.messageModalStatus.message = [];
      this.messageModalStatus.message.push("Processing failure");
      this.messageModalStatus.messageType = "warn";
      this.messageModalStatus.loading = false;
      this.messageModalStatus.button = true;
      this.routePushName = "";
    },
    checkAuth() {
      this.isLoading = true;
      const url =
        "/auth/check/?accountName=" + this.$cookie.getCookie("accountName");
      this.httpClient.get(
        url,
        this.$cookie.getCookie("token"),
        this.onSuccessAuth,
        this.onErrorAuth
      );
    },
    onSuccessAuth() {
      this.getList();
      this.isLoading = false;
    },
    onErrorAuth(error) {
      if (
        error != null &&
        error.response != null &&
        "status" in error.response &&
        error.response.status == 401
      ) {
        this.messageModalStatus.message.push(
          "The session has expired. Please log in again."
        );
      } else {
        this.messageModalStatus.message.push(
          "An error has occurred. Please log in again."
        );
      }
      this.messageModalStatus.show = true;
      this.messageModalStatus.messageType = "warn";
      this.messageModalStatus.button = true;
      this.routePushName = "login";
    },
    messageModalClose() {
      this.messageModalStatus.show = false;
      if (this.routePushName) {
        this.$router.push({ name: this.routePushName });
      }
    },
    toDetail(link, index) {
      if (this.listMode == 1) {
        const index = this.checkItems.indexOf(link.pid);
        if (index != -1) {
          this.checkItems.splice(index, 1);
        } else {
          this.checkItems.push(link.pid);
        }
      } else {
        let forwardName = "request";
        if (link.managementStatus == 3) {
          forwardName = "check";
        }
        console.log(index * this.pageData.page);
        console.log(forwardName);
        console.log(link);

        this.$store.dispatch("doUpdatePreAlertDetailData", link);
        console.log(this.$store);

        this.$router.push({
          name: forwardName,
          params: { paramPid: link.pid, type: "edit" },
        });
      }
    },
    pageChange(page) {
      this.pageData.page = this.pageData.page + page;
    },
    dispRowChange(value) {
      this.pageData.dispRow = value;
      this.pageSettings();
    },
    getList() {
      this.isLoading = true;
      const url = "/list/?accountName=" + this.$cookie.getCookie("accountName");
      this.httpClient.get(
        url,
        this.$cookie.getCookie("token"),
        this.onSuccessGetList,
        this.onErrorAuth
      );
    },
    // mode:Checkで検索時に、チェックされていない値のみを取得
    getListWithMode() {
      this.isLoading = true;
      const url =
        "/uncheckedList/?accountName=" +
        this.$cookie.getCookie("accountName") +
        "&selectedMode=" +
        this.selectedMode;
      this.httpClient.get(
        url,
        this.$cookie.getCookie("token"),
        this.onSuccessGetList,
        this.onErrorAuth
      );
    },
    onSuccessGetList(response) {
      console.log(response.data);
      this.listData = response.data.prelist;
      this.listDataRaw = JSON.stringify(this.listData);
      this.listDataRaw = JSON.parse(this.listDataRaw);
      this.setInitCheckBox();
      this.filterList();
      this.initOrder();
      this.pageSettings();
      this.isLoading = false;
      this.$forceUpdate();
    },
    initOrder() {
      let listDataBuff = JSON.stringify(this.listData);
      listDataBuff = JSON.parse(listDataBuff);
      console.log(listDataBuff);

      let upperList = [];
      for (let buff in listDataBuff) {
        if (listDataBuff[buff].managementStatus > 0) {
          console.log(listDataBuff[buff]);
          upperList.push(listDataBuff[buff]);
        }
      }
      console.log(upperList);

      let normalList = [];
      for (let buff in listDataBuff) {
        let check = false;
        for (let buff2 in upperList) {
          if (listDataBuff[buff].pid == upperList[buff2].pid) {
            check = true;
          }
        }
        if (!check) {
          normalList.push(listDataBuff[buff]);
        }
      }
      const buffList = upperList.concat(normalList);
      console.log(upperList);
      console.log(normalList);
      console.log(buffList);
      this.listData = buffList;
    },
    reloadList() {
      this.pageData.page = 1;
      this.selectedMode = document.getElementById("select-mode").value;
      console.log(this.selectedMode);
      this.getListWithMode();
    },
    filterItem(name, value) {
      this.filterData.isFilter = true;
      this.filterData[name] = value;
      this.filterList();
      this.initOrder();
      this.pageSettings();
    },
    filterList() {
      let listDataBuff = JSON.stringify(this.listDataRaw);
      listDataBuff = JSON.parse(listDataBuff);

      if (this.filterData["showPid"] != "") {
        console.log(this.filterData["showPid"]);
        listDataBuff = listDataBuff.filter((values) => {
          if (this.filterData["showPid"].length > 0) {
            if (values.showPid == this.filterData["showPid"]) {
              return values;
            }
          }
        });
      }

      if (this.filterData["jobNo"] != "") {
        console.log(this.filterData["jobNo"]);
        listDataBuff = listDataBuff.filter((values) => {
          if (this.filterData["jobNo"].length > 0) {
            if (values.jobNo.indexOf(this.filterData["jobNo"]) != -1) {
              return values;
            }
          }
        });
      }

      if (this.filterData["etd"] != "") {
        console.log(this.filterData["etd"]);
        listDataBuff = listDataBuff.filter((values) => {
          if (this.filterData["etd"].length > 0) {
            if (values.etd.indexOf(this.filterData["etd"]) != -1) {
              return values;
            }
          }
        });
      }

      if (this.filterData["consignee"] != "") {
        console.log(this.filterData["consignee"]);
        listDataBuff = listDataBuff.filter((values) => {
          if (this.filterData["consignee"].length > 0) {
            if (values.consignee.indexOf(this.filterData["consignee"]) != -1) {
              return values;
            }
          }
        });
      }

      this.listData = listDataBuff;
    },
    pageSettings() {
      this.pageData.totalPage = Math.ceil(
        this.listData.length / this.pageData.dispRow
      );
      if (this.pageData.totalPage == 0) {
        this.pageData.page = 1;
        this.pageData.totalPage = 1;
      } else {
        if (this.pageData.page > this.pageData.totalPage) {
          this.pageData.page = this.pageData.totalPage;
        }
      }
    },
    sortList(type, name, func, asc) {
      console.log(type);
      this.checkItems = [];
      if (asc == undefined || asc == null) {
        this.orderData.isAsc = !this.orderData.isAsc;
      } else {
        this.orderData.isAsc = asc;
      }

      this.orderData.orderColumn = name;
      this.orderData.orderType = type;
      this.orderData.isOrder = true;
      this.orderData.orderFunction = func;

      this.filterList();

      this.listData = this.listData.sort((a, b) => {
        if (type == null) {
          a = a[name];
          b = b[name];
        } else {
          if (
            a[type].length > 0 &&
            a[type][0][name] !== "" &&
            a[type][0][name] !== null
          ) {
            a = a[type][0][name];
          } else {
            if (func == Number) {
              a = -1;
            } else {
              a = "-";
            }
          }
          if (
            b[type].length > 0 &&
            b[type][0][name] !== "" &&
            b[type][0][name] !== null
          ) {
            b = b[type][0][name];
          } else {
            if (func == Number) {
              b = -1;
            } else {
              b = "-";
            }
          }
        }
        return (
          (func(a) === func(b) ? 0 : func(a) > func(b) ? 1 : -1) *
          (this.orderData.isAsc ? 1 : -1)
        );
      });
    },
    // EnterキーでTab移動できる関数
    focusNext(e) {
      const inputs = Array.from(
        e.target.form.querySelectorAll('input[type="text"]')
      );
      const index = inputs.indexOf(e.target);

      if (index < inputs.length) {
        inputs[index + 1].focus();
      }
    },
    // チェックボックスの初期値を取得
    setInitCheckBox() {
      this.checkItems = [];
      for (let buff of this.listData) {
        if (buff.checkFlg == true) {
          this.checkItems.push(buff.pid);
        } else {
          this.checkItems.splice(buff.pid);
        }
      }
    },
  },
};
</script>
<style>
.row-scrollable {
  overflow-x: auto !important;
  white-space: nowrap !important;
}
.row-scrollable > .col-scrollable {
  display: inline-block !important;
  float: none !important;
}
.cancel {
  color: gray;
  text-decoration: line-through;
}
</style>

