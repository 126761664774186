<template>
      <Header />
      <router-view :key="$route.fullPath"></router-view>
</template>

<script>
import Header from "@/components/Header";

export default {
  name: "app",
  components: {
    Header,
  },
  data: function () {
    return {};
  },
};
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Lobster&family=Marck+Script&family=Niconne&family=Pacifico&family=Rock+Salt&family=Tangerine:wght@700&Alex+Brush&family=M+PLUS+1p&family=Noto+Serif+JP&family=Sawarabi+Gothic&family=Kosugi&family=Noto+Serif+JP:wght@900&display=swap');

#app {
  /* font-family: 'Sawarabi Gothic', sans-serif; */
  font-family: 'Kosugi', sans-serif;
}
</style>
