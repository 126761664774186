<template>
  <div class="prealert">
    <div class="container-fluid">
      <form>
        <br />
        <sup><font color="red">*</font></sup
        >Required Field
        <br />
        <br />
        <div class="form-row align-items-center">
          <div class="form-group col-md-12 align-items-center">
            <label
              v-if="index === 0"
              for="jobNo"
              style="float: left"
              class="col-md-2"
            >
              JOB&nbsp;No.<sup><font color="red">*</font></sup>
            </label>
            <div
              class="input-group col-md-4 align-items-center"
              style="float: left"
            >
              <div class="input-group align-items-center" style="float: center">
                <div class="input-group" style="float: center">
                  <input
                    type="text"
                    class="form-control"
                    id="jobNo"
                    v-bind:readonly="readonly || callType == 'show'"
                    @keydown.enter="focusNext"
                    :maxlength="20"
                    :value="preAlertData.jobNo"
                    @input="inputPreAlert('jobNo', $event.target.value)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="form-group col-md-12 align-items-center">
            <label
              v-if="index === 0"
              for="shipper"
              style="float: left"
              class="col-md-2"
            >
              SHIPPER<sup><font color="red">*</font></sup>
            </label>
            <div
              class="input-group col-md-6 align-items-center"
              style="float: left"
            >
              <div class="input-group align-items-center" style="float: center">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="shipper"
                    v-bind:readonly="readonly || callType == 'show'"
                    @keydown.enter="focusNext"
                    :maxlength="100"
                    :value="preAlertData.shipper"
                    @input="inputPreAlert('shipper', $event.target.value)"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="form-group col-md-12 align-items-center">
            <label
              v-if="index === 0"
              for="consignee"
              style="float: left"
              class="col-md-2"
            >
              CONSIGNEE<sup><font color="red">*</font></sup>
            </label>
            <div
              class="input-group col-md-6 align-items-center"
              style="float: left"
            >
              <div class="input-group" style="float: center">
                <div class="input-group">
                  <div class="input-group-append" v-if="preAlertData.pid == 0">
                    <span class="input-group-text" @click="showCustomerModal"
                      >Select</span
                    >
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    id="consignee"
                    readonly
                    @keydown.enter="focusNext"
                    :maxlength="100"
                    :value="preAlertData.consignee"
                    @input="inputPreAlert('consignee', $event.target.value)"
                  />
                </div>
              </div>
            </div>
            <label
              v-if="index === 0"
              for="customer"
              style="float: left"
              class="col-md-2"
            >
              REF&nbsp;Code<sup><font color="red">*</font></sup>
            </label>
            <div
              class="input-group col-md-2 align-items-center"
              style="float: right"
            >
              <div class="input-group" style="float: center">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="customer"
                    readonly
                    @keydown.enter="focusNext"
                    :maxlength="6"
                    :value="preAlertData.customer"
                    @input="inputPreAlert('customer', $event.target.value)"
                  />
                </div>
                <div class="invalid-feedback">Please input the number.</div>
              </div>
            </div>
          </div>
          <div class="form-group col-md-12 align-items-center">
            <label
              v-if="index === 0"
              for="notify"
              style="float: left"
              class="col-md-2"
            >
              NOTIFY&nbsp;PARTY<sup><font color="red">*</font></sup>
            </label>
            <div
              class="input-group col-md-6 align-items-center"
              style="float: left"
            >
              <div class="input-group" style="float: center">
                <div class="input-group">
                  <input
                    type="text"
                    @keydown.enter="focusNext"
                    class="form-control"
                    id="notify"
                    v-bind:readonly="readonly || callType == 'show'"
                    :maxlength="100"
                    :value="preAlertData.notify"
                    @input="inputPreAlert('notify', $event.target.value)"
                  />
                  <div
                    class="input-group-append"
                    v-if="preAlertData.notify == 0"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group col-md-12 align-items-center">
            <label
              v-if="index === 0"
              for="vessel"
              style="float: left"
              class="col-md-2"
            >
              VESSEL<sup><font color="red">*</font></sup>
            </label>
            <div
              class="input-group col-md-4 align-items-center"
              style="float: left"
            >
              <div class="input-group align-items-center" style="float: center">
                <!-- 海上速達便の場合のみ、Read-Onlyで表示 -->
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="vessel"
                    v-bind:readonly="readonly || callType == 'show'"
                    @keydown.enter="focusNext"
                    :maxlength="50"
                    :value="preAlertData.vessel"
                    @input="inputPreAlert('vessel', $event.target.value)"
                  />
                </div>
              </div>
            </div>
            <label
              v-if="index === 0"
              for="voyage"
              style="float: left"
              class="col-md-2"
            >
              VOY<sup><font color="red">*</font></sup>
            </label>
            <div
              class="input-group col-md-4 align-items-center"
              style="float: right"
            >
              <div class="input-group align-items-center" style="float: center">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="voyage"
                    v-bind:readonly="readonly || callType == 'show'"
                    :maxlength="20"
                    :value="preAlertData.voyage"
                    @input="inputPreAlert('voyage', $event.target.value)"
                    @keydown.enter="focusNext"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="form-group col-md-12 align-items-center">
            <label
              v-if="index === 0"
              for="carrier"
              style="float: left"
              class="col-md-2"
              >CARRIER</label
            >
            <div
              class="input-group col-md-4 align-items-center"
              style="float: left"
            >
              <div class="input-group align-items-center" style="float: center">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="carrier"
                    v-bind:readonly="readonly || callType == 'show'"
                    :maxlength="50"
                    :value="preAlertData.carrier"
                    @input="inputPreAlert('carrier', $event.target.value)"
                    @keydown.enter="focusNext"
                  />
                </div>
              </div>
            </div>
            <label
              v-if="index === 0"
              for="pattern"
              style="float: left"
              class="col-md-2"
              >Type</label
            >
            <div
              class="input-group col-md-4 align-items-center"
              style="float: right"
            >
              <div class="input-group align-items-center" style="float: center">
                <div class="input-group">
                  <select
                    class="form-control"
                    id="patternSelect"
                    :disabled="readonly || callType == 'show'"
                    :value="preAlertData.pattern"
                    @change="inputPreAlert('pattern', $event.target.value)"
                  >
                    <option>LCL</option>
                    <option>FCL</option>
                    <option>CONSO</option>
                    <option>AIR</option>
                    <option>EXPRESS</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group col-md-12 align-items-center">
            <label
              v-if="index === 0"
              for="carrier"
              style="float: left"
              class="col-md-2"
              >POL<sup><font color="red">*</font></sup>
            </label>
            <div
              class="input-group col-md-4 align-items-center"
              style="float: left"
            >
              <div class="input-group align-items-center" style="float: center">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="pol"
                    v-bind:readonly="readonly || callType == 'show'"
                    :maxlength="20"
                    :value="preAlertData.pol"
                    @input="inputPreAlert('pol', $event.target.value)"
                    @keydown.enter="focusNext"
                  />
                </div>
              </div>
            </div>
            <label
              v-if="index === 0"
              for="etd"
              style="float: left"
              class="col-md-2"
              >ETD<sup><font color="red">*</font></sup>
            </label>
            <div
              class="input-group col-md-4 align-items-center"
              style="float: right"
            >
              <div class="input-group align-items-center" style="float: center">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="etd"
                    v-bind:placeholder="getPlaceholder('yyyy-mm-dd')"
                    v-bind:readonly="readonly || callType == 'show'"
                    :value="preAlertData.etd"
                    @input="inputPreAlert('etd', $event.target.value)"
                    @keydown.enter="focusNext"
                  />
                  <div
                    v-show="readonly == false"
                    id="etd2"
                    class="input-group-append re-pointer"
                  >
                    <div class="input-group-text">
                      <i class="fa fa-calendar"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group col-md-12 align-items-center">
            <label
              v-if="index === 0"
              for="pod"
              style="float: left"
              class="col-md-2"
              >POD<sup><font color="red">*</font></sup>
            </label>
            <div
              class="input-group col-md-4 align-items-center"
              style="float: left"
            >
              <div class="input-group align-items-center" style="float: center">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="pod"
                    v-bind:readonly="readonly || callType == 'show'"
                    :maxlength="20"
                    :value="preAlertData.pod"
                    @input="inputPreAlert('pod', $event.target.value)"
                    @keydown.enter="focusNext"
                  />
                </div>
              </div>
            </div>
            <label
              v-if="index === 0"
              for="eta"
              style="float: left"
              class="col-md-2"
              >ETA<sup><font color="red">*</font></sup>
            </label>
            <div
              class="input-group col-md-4 align-items-center"
              style="float: right"
            >
              <div class="input-group align-items-center" style="float: center">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="eta"
                    v-bind:placeholder="getPlaceholder('yyyy-mm-dd')"
                    v-bind:readonly="readonly || callType == 'show'"
                    :value="preAlertData.eta"
                    @input="inputPreAlert('eta', $event.target.value)"
                    @keydown.enter="focusNext"
                  />
                  <div
                    v-show="readonly == false"
                    id="eta2"
                    class="input-group-append re-pointer"
                  >
                    <div class="input-group-text">
                      <i class="fa fa-calendar"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group col-md-12 align-items-center">
            <label
              v-if="index === 0"
              for="factory"
              style="float: left"
              class="col-md-2"
            >
              COMMODITY<sup><font color="red">*</font></sup>
            </label>
            <div
              class="input-group col-md-10 align-items-center"
              style="float: right"
            >
              <div class="input-group" style="float: center">
                <input
                  type="text"
                  class="form-control"
                  id="commodity"
                  v-bind:readonly="readonly || callType == 'show'"
                  :maxlength="100"
                  :value="preAlertData.commodity"
                  @input="inputPreAlert('commodity', $event.target.value)"
                  @keydown.enter="focusNext"
                />
              </div>
            </div>
          </div>
          <div class="form-group col-md-12 align-items-center">
            <label
              v-if="index === 0"
              for="quantity"
              style="float: left"
              class="col-md-2"
            >
              QUANTITY
            </label>
            <div
              class="input-group col-md-4 align-items-center"
              style="float: left"
            >
              <div class="input-group align-items-center" style="float: center">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="quantity"
                    onfocus="this.select();"
                    v-bind:readonly="readonly || callType == 'show'"
                    :maxlength="10"
                    :value="preAlertData.quantity"
                    @input="inputPreAlert('quantity', $event.target.value)"
                    @keydown.enter="focusNext"
                    @keydown="filterKey"
                  />
                </div>
              </div>
            </div>
            <label
              v-if="index === 0"
              for="packages"
              style="float: left"
              class="col-md-2"
            >
              PACKAGES<sup><font color="red">*</font></sup>
            </label>
            <div
              class="input-group col-md-4 align-items-center"
              style="float: left"
            >
              <div class="input-group align-items-center" style="float: center">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="packages"
                    v-bind:readonly="readonly || callType == 'show'"
                    :maxlength="20"
                    :value="preAlertData.packages"
                    @input="inputPreAlert('packages', $event.target.value)"
                    @keydown.enter="focusNext"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="form-group col-md-12 align-items-center">
            <label
              v-if="index === 0"
              for="grossWeight"
              style="float: left"
              class="col-md-2"
            >
              GROSS&nbsp;WEIGHT
            </label>
            <div
              class="input-group col-md-4 align-items-center"
              style="float: left"
            >
              <div class="input-group align-items-center" style="float: center">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="grossWeight"
                    onfocus="this.select();"
                    v-bind:readonly="readonly || callType == 'show'"
                    :maxlength="8"
                    :value="preAlertData.grossWeight || '0'"
                    @input="inputPreAlert('grossWeight', $event.target.value)"
                    @keydown.enter="focusNext"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">KGS</span>
                  </div>
                  <div class="invalid-feedback">Please enter a number</div>
                </div>
              </div>
            </div>
            <label
              v-if="index === 0"
              for="mesurement"
              style="float: left"
              class="col-md-2"
            >
              MEASUREMENT
            </label>
            <div
              class="input-group col-md-4 align-items-center"
              style="float: left"
            >
              <div class="input-group align-items-center" style="float: center">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="mesurement"
                    onfocus="this.select();"
                    v-bind:readonly="readonly || callType == 'show'"
                    :value="preAlertData.mesurement || '0'"
                    @input="inputPreAlert('mesurement', $event.target.value)"
                    @keydown.enter="focusNext"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">M3</span>
                  </div>
                </div>
                <div class="invalid-feedback">Please enter a number</div>
              </div>
            </div>
          </div>
          <div class="form-group col-md-12 align-items-center">
            <label
              v-if="index === 0"
              for="housBl"
              style="float: left"
              class="col-md-2"
            >
              HOUSE B/L<sup><font color="red">*</font></sup>
            </label>
            <div
              class="input-group col-md-4 align-items-center"
              style="float: left"
            >
              <div class="input-group align-items-center" style="float: center">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="housBl"
                    v-bind:readonly="readonly || callType == 'show'"
                    :value="preAlertData.housBl"
                    @input="inputPreAlert('housBl', $event.target.value)"
                    @keydown.enter="focusNext"
                  />
                </div>
              </div>
            </div>
            <label
              v-if="index === 0"
              for="poNo"
              style="float: left"
              class="col-md-2"
            >
              PO No.
            </label>
            <div
              class="input-group col-md-4 align-items-center"
              style="float: left"
            >
              <div class="input-group align-items-center" style="float: center">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="poNo"
                    v-bind:readonly="readonly || callType == 'show'"
                    :value="preAlertData.poNo"
                    @input="inputPreAlert('poNo', $event.target.value)"
                    @keydown.enter="focusNext"
                  />
                </div>
                <div class="invalid-feedback">Please enter a number</div>
              </div>
            </div>
          </div>
          <div class="form-group col-md-12 align-items-center">
            <label
              v-if="index === 0"
              for="bookingDate"
              style="float: left"
              class="col-md-2"
            >
              BOOKING&nbsp;DATE<sup><font color="red">*</font></sup>
            </label>
            <div
              class="input-group col-md-4 align-items-center"
              style="float: left"
            >
              <div class="input-group align-items-center" style="float: center">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="bookingDate"
                    v-bind:placeholder="getPlaceholder('yyyy-mm-dd')"
                    v-bind:readonly="readonly || callType == 'show'"
                    :value="preAlertData.bookingDate"
                    @input="inputPreAlert('bookingDate', $event.target.value)"
                    @keydown.enter="focusNext"
                  />
                  <div
                    v-show="readonly == false"
                    id="bookingDate2"
                    class="input-group-append re-pointer"
                  >
                    <div class="input-group-text">
                      <i class="fa fa-calendar"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <label
              v-if="index === 0"
              for="closeDate"
              style="float: left"
              class="col-md-2"
            >
              CLOSE DATE
            </label>
            <div
              class="input-group col-md-4 align-items-center"
              style="float: left"
            >
              <div class="input-group align-items-center" style="float: center">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="closeDate"
                    v-bind:placeholder="getPlaceholder('yyyy-mm-dd')"
                    v-bind:readonly="readonly || callType == 'show'"
                    :value="preAlertData.closeDate"
                    @input="inputPreAlert('closeDate', $event.target.value)"
                    @keydown.enter="focusNext"
                  />
                  <div
                    v-show="readonly == false"
                    id="closeDate2"
                    class="input-group-append re-pointer"
                  >
                    <div class="input-group-text">
                      <i class="fa fa-calendar"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group col-md-12 align-items-center">
            <label
              v-if="index === 0"
              for="container20"
              style="float: left"
              class="col-md-2"
            >
              CONTAINER
            </label>
            <div
              class="input-group col-md-3 align-items-center"
              style="float: left"
            >
              <div class="input-group align-items-center">
                <div class="input-group">
                  <div class="input-group-append">
                    <span class="input-group-text">20' </span>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    id="container20"
                    onfocus="this.select();"
                    v-bind:readonly="readonly || callType == 'show'"
                    :maxlength="50"
                    :value="preAlertData.container20 || '0'"
                    @input="inputPreAlert('container20', $event.target.value)"
                    @keydown.enter="focusNext"
                  />
                </div>
                <div class="invalid-feedback">Please enter a number</div>
              </div>
            </div>
            <div
              class="input-group col-md-3 align-items-center"
              style="float: left"
            >
              <div class="input-group align-items-center">
                <div class="input-group">
                  <div class="input-group-append">
                    <span class="input-group-text">40' </span>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    id="container40"
                    onfocus="this.select();"
                    v-bind:readonly="readonly || callType == 'show'"
                    :maxlength="50"
                    :value="preAlertData.container40 || '0'"
                    @input="inputPreAlert('container40', $event.target.value)"
                    @keydown.enter="focusNext"
                  />
                </div>
                <div class="invalid-feedback">Please enter a number</div>
              </div>
            </div>
            <div
              class="input-group col-md-3 align-items-center"
              style="float: left"
            >
              <div class="input-group align-items-center">
                <div class="input-group">
                  <div class="input-group-append">
                    <span class="input-group-text">HQ&nbsp;</span>
                  </div>
                  &nbsp;
                  <input
                    type="checkbox"
                    class="form-control col-md-1"
                    id="hq"
                    :disabled="readonly || callType == 'show'"
                    :checked="preAlertData.hq"
                    :maxlength="50"
                    @change="inputPreAlert('hq', $event.target.checked)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="form-group col-md-12 align-items-center">
            <label
              v-if="index === 0"
              for="tradeTerms"
              style="float: left"
              class="col-md-2"
            >
              TRADE&nbsp;TERMS
            </label>
            <div
              class="input-group col-md-4 align-items-center"
              style="float: left"
            >
              <div class="input-group align-items-center" style="float: center">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="tradeTerms"
                    list="teadeList"
                    v-bind:readonly="readonly || callType == 'show'"
                    :maxlength="15"
                    :value="preAlertData.tradeTerms"
                    @input="inputPreAlert('tradeTerms', $event.target.value)"
                    @keydown.enter="focusNext"
                  />
                  <datalist id="teadeList">
                    <option>FREIGHT PREPAID</option>
                    <option>FREIGHT COLLECT</option>
                  </datalist>
                </div>
              </div>
            </div>
            <label
              v-if="index === 0"
              for="specHandle"
              style="float: left"
              class="col-md-2"
            >
              SPEC.&nbsp;HANDLING
            </label>
            <div
              class="input-group col-md-4 align-items-center"
              style="float: right"
            >
              <div class="input-group align-items-center" style="float: center">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="specHandle"
                    v-bind:readonly="readonly || callType == 'show'"
                    :maxlength="10"
                    :value="preAlertData.specHandle"
                    @input="inputPreAlert('specHandle', $event.target.value)"
                    @keydown.enter="focusNext"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="form-group col-md-12 align-items-center">
            <label
              v-if="index === 0"
              for="marks"
              style="float: left"
              class="col-md-2"
            >
              SHIPPING&nbsp;MARK
            </label>
            <div
              class="input-group col-md-4 align-items-center"
              style="float: left"
            >
              <div class="input-group align-items-center" style="float: center">
                <div class="input-group">
                  <textarea
                    type="text"
                    class="form-control"
                    id="marks"
                    rows="12"
                    maxlength="250"
                    v-bind:readonly="readonly || callType == 'show'"
                    :value="preAlertData.marks"
                    @input="inputPreAlert('marks', $event.target.value)"
                    @keydown.enter="focusNext"
                  >
                  </textarea>
                </div>
              </div>
            </div>
            <label
              v-if="index === 0"
              for="containerNo"
              style="float: left"
              class="col-md-2"
            >
              CONTAINER&nbsp;NO.
            </label>
            <div
              class="input-group col-md-4 align-items-center"
              style="float: right"
            >
              <div class="input-group align-items-center" style="float: center">
                <div class="input-group">
                  <textarea
                    type="text"
                    class="form-control"
                    id="containerNo"
                    rows="12"
                    maxlength="500"
                    v-bind:readonly="readonly || callType == 'show'"
                    :value="preAlertData.containerNo"
                    @input="inputPreAlert('containerNo', $event.target.value)"
                    @keydown.enter="focusNext"
                  >
                  </textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group col-md-12 align-items-center">
            <label
              v-if="index === 0"
              for="factory"
              style="float: left"
              class="col-md-2"
            >
              FACTORY
            </label>
            <div
              class="input-group col-md-4 align-items-center"
              style="float: left"
            >
              <div class="input-group align-items-center" style="float: center">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="factory"
                    v-bind:readonly="readonly || callType == 'show'"
                    :maxlength="100"
                    :value="preAlertData.factory"
                    @input="inputPreAlert('factory', $event.target.value)"
                    @keydown.enter="focusNext"
                  />
                </div>
              </div>
            </div>
            <label
              v-if="index === 0"
              for="delayInfo"
              style="float: left"
              class="col-md-2"
            >
              DELAY&nbsp;INFO
            </label>
            <div
              class="input-group col-md-4 align-items-center"
              style="float: left"
            >
              <div class="input-group align-items-center" style="float: center">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="delayInfo"
                    list="delayList"
                    v-bind:readonly="readonly || callType == 'show'"
                    :maxlength="100"
                    :value="preAlertData.delayInfo"
                    @input="inputPreAlert('delayInfo', $event.target.value)"
                    @keydown.enter="focusNext"
                  />
                  <datalist id="delayList">
                    <option>CARGO DELAY</option>
                    <option>BAD WEATHER</option>
                    <option>PORT CONGRESTION</option>
                    <option>DELAY DUE TO CARRIER</option>
                  </datalist>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <br />
    </div>
  </div>
  <transition-group name="bounce" tag="div" appear>
    <customerModal
      v-if="customerModalShow === true"
      :customerData="customerData"
      @customermodal-close="closeCustomerModal"
      @update-customermodal="updateCustomerModal"
    />
  </transition-group>
  <datalist id="vessel-option"></datalist>
</template>
<script>
import CustomerModal from "./CustomerModal.vue";
import { checkDate } from "../../modules/validation";
import { checkNumber } from "../../modules/validation";
import HttpClient from "../../modules/http";
import Utility from "../../modules/utility";
import { saveAs } from "file-saver";

export default {
  name: "preAlert",
  components: {
    customerModal: CustomerModal,
  },
  props: ["index", "readonly", "preAlertData", "callType"],
  emits: ["deletePrealert", "updatePrealert", "pushMessagemodal"],
  data: function () {
    return {
      patternSelect: { code: "LCL", label: "LCL" },
      messageModalStatus: {
        show: false,
        message: [],
        messageType: "info",
        loading: false,
        button: false,
      },
      validationsPreAlert: {
        etd: checkDate,
        eta: checkDate,
        bookingDate: checkDate,
        closeDate: checkDate,
        quantity: checkNumber,
        grossWeight: checkNumber,
        mesurement: checkNumber,
        container20: checkNumber,
        container40: checkNumber,
        customer: checkNumber,
      },
      selectinput: {
        selected: "LCL",
        input: "",
        items: [
          { id: 1, val: "LCL" },
          { id: 2, val: "FCL" },
          { id: 3, val: "CONSO" },
          { id: 4, val: "AIR" },
          { id: 5, val: "EXPRESS" },
        ],
      },
      customerModalShow: false,
      newPreAlertData: this.preAlertData,
      httpClient: new HttpClient(),
      optionsFilterMap: { vessel: "vesselList" },
      vesselOption: null,
    };
  },
  mounted() {
    $("#etd2").daterangepicker(
      {
        autoUpdateInput: false,
        singleDatePicker: true,
        autoApply: true,
        locale: {
          format: "YYYY-MM-DD",
          separator: " - ",
        },
      },
      (start) => {
        this.inputPreAlert("etd", start.format("YYYY-MM-DD"));
      }
    );
    $("#eta2").daterangepicker(
      {
        autoUpdateInput: false,
        singleDatePicker: true,
        autoApply: true,
        locale: {
          format: "YYYY-MM-DD",
          separator: " - ",
        },
      },
      (start) => {
        this.inputPreAlert("eta", start.format("YYYY-MM-DD"));
      }
    );
    $("#bookingDate2").daterangepicker(
      {
        autoUpdateInput: false,
        singleDatePicker: true,
        autoApply: true,
        locale: {
          format: "YYYY-MM-DD",
          separator: " - ",
        },
      },
      (start) => {
        this.inputPreAlert("bookingDate", start.format("YYYY-MM-DD"));
      }
    );
    $("#closeDate2").daterangepicker(
      {
        autoUpdateInput: false,
        singleDatePicker: true,
        autoApply: true,
        locale: {
          format: "YYYY-MM-DD",
          separator: " - ",
        },
      },
      (start) => {
        this.inputPreAlert("closeDate", start.format("YYYY-MM-DD"));
      }
    );
  },
  methods: {
    showCustomerModal() {
      this.customerModalShow = true;
    },
    closeCustomerModal() {
      this.customerModalShow = false;
      this.inputPreAlert("customer", document.getElementById("customer").value);
      this.inputPreAlert(
        "consignee",
        document.getElementById("consignee").value
      );
    },
    updateCustomerModal(index, value) {
      this.newCustomerData = value;
      this.updatePreAlert();
      this.customerModalShow = false;
    },
    removePreAlert() {
      this.$emit("delete-prealert", this.index);
    },
    updatePreAlert() {
      this.$emit("update-prealert", this.index, this.newPreAlertData);
    },
    inputPreAlert(name, value) {
      console.log(name + ":" + value);
      let validation = this.validationsPreAlert[name];
      const elementDiv = document.getElementById(name);
      if (name != "pattern" && name != "hq") {
        var str = value.toUpperCase();
        elementDiv.value = str;
        value = str;
      }
      /* LCL OR FCLとVESSELの連動
         画面上の"Type"においてEXPRESSを選択した場合：
         VESSELに「海上速達便」と表示。
         また、属性は読み取り専用(Read-Only)
      */
      if (("pattern" == name) & ("EXPRESS" == value)) {
        this.newPreAlertData.vessel = "海上速達便";
        document.getElementById("vessel").readOnly = true;
      } else {
        const vesselElement = document.getElementById("vessel");
        // Type：Expressから別の値に変わる場合、
        // すなわちVESSELが読み取り専用ではなくなる場合のみ、
        // VESSELの値をクリア
        if (vesselElement.readOnly == true) {
          this.newPreAlertData.vessel = "";
        }
        // 読み取り専用を解除
        document.getElementById("vessel").readOnly = false;
      }

      if (validation) {
        if (value != "") {
          if (!validation(value)) {
            elementDiv.classList.add("is-invalid");
            elementDiv.classList.remove("is-valid");
          } else {
            elementDiv.classList.remove("is-invalid");
            elementDiv.classList.add("is-valid");
            this.newPreAlertData = this.preAlertData;
            this.newPreAlertData[name] = value;
            this.updatePreAlert();
          }
        } else {
          elementDiv.classList.remove("is-invalid");
          elementDiv.classList.remove("is-valid");
          this.newPreAlertData = this.preAlertData;
          this.newPreAlertData[name] = value;
          this.updatePreAlert();
        }
      } else {
        this.newPreAlertData = this.preAlertData;
        this.newPreAlertData[name] = value;
        this.updatePreAlert();
      }

      /*
       vesselの項目において、
       画面に入力したのが3文字目以上の場合、
       前方一致するオートコンプリートリストを表示
      */
      if (this.optionsFilterMap[name]) {
        if (value.length >= 3) {
          this.httpClient.getVesselList(
            "/getVesselList/",
            this.$cookie.getCookie("token"),
            this.setVesselOptions,
            this.onErrorAuth
          );
        }
      }
    },
    onSuccessDeleted(response) {
      console.log(response);
      this.newPreAlertData["fileName"] = "";
      this.updatePreAlert();
    },
    onErrorDeleted() {
      this.$emit("push-messagemodal", "File deleted failed.");
    },
    download(pid) {
      const url =
        "/files/dl/?accountName=" +
        this.$cookie.getCookie("accountName") +
        "&pid=" +
        pid;
      this.httpClient.dl(
        url,
        this.$cookie.getCookie("token"),
        this.onSuccessDownload,
        this.onErrorDownload
      );
    },
    onSuccessDownload(response) {
      const blob = new Blob([response.data], {
        type: response.data.type,
      });

      //レスポンスヘッダからファイル名を取得します
      const contentDisposition = response.headers["content-disposition"];
      const fileName = Utility.decodeFileName(contentDisposition);

      //ダウンロードします
      saveAs(blob, fileName);
    },
    onErrorDownload() {
      this.$emit("push-messagemodal", "File download failed.");
    },
    getPlaceholder(str) {
      if (this.readonly) {
        return "";
      } else {
        return str;
      }
    },
    // EnterキーでTab移動できる関数
    focusNext(e) {
      const inputs = Array.from(
        e.target.form.querySelectorAll('input[type="text"]')
      );
      const index = inputs.indexOf(e.target);

      if (index < inputs.length) {
        inputs[index + 1].focus();
      }
    },
    filterKey(e) {
      const key = e.key;

      // If is '.' key, stop it
      if (key === ".") return e.preventDefault();

      // OPTIONAL
      // If is 'e' key, stop it
      if (key === "e") return e.preventDefault();
    },
    optionsFilter() {
      this.httpClient.getVesselList(
        "/getVesselList/",
        this.$cookie.getCookie("token"),
        this.onSuccessCheckServerInfo,
        this.onErrorAuth
      );
    },

    suggestionClick(index) {
      this.query = this.results[index].email;
      this.open = false;
      this.current = 0;
      this.results = this.results[index];
    },
    setVesselOptions(response) {
      var input = document.getElementById("vessel");
      input.autocomplete = true;
      input.setAttribute("list", "vessel-option");
      var filtered = [];

      // datalistタグの作成
      this.vesselOption = document.getElementById("vessel-option");
      // リストを初期化
      for (var idx = this.vesselOption.childNodes.length - 1; idx >= 0; idx--) {
        this.vesselOption.removeChild(this.vesselOption.childNodes[idx]);
      }
      // サーバー側で取得した値をリストに追加
      for (var i = 0; i < response.data.vesselList.length; i++) {
        if (!filtered.includes(response.data.vesselList[i])) {
          // リストに追加するのは、画面で入力した値と前方一致する場合のみ
          if (response.data.vesselList[i].startsWith(input.value)) {
            filtered.push(response.data.vesselList[i]);
            var option = document.createElement("option");
            option.value = response.data.vesselList[i];
            this.vesselOption.appendChild(option);
          }
        }
      }
    },
  },
};
</script>