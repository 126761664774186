<template>
  <div
    id="request-top"
    class="container col-md-9 col-xl-9 re-content-fixed"
    @dragover.prevent
    @drop.prevent
  >
    <div id="loading" class="overlay" v-show="isRequestLoading">
      <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div id="preAlert-top" class="row d-flex justify-content-center">
      <div class="col-md-9 col-xl-10">
        <div
          class="
            d-flex
            justify-content-start
            flex-wrap flex-md-nowrap
            align-items-center
            pt-3
            pb-2
            mb-3
            border-bottom
          "
        >
          <h5 class="h5 d-flex align-items-center">
            <span data-toggle="tooltip">
              <img
                class="re-icon-lg justify-content-start"
                alt="preAlert"
                src="../assets/new.png"
              />
              PreAlert</span
            >
            <div v-if="preAlertBlDataSet != null && preAlertBlDataSet.fid != 0">
              <div data-toggle="tooltip" title="">
                <img
                  v-if="readonly === false && preAlertDataSet.length === 0"
                  class="re-icon-btn-md justify-content-start"
                  @click="setPreAlert()"
                  alt="追加"
                  src="../assets/plus.png"
                />
              </div>
            </div>
          </h5>
        </div>
      </div>
    </div>
    <div
      id="preAlert-top2"
      class="row d-flex justify-content-center"
      v-if="!isRequestLoading"
    >
      <div class="col-md-9 col-xl-10">
        <div class="row justify-content-center">
          <transition-group
            name="bounce"
            tag="div"
            appear
            class="col-md-9 col-xl-10"
            v-on:after-enter="enter()"
          >
            <div
              class="border rounded-lg shadow border-dark re-content-body"
              v-if="preAlertDataSet.length > 0"
            >
              <br />
              <div v-for="(preAlertData, d) in preAlertDataSet" :key="d">
                <preAlert
                  :index="d"
                  :readonly="readonly"
                  @deletePrealert="deletePrealert"
                  :preAlertData="preAlertData"
                  :callType="callType"
                  @updatePrealert="updatePrealert"
                  @pushMessagemodal="pushMessagemodal"
                />
              </div>
            </div>
          </transition-group>
        </div>
      </div>
    </div>
    <br />
    <!-- 
    「Modify A Pre-Alert」から遷移時に、添付ファイルがない場合、 
    Upload B/L以降は非表示
    -->
    <div v-show="callType != 'new'">
      <template
        v-if="
          (preAlertBlDataSet != null && preAlertBlDataSet.fid != 0) ||
          callType == 'show'
        "
      >
        <div id="attach-top" class="row d-flex justify-content-center">
          <div class="col-md-9 col-xl-10">
            <div
              class="
                d-flex
                justify-content-between
                flex-wrap flex-md-nowrap
                align-items-center
                pt-3
                pb-2
                mb-3
                border-bottom
              "
            >
              <h5 class="h5">
                <img
                  class="re-icon-lg justify-content-start"
                  alt="Upload B/L"
                  src="../assets/attach.png"
                />
                Upload B/L
              </h5>
              <div data-toggle="tooltip" title="">
                <img
                  v-if="
                    readonly === false &&
                    attachFilesVisible === false &&
                    (preAlertBlDataSet == null || preAlertBlDataSet.fid == 0)
                  "
                  class="re-icon-btn-md justify-content-start"
                  @click="changeAttachedVisible()"
                  alt="追加"
                  src="../assets/plus.png"
                />
              </div>
            </div>
            <br /><br />
            <!-- アップロードされたファイルを表示 -->
            <div class="h5 d-flex align-items-center">
              <div
                v-if="preAlertBlDataSet != null && preAlertBlDataSet.fid != 0"
              >
                Uploaded File:
                <strong>
                  <a
                    v-bind:href="preAlertBlDataSet.filePath"
                    target="_blank"
                    rel="norefferrer"
                    >{{ preAlertBlDataSet.fileTitle }}
                  </a>
                </strong>
                <br />
                <p>
                  If you would like to download, please click the file name.
                </p>
                <div class="h5 d-flex align-items-center">
                  <!-- 一度ファイルをアップロードしたら、再度アップロードしなおすことはできない通知
                (日本語)
                一度ファイルをアップロードした場合、画面上からファイルを変更できません。
                もしアップロードしたファイルを変更したい場合は、ご連絡ください。 -->
                  <br /><strong>
                    Once you have uploaded the file, you cannot change the file
                    from the screen. <br />If you would like to change the
                    uploaded file, please contact us.
                  </strong>
                </div>
              </div>
            </div>
          </div>
          <br /><br />
          <div
            id="attach-top2"
            class="row d-flex justify-content-center col-md-9 col-xl-10"
            v-if="preAlertBlDataSet == null || preAlertBlDataSet.fid == 0"
          >
            <div class="row justify-content-center col-md-9 col-xl-10">
              <!-- 
            添付ファイルが追加可能なのは、
            メニュー画面の「Upload B/C Doc(callType='show')」から遷移した場合のみ 
            -->
              <template v-if="this.callType == 'show'">
                <transition-group
                  name="bounce"
                  tag="div"
                  appear
                  class="col-md-9 col-xl-10"
                >
                  <attach-files
                    v-if="attachFilesVisible"
                    :attachFiles="attachFilesDataset"
                    :preAlertBlDataSet="preAlertBlDataSet"
                    :readonly="readonly"
                    @updateFileattached="updateFileattached"
                    @deleteFileattached="deleteFileattached"
                    @deleteAttached="deleteAttached"
                  >
                  </attach-files>
                </transition-group>
              </template>
            </div>
          </div>
          <br />
        </div>
      </template>
    </div>

    <br />
    <div class="row justify-content-around">
      <button
        type="button"
        class="btn btn-primary active col-md-3 re-shadow"
        data_but="btn-xs"
        @click="check()"
        v-if="readonly === false"
      >
        <i class="fa fa-check"></i>
        Verification
      </button>
      <button
        type="button"
        class="btn btn-info active col-md-3 re-shadow"
        data_but="btn-xs"
        @click="copy()"
        v-if="readonly === false && callType == 'edit'"
      >
        <i class="fa fa-clone" aria-hidden="true"></i>
        Copy
      </button>

      <button
        type="button"
        class="btn btn-info col-md-3 re-shadow"
        data_but="btn-xs"
        @click="save('保存', 0, 'として保存しました。')"
        v-if="readonly === true && !isDataLock && editDataSet != null"
      >
        <i class="fa fa-folder-open"></i>
        Save
      </button>
      <button
        type="button"
        class="btn btn-info col-md-3 re-shadow"
        data_but="btn-xs"
        @click="save('保存', 0, 'として保存しました。')"
        v-if="readonly === true && !isDataLock && editDataSet == null"
      >
        <i class="fa fa-folder-open"></i>
        Save
      </button>
      <button
        type="button"
        class="btn btn-primary col-md-3 re-shadow"
        data_but="btn-xs"
        @click="back()"
        v-if="readonly === true && !isDataLock"
      >
        <i class="fa fa-stop-circle"></i>
        Cancel
      </button>
    </div>
    <br />
    <br />
    <br />
    <br />
    <br />
    <transition-group name="bounce" tag="div" appear>
      <messageModal
        v-if="messageModalStatus.show === true"
        :messageModalStatus="messageModalStatus"
        @messagemodal-close="messageModalClose"
      />
    </transition-group>
  </div>
</template>

<script>
import PreAlert from "../components/request/PreAlert.vue";
import AttachFiles from "../components/request/AttachFiles.vue";
import MessageModal from "../components/MessageModal.vue";
import MixinAttachFile from "../mixins/request/attachFile";
import MixinPreAlert from "../mixins/request/preAlert";
import HttpClient from "../modules/http";

export default {
  components: {
    preAlert: PreAlert,
    attachFiles: AttachFiles,
    messageModal: MessageModal,
  },
  mixins: [MixinAttachFile, MixinPreAlert],
  props: ["paramPid"],
  emits: [
    "deletePreAlert",
    "updatePreAlert",
    "updateAttachedfile",
    "updateAttachedtype",
    "deleteAttachedfile",
    "deleteAttached",
    "pushMessagemodal",
  ],
  computed: {
    isRequestLoading: function () {
      if (!this.isEditLoading) {
        return false;
      } else {
        return true;
      }
    },
    isDataLock: function () {
      if (this.editDataSet != null && this.editDataSet.length > 0) {
        if (this.editDataSet[0].customerLock != 1) {
          return false;
        } else {
          return true;
        }
      }

      return false;
    },
  },
  data: function () {
    return {
      checkAuthShow: false,
      callType: "new",
      shipType: 1,
      readonly: false,
      editDataSet: null,
      copyDataSet: null,
      isEditLoading: false,
      apiSendStatus: {
        pid: 0,
        preAlert: 0,
        finish: 0,
        error: 0,
        status: 0,
      },
      messageModalStatus: {
        show: false,
        message: [],
        messageType: "info",
        loading: false,
        button: false,
      },
      httpClient: new HttpClient(),
      dispType: "",
      dispType2: "",
      updateStatusType: 0,
    };
  },
  mounted: function () {
    this.checkAuth();
    $('[data-toggle="tooltip"]').tooltip();
  },
  created: function () {
    this.$store.watch(
      (state, getters) => getters.preAlertDetailData,
      (value) => {
        if (value == null) {
          this.dataAllCear();
        }
      }
    );
    this.$store.watch(
      (state, getters) => getters.preAlertShowData,
      (value) => {
        if (value == null) {
          this.dataAllCear();
        }
      }
    );
    if (this.$store.getters.preAlertDetailData != null) {
      this.isEditLoading = true;
      this.pid = this.$store.getters.preAlertDetailData.pid;
      this.callType = "edit";
      const url =
        "/rep/?&accountName=" +
        this.$cookie.getCookie("accountName") +
        "&pid=" +
        this.pid;
      this.httpClient.get(
        url,
        this.$cookie.getCookie("token"),
        this.onSuccessDetail,
        this.onErrorAuth
      );
    } else if (this.$store.getters.preAlertShowData != null) {
      this.isEditLoading = true;
      this.pid = this.$store.getters.preAlertShowData.pid;
      this.callType = "show";
      const url =
        "/rep/?&accountName=" +
        this.$cookie.getCookie("accountName") +
        "&pid=" +
        this.pid;
      this.httpClient.get(
        url,
        this.$cookie.getCookie("token"),
        this.onSuccessDetail,
        this.onErrorAuth
      );
    } else {
      this.dataAllCear();
    }
  },
  watch: {
    apiSendStatus: {
      handler: function (oldStatus, newStatus) {
        console.log(this.apiSendStatus);
        if (
          this.apiSendStatus.pid == 1 &&
          this.apiSendStatus.preAlert == 1 &&
          this.apiSendStatus.attached == 1 &&
          this.apiSendStatus.status == 0
        ) {
          this.apiSendStatus.status = 1;
          this.messageModalStatus.message = [];
          this.messageModalStatus.message.push(
            "Saved as No." + Math.abs(this.pid)
          );
          this.messageModalStatus.loading = false;
          this.messageModalStatus.button = true;
          this.apiSendStatus.finish = 1;
        } else {
          this.apiSendStatus.error = 1;
          if (
            newStatus.pid != 0 &&
            newStatus.preAlert != 0 &&
            newStatus.attached != 0
          ) {
            this.messageModalStatus.button = true;
          }

          if (oldStatus.pid != newStatus.pid && newStatus.pid == 2) {
            this.messageModalStatus.messageType = "warn";
            this.messageModalStatus.message.push(
              "The pre-alert could not be issued."
            );
          }

          if (
            oldStatus.prealert != newStatus.prealert &&
            newStatus.prealert == 2
          ) {
            this.messageModalStatus.messageType = "warn";
            this.messageModalStatus.message.push(
              "The pre-alert could not be saved."
            );
          }

          if (
            oldStatus.attached != newStatus.attached &&
            newStatus.attached == 2
          ) {
            this.messageModalStatus.messageType = "warn";
            this.messageModalStatus.message.push(
              "The attached document could not be saved."
            );
          }
          this.messageModalStatus.loading = false;
        }
      },
      deep: true,
    },
  },
  methods: {
    checkAuth() {
      this.isLoading = true;
      this.checkAuthShow = true;
      const url =
        "/auth/check/?accountName=" + this.$cookie.getCookie("accountName");
      this.httpClient.get(
        url,
        this.$cookie.getCookie("token"),
        this.onSuccessAuth,
        this.onErrorAuth
      );
    },
    onSuccessAuth() {
      this.checkAuthShow = false;
      if (this.callType == "new") {
        this.setPreAlert();
      }
    },
    enter() {
      if (this.callType == "show") {
        this.changeAttachedVisible();
      }
    },
    onErrorAuth(error) {
      this.isLoading = false;
      if (
        error != null &&
        error.response != null &&
        "status" in error.response &&
        error.response.status == 401
      ) {
        this.messageModalStatus.message.push(
          "The session has expired. Please log in again."
        );
      } else {
        this.messageModalStatus.message.push(
          "An error has occurred. Please log in again."
        );
      }
      this.messageModalStatus.show = true;
      this.messageModalStatus.messageType = "warn";
      this.messageModalStatus.button = true;
    },
    scrollTarget: function (id) {
      var target = document.getElementById(id);
      $("html, body").animate(
        {
          scrollTop: target.offsetTop - 80,
        },
        500
      );
    },
    messageModalClose() {
      this.messageModalStatus.show = false;
      if (!this.checkAuthShow) {
        if (this.apiSendStatus.finish == 1) {
          if (this.callType == "new") {
            this.$router.push({ name: "Home" });
          } else if (this.callType == "edit") {
            this.$router.push({ name: "list" });
          } else {
            this.$router.push({ name: "upload" });
          }
        }
      } else {
        this.checkAuthShow = false;
        this.$router.push({ name: "login" });
      }
    },
    back() {
      // 保存せず、入力モードに戻る
      this.readonly = false;
      if (this.callType == "show") {
        this.scrollTarget("attach-top");
      } else {
        this.scrollTarget("request-top");
      }
    },
    close() {
      if (this.callType == "show") {
        this.$router.push({ name: "upload" });
      } else {
        this.$router.push({ name: "list" });
      }
    },
    check() {
      // バリデーションチェック
      if (this.checkInputLength(true) && this.checkInputRequire() && this.checkBytesLength()) {
        /*サーバー側チェック
        新規登録時に、
        JOBNoとConsignee重複チェック
        */
        const params = {
          accountName: this.$cookie.getCookie("accountName"),
          preAlert: this.preAlertDataSet,
        };

        if (this.readonly == false && this.callType == "new") {
          this.httpClient.checkServerInfo(
            "/checkServerInfo/",
            this.$cookie.getCookie("token"),
            params,
            this.onSuccessCheckServerInfo,
            this.onErrorAuth
          );
        } else {
          // コピーの場合、save画面に進む
          this.readonly = true;
        }
      }

      if (this.callType != "show") {
        this.scrollTarget("request-top");
      } else {
        this.scrollTarget("attach-top");
      }
    },
    onSuccessCheckServerInfo(response) {
      if (response.data.status != "OK") {
        this.messageModalStatus.message = [];
        this.messageModalStatus.message.push(response.data.status);
        this.messageModalStatus.messageType = "warn";
        this.messageModalStatus.button = true;
        this.messageModalStatus.show = true;
      } else {
        this.readonly = true;
      }
    },
    changeSendStatus(type, value) {
      this.apiSendStatus[type] = value;
    },
    checkInputLength(msgDisp) {
      if (this.preAlertDataSet.length == 0) {
        if (msgDisp) {
          this.messageModalStatus.message = [];
          this.messageModalStatus.message.push("Nothing has been entered.");
          this.messageModalStatus.messageType = "warn";
          this.messageModalStatus.button = true;
          this.messageModalStatus.show = true;
        }

        return false;
      } else if (
        this.callType == "show" &&
        this.attachFilesDataset.length == 0 &&
        this.preAlertBlDataSet.length == 0
      ) {
        if (msgDisp) {
          this.messageModalStatus.message = [];
          this.messageModalStatus.message.push("No file.");
          this.messageModalStatus.messageType = "warn";
          this.messageModalStatus.button = true;
          this.messageModalStatus.show = true;
        }

        return false;
      }

      return true;
    },
    checkInputRequire() {
      console.log(this.preAlertDataSet);
      let errorVal = false;
      this.messageModalStatus.message = [];
      if (this.preAlertDataSet.length > 0) {
        var jobNo = this.preAlertDataSet[0].jobNo;
        if (jobNo.length == 0 || jobNo == "") {
          this.messageModalStatus.message.push("Please input the JOB No.");
          errorVal = true;
        }

        var shipper = this.preAlertDataSet[0].shipper;
        if (errorVal == false) {
          if (shipper.length == 0 || shipper == "") {
            this.messageModalStatus.message.push("Please input the SHIPPER");
            errorVal = true;
          }
        }

        var consignee = this.preAlertDataSet[0].consignee;
        if (errorVal == false) {
          if (consignee.length == 0 || consignee == "") {
            this.messageModalStatus.message.push("Please input the CONSIGNEE");
            errorVal = true;
          }
        }

        var referCd = this.preAlertDataSet[0].customer;
        if (errorVal == false) {
          if (referCd.length == 0 || referCd == "" || referCd == 0) {
            this.messageModalStatus.message.push("Please input the REF Code");
            errorVal = true;
          }
        }

        var notify = this.preAlertDataSet[0].notify;
        if (errorVal == false) {
          if (notify.length == 0 || notify == "") {
            this.messageModalStatus.message.push(
              "Please input the NOTIFY PARTY"
            );
            errorVal = true;
          }
        }

        var vessel = this.preAlertDataSet[0].vessel;
        if (errorVal == false) {
          if (vessel.length == 0 || vessel == "") {
            this.messageModalStatus.message.push("Please input the VESSEL");
            errorVal = true;
          }
        }

        var voyage = this.preAlertDataSet[0].voyage;
        if (errorVal == false) {
          if (voyage.length == 0 || voyage == "") {
            this.messageModalStatus.message.push("Please input the VOY");
            errorVal = true;
          }
        }

        var pol = this.preAlertDataSet[0].pol;
        if (errorVal == false) {
          if (pol.length == 0 || pol == "") {
            this.messageModalStatus.message.push("Please input the POL");
            errorVal = true;
          }
        }

        var etd = this.preAlertDataSet[0].etd;
        if (errorVal == false) {
          if (etd.length == 0 || pod == "") {
            this.messageModalStatus.message.push("Please input the ETD");
            errorVal = true;
          } else {
            if (!this.checkDate(etd)) {
              this.messageModalStatus.message.push("ETD is invalid.");
              errorVal = true;
            } else {
              if (!this.confirmDate(etd, 1)) {
                this.messageModalStatus.message.push("ETD is out of range.");
                errorVal = true;
              }
            }
          }
        }

        var pod = this.preAlertDataSet[0].pod;
        if (errorVal == false) {
          if (pod.length == 0 || pod == "") {
            this.messageModalStatus.message.push("Please input the POD");
            errorVal = true;
          }
        }

        var eta = this.preAlertDataSet[0].eta;
        if (errorVal == false) {
          if (eta.length == 0 || pod == "") {
            this.messageModalStatus.message.push("Please input the ETA");
            errorVal = true;
          } else {
            if (!this.checkDate(eta)) {
              this.messageModalStatus.message.push("ETA is invalid.");
              errorVal = true;
            } else {
              if (!this.confirmDate(eta, 1)) {
                this.messageModalStatus.message.push("ETA is out of range.");
                errorVal = true;
              }
            }
          }
        }

        var commodity = this.preAlertDataSet[0].commodity;
        if (errorVal == false) {
          if (commodity.length == 0 || commodity == "") {
            this.messageModalStatus.message.push("Please input the COMMODITY");
            errorVal = true;
          }
        }

        var quantity = this.preAlertDataSet[0].quantity;
        if (errorVal == false) {
          if (!this.checkDouble(quantity)) {
            this.messageModalStatus.message.push(
              "Only number could be inputed into QUANTITY"
            );
            errorVal = true;
          }
        }

        var packages = this.preAlertDataSet[0].packages;
        if (errorVal == false) {
          if (packages.length == 0 || packages == "") {
            this.messageModalStatus.message.push("Please input the Package");
            errorVal = true;
          }
        }

        var housBl = this.preAlertDataSet[0].housBl;
        if (errorVal == false) {
          if (housBl.length == 0 || housBl == "") {
            this.messageModalStatus.message.push("Please input the HOUSE B/L");
            errorVal = true;
          }
        }

        var grossWeight = this.preAlertDataSet[0].grossWeight;
        if (errorVal == false) {
          if (!this.checkDouble(grossWeight)) {
            this.messageModalStatus.message.push(
              "Only number could be inputed into GROSS WEIGHT"
            );
            errorVal = true;
          }
        }

        var mesurement = this.preAlertDataSet[0].mesurement;
        if (errorVal == false) {
          if (!this.checkDouble(mesurement)) {
            this.messageModalStatus.message.push(
              "Only number could be inputed into MEASUREMENT"
            );
            errorVal = true;
          }
        }

        var bookingDate = this.preAlertDataSet[0].bookingDate;
        if (errorVal == false) {
          if (bookingDate.length == 0 || bookingDate == "") {
            this.messageModalStatus.message.push(
              "Please input the BOOKING DATE"
            );
            errorVal = true;
          } else {
            if (!this.checkDate(bookingDate)) {
              this.messageModalStatus.message.push("BOOKING DATE is invalid.");
              errorVal = true;
            } else {
              if (!this.confirmDate(bookingDate, 0)) {
                this.messageModalStatus.message.push(
                  "BOOKINGDATE is out of range."
                );
                errorVal = true;
              }
            }
          }
        }

        var closeDate = this.preAlertDataSet[0].closeDate;
        if (errorVal == false && closeDate != "" && closeDate != null) {
          if (!this.checkDate(closeDate)) {
            this.messageModalStatus.message.push("CLOSE DATE is invalid.");
            errorVal = true;
          } else {
            if (!this.confirmDate(closeDate, 0)) {
              this.messageModalStatus.message.push(
                "CLOSE DATE is out of range."
              );
              errorVal = true;
            }
          }
        }
      }

      if (errorVal) {
        this.messageModalStatus.messageType = "warn";
        this.messageModalStatus.button = true;
        this.messageModalStatus.show = true;

        return false;
      }

      return true;
    },
    //バイト数チェック(全/半入力項目のみ)
    checkBytesLength() {
      console.log(this.preAlertDataSet);
      let errorVal = false;
      this.messageModalStatus.message = [];
      
      if (this.preAlertDataSet.length > 0) {
        if (!this.checkLenB(this.preAlertDataSet[0].jobNo,"JOB No.",20)) {errorVal = true;}
        if (errorVal == false && !this.checkLenB(this.preAlertDataSet[0].shipper,"SHIPPER",100)) {errorVal = true;}
        if (errorVal == false && !this.checkLenB(this.preAlertDataSet[0].consignee,"CONSIGNEE",100)) {errorVal = true;}
        if (errorVal == false && !this.checkLenB(this.preAlertDataSet[0].notify,"NOTIFY PARTY",100)) {errorVal = true;}
        if (errorVal == false && !this.checkLenB(this.preAlertDataSet[0].vessel,"VESSEL",50)) {errorVal = true;}
        if (errorVal == false && !this.checkLenB(this.preAlertDataSet[0].voyage,"VOY",20)) {errorVal = true;}
        if (errorVal == false && !this.checkLenB(this.preAlertDataSet[0].carrier,"CARRIER",50)) {errorVal = true;}
        if (errorVal == false && !this.checkLenB(this.preAlertDataSet[0].pol,"POL",20)) {errorVal = true;}
        if (errorVal == false && !this.checkLenB(this.preAlertDataSet[0].pod,"POD",20)) {errorVal = true;}
        if (errorVal == false && !this.checkLenB(this.preAlertDataSet[0].commodity,"COMMODITY",100)) {errorVal = true;}
        if (errorVal == false && !this.checkLenB(this.preAlertDataSet[0].packages,"Package",20)) {errorVal = true;}
        if (errorVal == false && !this.checkLenB(this.preAlertDataSet[0].housBl,"HOUSE B/L",30)) {errorVal = true;}
        if (errorVal == false && !this.checkLenB(this.preAlertDataSet[0].poNo,"PO No.",250)) {errorVal = true;}
        if (errorVal == false && !this.checkLenB(this.preAlertDataSet[0].tradeTerms,"TRADE TERMS",50)) {errorVal = true;}
        if (errorVal == false && !this.checkLenB(this.preAlertDataSet[0].specHandle,"SPEC. HANDLING",50)) {errorVal = true;}
        if (errorVal == false && !this.checkLenB(this.preAlertDataSet[0].marks,"SHIPPING MARK",250)) {errorVal = true;}
        if (errorVal == false && !this.checkLenB(this.preAlertDataSet[0].containerNo,"CONTAINER NO.",500)) {errorVal = true;}
        if (errorVal == false && !this.checkLenB(this.preAlertDataSet[0].factory,"FACTORY",100)) {errorVal = true;}
        if (errorVal == false && !this.checkLenB(this.preAlertDataSet[0].delayInfo,"DELAY INFO",100)) {errorVal = true;}
      }
      if (errorVal) {
        this.messageModalStatus.messageType = "warn";
        this.messageModalStatus.button = true;
        this.messageModalStatus.show = true;
        return false;
      }
      return true;
    },
    //バイト数チェック
    checkLenB(myId, myTitle, myLen){
      //var value_bytes = encodeURI(myId).replace(/%../g, "*").length; //全角が3bytesになる
      let value_bytes = 0;
      for (let i = 0; i < myId.length; i++) {
        (myId[i].match(/[ -~]/)) ? value_bytes += 1 : value_bytes += 2;
      }
      if (value_bytes > myLen) {
        this.messageModalStatus.message.push("Please input the " + myTitle + " at less than " + myLen + " bytes (now="+ value_bytes + "bytes)");
        return false;
      }
      return true;
    },
    checkDate(strDate) {
      if (!strDate.match(/^\d{4}-\d{2}-\d{2}$/)) {
        return false;
      }
      var y = strDate.split("-")[0];
      var m = strDate.split("-")[1] - 1;
      var d = strDate.split("-")[2];
      var date = new Date(y, m, d);
      if (
        date.getFullYear() != y ||
        date.getMonth() != m ||
        date.getDate() != d
      ) {
        return false;
      }
      return true;
    },
    checkDouble(strNumber) {
      // if (strNumber == '') {
      //   return false;
      // }
      if (!isFinite(strNumber)) {
        return false;
      }
      if (strNumber < 0) {
        return false;
      }
      return true;
    },
    confirmDate(dt, cnt) {
      var y = dt.split("-")[0];
      var m = dt.split("-")[1] - 1;
      var d = dt.split("-")[2];
      var date1 = new Date(y, m, d);
      var today = new Date();
      var date2 = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );
      var termDay = (date1 - date2) / 86400000;
      var consignee = document.getElementById("consignee").value;

      if (consignee == "RANDOM") {
        if (termDay >= 7 * (cnt - 3) && termDay <= 90) {
          return true;
        } else {
          return false;
        }
      } else {
        if (termDay >= 7 * (cnt - 3) && termDay <= 60) {
          return true;
        } else {
          return false;
        }
      }
    },
    pushMessagemodal(msg) {
      this.messageModalStatus.message = [];
      this.messageModalStatus.message.push(msg);
      this.messageModalStatus.messageType = "warn";
      this.messageModalStatus.button = true;
      this.messageModalStatus.show = true;
    },
    save(type, status, type2) {
      this.updateStatusType = status;
      this.dispType = type;
      this.dispType2 = type2;
      this.readonly = true;
      if (!this.checkInputLength(false)) {
        this.messageModalStatus.message = [];
        this.messageModalStatus.message.push(
          "It cannot be saved without any input."
        );
        this.messageModalStatus.messageType = "warn";
        this.messageModalStatus.button = true;
        this.messageModalStatus.show = true;
      } else if (!this.checkInputRequire()) {
        this.readonly = false;
      } else if (!this.checkBytesLength()) {
        this.readonly = false;
      } else if (this.isValitatePreAlertState) {
        this.messageModalStatus.message = [];
        this.messageModalStatus.message.push(
          "There is an error in the input content. please confirm."
        );
        this.messageModalStatus.messageType = "warn";
        this.messageModalStatus.button = true;
        this.messageModalStatus.show = true;
      } else {
        this.messageModalStatus.message = [];
        this.messageModalStatus.message.push("Saving...");
        this.messageModalStatus.messageType = "info";
        this.messageModalStatus.button = false;
        this.messageModalStatus.loading = true;
        this.messageModalStatus.show = true;
        if (this.callType == "new") {
          this.numberingPreAlertNo();
        } else if (this.callType == "edit") {
          this.savePreAlert();
        } else {
          if (this.attachFilesDataset.length > 0) {
            this.apiSendStatus.preAlert = 1;
            this.apiSendStatus.pid = 1;
            this.saveAttachedFiles(this.pid);
          } else {
            this.messageModalStatus.message = [];
            this.messageModalStatus.message.push("No file");
            this.messageModalStatus.messageType = "warn";
            this.messageModalStatus.button = true;
            this.messageModalStatus.show = true;
          }
        }
      }
    },
    copy() {
      this.copyDataSet = null;
      this.copyDataSet = JSON.stringify(this.preAlertDataSet);
      this.copyDataSet = JSON.parse(this.copyDataSet);
      // コピーしない項目を空白値にセットする
      this.copyDataSet[0].pid = 0;
      this.copyDataSet[0].jobNo = "";
      this.copyDataSet[0].voyage = "";
      this.copyDataSet[0].pattern = "LCL";
      this.copyDataSet[0].etd = "";
      this.copyDataSet[0].eta = "";
      this.copyDataSet[0].quantity = "";
      this.copyDataSet[0].grossWeight = "";
      this.copyDataSet[0].mesurement = "";
      this.copyDataSet[0].packages = "";
      this.copyDataSet[0].housBl = "";
      this.copyDataSet[0].poNo = "";
      this.copyDataSet[0].bookingDate = "";
      this.copyDataSet[0].closeDate = "";
      this.copyDataSet[0].container20 = "";
      this.copyDataSet[0].container40 = "";
      this.copyDataSet[0].hq = 0;
      this.copyDataSet[0].tradeTerms = "";
      this.copyDataSet[0].specHandle = "";
      this.copyDataSet[0].factory = "";
      this.copyDataSet[0].marks = "";
      this.copyDataSet[0].containerNo = "";
      this.copyDataSet[0].delayInfo = "";

      this.dataAllCear();

      this.preAlertDataSet = this.copyDataSet;
      this.scrollTarget("preAlert-top");
    },
    onSuccessDetail(response) {
      console.log(response.data.prelist[0]);
      this.isEditLoading = false;
      this.editDataSet = JSON.stringify(response.data.prelist[0]);
      this.editDataSet = JSON.parse(this.editDataSet);
      this.preAlertDataSet = response.data.prelist;
      this.preAlertBlDataSet = response.data.prelistBl;

      if (this.preAlertBlDataSet.fid != 0) {
        this.attachFilesVisible = false;
      } else {
        this.attachFilesVisible = true;
      }

      console.log("----------");
      console.log(this.editDataSet);

      // if (this.callType == 'show') {
      //   this.changeAttachedVisible();
      //   this.scrollTarget("attach-top");
      // }

      // if (response.data.link[0].customerLock == 1) {
      //   this.readonly = true;
      // }
    },
    checkServerInfo() {
      const params = {
        accountName: this.$cookie.getCookie("accountName"),
        preAlert: this.preAlertDataSet,
      };
      this.httpClient.checkServerInfo(
        "/checkServerInfo/",
        this.$cookie.getCookie("token"),
        params,
        this.onSuccessNumbering,
        this.onErrorAuth
      );
    },
    numberingPreAlertNo() {
      const params = {
        accountName: this.$cookie.getCookie("accountName"),
        preAlert: this.preAlertDataSet,
      };
      this.httpClient.save(
        "/number/",
        this.$cookie.getCookie("token"),
        params,
        this.onSuccessNumbering,
        this.onErrorAuth
      );
    },
    savePreAlert() {
      console.log(this.preAlertDataSet);
      this.isloadingPreAlertState = true;
      const params = {
        accountName: this.$cookie.getCookie("accountName"),
        preAlert: this.preAlertDataSet,
      };
      this.httpClient.save(
        "/preAlert/",
        this.$cookie.getCookie("token"),
        params,
        this.onSuccessNumbering,
        this.onErrorNumbering
      );
    },
    onSuccessNumbering(response) {
      if (response.data.status != "OK") {
        this.messageModalStatus.message = [];
        this.messageModalStatus.message.push(response.data.status);
        this.messageModalStatus.messageType = "warn";
        this.messageModalStatus.button = true;
        this.messageModalStatus.show = true;
      } else {
        this.pid = response.data.pid;
        this.apiSendStatus.pid = 1;
        //this.apiSendStatus.attached = 1;
        this.apiSendStatus.preAlert = 1;
        if (this.attachFilesDataset.length > 0) {
          this.saveAttachedFiles(this.pid);
        } else {
          this.apiSendStatus.attached = 1;
        }
      }
    },
    onErrorNumbering() {
      this.apiSendStatus.pid = 2;
    },
    updateStatus(status) {
      const params = {
        accountName: this.$cookie.getCookie("accountName"),
        pid: this.pid,
        status: status,
      };
      this.httpClient.edit(
        "/",
        this.$cookie.getCookie("token"),
        params,
        this.onSuccessStatusUpdate,
        this.onErrorAuth
      );
    },
    onSuccessStatusUpdate() {},
    dataAllCear() {
      this.callType = "new";
      this.pid = 0;
      this.readonly = false;
      this.editDataSet = null;
      this.preAlertDataSet = [];
      this.apiSendStatus = {
        pid: 0,
        preAlert: 0,
        finish: 0,
        error: 0,
        status: 0,
      };
      this.messageModalStatus = {
        show: false,
        message: [],
        messageType: "info",
        loading: false,
        button: false,
      };
    },
  },
};
</script>
