<template>
  <div class="home">
    HOME
  </div>
</template>
<script>
export default {
  created: function () {
    this.checkSession();
  },
  methods: {
    checkSession() {
      if (this.$cookie.getCookie("accountName") &&
        this.$cookie.getCookie("token") &&
        this.$store.getters.userInfo != null
      ) {
        this.$router.push({ name: "info" });
      }else{
        this.$router.push({ name: "login" });
      }
    },
  },
};
</script>
