import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { VueCookieNext } from 'vue-cookie-next'
import store from "./stores/store"

const app = createApp(App);
app.use(router);
app.use(VueCookieNext);
app.use(store);
app.mount('#app')

//createApp(App).use(router).use(VueCookies).mount('#app')

