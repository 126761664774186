import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

const store = createStore({
    plugins: [createPersistedState()],
    //state:コンポーネントでいうdata
    state() {
        return {
            login: false,
            userInfo:null,
            preAlertDetailData:null,
            preAlertShowData:null,
        }
    },
  
    //getters:コンポーネントでいうcomputed的なもの
    getters:{
      //messageを使用するgetter
      login(state) {
        return state.login
      },
      userInfo(state){
        return state.userInfo
      },
      preAlertDetailData(state){
        return state.preAlertDetailData
      },
      preAlertShowData(state){
        return state.preAlertShowData
      }

    },
  
    //mutations:コンポーネントでいうmethod（と言うかsetter）
    //stateを唯一変更できるもの
    mutations: {
      //vuexでは引数をpayloadと呼ぶっぽい
      //payloadはオブジェクトにするべき（いっぱい入れれるし）
      setLogin(state,payload){
        state.login = payload.login
      },
      setUserIfo(state,payload){
        state.userInfo = payload.userInfo
      },
      setPreAlertDetailData(state,payload){
        state.preAlertDetailData = payload.preAlertDetailData
      },
      setPreAlertShowData(state,payload){
        state.preAlertShowData = payload.preAlertShowData
      },      
      setInitalize(state){
        state.login = false,
        state.userInfo = null,
        state.preAlertDetailData = null
        state.preAlertShowData = null
      }
    },
  
    //actionのコミットを使うことでミューテーションを呼び出す（コンポーネントには無い概念）
    actions: {
      doUpdateLogin({commit}, login){
        commit('setLogin',{login})
      },
      doUpdateUserInfo({commit}, userInfo){
        commit('setUserIfo',{userInfo})
      },
      doUpdatePreAlertDetailData({commit}, preAlertDetailData){
        commit('setPreAlertDetailData',{preAlertDetailData})
      },
      doShowPreAlertShowData({commit}, preAlertShowData){
        commit('setPreAlertShowData',{preAlertShowData})
      },      
      doInitalize({commit}){
        commit('setInitalize')
      }
    }
  })
  export default store