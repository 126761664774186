<template>
  <div class="re-content-fixed login">
    <div class="container d-flex justify-content-center">
      <form>
        <div class="form-row d-flex justify-content-center">
          <h2 class="h2">{{ msg }}</h2>
          <p class="error_msg">{{ error_msg_login }}</p>
        </div>
        <div class="form-row">
          <div class="form-group">
            <label for="accountName">Account</label>
            <input
              type="text"
              class="form-control"
              name="accountName"
              v-model="accountName"
              placeholder="Account"
              @keydown.enter="focusNext"
            />
          </div>
        </div>
        <p class="error_msg">{{ error_msg_account_name }}</p>
        <div class="form-row">
          <div class="form-group">
            <label for="passwd">Password</label>
            <input
              type="password"
              class="form-control"
              name="passwd"
              v-model="passwd"
              placeholder="Password"
              @keydown.enter="focusNext"
            />
          </div>
        </div>
        <p class="error_msg">{{ error_msg_password }}</p>
        <div class="form-row d-flex justify-content-center">
          <div class="form-group">
            <button
              @click="login()"
              type="button"
              class="btn btn-primary btn-lg btn-block"
            >
              LOGIN
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script >
import axios from "axios";

export default {
  name: "LOGIN",
  components: {},
  data() {
    return {
      dayStr: ["7", "1", "2", "3", "4", "5", "6"],
      date: "2018-06-01",
      isM: false,
      msg: "LOGIN",
      error_msg: "",
      error_msg_login: "",
      error_msg_account_name: "",
      error_msg_password: "",
      agentId: "",
      accountName: "",
      passwd: "",
      tokenCode: "",
    };
  },
  created() {
    this.$store.dispatch("doInitalize");
  },
  methods: {
    login: function () {
      let validation_result = true;
      if (this.accountName == "") {
        this.error_msg_account_name = "No account has been entered.";

        validation_result = false;
      }
      if (this.passwd == "") {
        this.error_msg_password = "No password has been entered.";
        validation_result = false;
      }
      if (validation_result) {
        // if (this.checkCookie("token")) {
        //   const tokenValue = this.$cookie.getCookie("token");
        //   this.token("", tokenValue);
        // } else {
        //   this.authcode();
        // }
        this.authcode();
      }
    },
    checkCookie(name) {
      if (this.$cookie.getCookie(name)) {
        return true;
      }

      return false;
    },
    authcode: function () {
      const authcode_url =
        process.env.VUE_APP_TARGET_API_HOST + "/api/v1/prealert/auth/authcode/";
      const authcode_params = {};
      authcode_params["accountName"] = this.accountName;
      authcode_params["password"] = this.passwd;

      const authcode_json_params = JSON.stringify(authcode_params);
      axios
        .post(authcode_url, authcode_json_params, {
          headers: { "Content-Type": "application/json" },
          responseType: "json",
        })
        .then((response) => {
          // handle success
          if (200 < response.status && response.status < 300) {
            this.token(response.data["authCode"], "");
          }
        })
        .catch((error) => {
          // handle error
          this.error_msg = "certification failed.";
          if (error.status == 400) {
            this.error_msg_password =
              this.error_msg + "There is an error in the input content.";
          } else {
            this.error_msg_password =
              this.error_msg + "Please check your input again.";
          }
        })
        .finally(function () {});
    },
    token: function (authCode, token) {
      const token_url =
        process.env.VUE_APP_TARGET_API_HOST + "/api/v1/prealert/auth/token/";
      const token_params = {};
      token_params["accountName"] = this.accountName;
      token_params["password"] = this.passwd;
      token_params["authCode"] = authCode;
      if (token != "") {
        token_params["token"] = encodeURI(token);
      }

      const token_json_params = JSON.stringify(token_params);
      axios
        .post(token_url, token_json_params, {
          headers: { "Content-Type": "application/json" },
          responseType: "json",
        })
        .then((response) => {
          // handle success
          if (200 < response.status && response.status < 300) {
            this.$cookie.setCookie("token", response.data.tokenData.token);
            this.$cookie.setCookie("accountName", this.accountName);
            this.$store.dispatch("doUpdateLogin", true);
            this.getUserInfo();
          }
        })
        .catch((error) => {
          // handle error
          console.log(error);
          this.error_msg = "認証に失敗しました。";
        })
        .finally(function () {});
    },
    getUserInfo() {
      const userinfo_url =
        process.env.VUE_APP_TARGET_API_HOST + "/api/v1/prealert/users/info/";
      const user_params = {};
      user_params["accountName"] = this.$cookie.getCookie("accountName");
      user_params["password"] = this.passwd;

      const user_json_params = JSON.stringify(user_params);
      axios
        .post(userinfo_url, user_json_params, {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$cookie.getCookie("token"),
          },
          responseType: "json",
        })
        .then((response) => {
          // handle success
          if (200 <= response.status && response.status < 300) {
            this.$store.dispatch("doUpdateUserInfo", response.data);
            this.$cookie.setCookie("compName", response.data.user.userName);
            this.$router.push({ name: "info" });
          }
        })
        .catch((error) => {
          // handle error
          console.log(error);
          // if (error.status == 400) {
          // } else {
          // }
        })
        .finally(function () {});
    },
    // EnterキーでTab移動できる関数(textからpasswordに移動)
    focusNext(e) {
      const inputs = Array.from(
        e.target.form.querySelectorAll('input[type="password"]')
      );
      const index = inputs.indexOf(e.target);

      if (index < inputs.length) {
        inputs[index + 1].focus();
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.error_msg {
  color: darkred;
}
</style>