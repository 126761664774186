<template>
  <br />
  <br />
  <br />
  <div class="container border">
    <h5>information</h5>
    <ul>
      <li>UserID:{{ this.$cookie.getCookie("accountName") }}</li>
      <li>CompanyName:{{ this.$cookie.getCookie("compName") }}</li>
    </ul>
  </div>
  <transition-group name="bounce" tag="div" appear>
    <messageModal
      v-if="messageModalStatus.show === true"
      :messageModalStatus="messageModalStatus"
      @messagemodal-close="messageModalClose"
    />
  </transition-group>
</template>
<script>
import HttpClient from "../modules/http";
import MessageModal from "./MessageModal.vue";

export default {
  components: { MessageModal },
  data: function () {
    return {
      isLoading: true,
      httpClient: new HttpClient(),
      messageModalStatus: {
        show: false,
        message: [],
        messageType: "info",
        loading: false,
        button: false,
      },
    };
  },
  mounted: function () {},
  computed: {},
  created: function () {
    this.checkAuth();
  },
  methods: {
    checkAuth() {
      this.isLoading = true;
      const url =
        "/auth/check/?accountName=" + this.$cookie.getCookie("accountName");
      this.httpClient.get(
        url,
        this.$cookie.getCookie("token"),
        this.onSuccessAuth,
        this.onErrorAuth
      );
    },
    onSuccessAuth() {},
    onErrorAuth(error) {
      if (
        error != null &&
        error.response != null &&
        "status" in error.response &&
        error.response.status == 401
      ) {
        this.messageModalStatus.message.push(
          "The session has expired. Please log in again."
        );
      } else {
        this.messageModalStatus.message.push(
          "An error has occurred. Please log in again."
        );
      }
      this.messageModalStatus.show = true;
      this.messageModalStatus.messageType = "warn";
      this.messageModalStatus.button = true;
    },
    messageModalClose() {
      this.messageModalStatus.show = false;
      this.$router.push({ name: "login" });
    },
  },
};
</script>
