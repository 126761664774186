<template>
  <div class="row col-md-1">
    <nav class="d-none d-lg-block re-nav sidebar" id="sidebar-sticky">
      <ul class="nav flex-column">
        <li class="nav-item re-menu-head">Menu</li>
        <li class="nav-item re-menu-content">
          <a href="#" @click="goTo(1)" class="nav-link re-menu-font">
            <img
              class="re-icon-md"
              alt="Add New Pre-Alert"
              src="../assets/pass.png"
            />
            Add New Pre-Alert
          </a>
        </li>
        <li class="nav-item re-menu-content">
          <a href="#" @click="goTo(2)" class="nav-link re-menu-font">
            <img class="re-icon-md" alt="Modify" src="../assets/list2.png" />
            Modify A Pre-Alert
          </a>
        </li>
        <!--li
          class="nav-item re-menu-content"
          v-if="$store.getters.userInfo.user.userType == 2 && $store.getters.userInfo.user.isAdmin == 1"
        -->
        <li class="nav-item re-menu-content">
          <a href="#" @click="goTo(3)" class="nav-link re-menu-font">
            <img class="re-icon-md" alt="Upload" src="../assets/list2.png" />
            Upload B/L Doc
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>
<script>
export default {
  props: {},
  components: {},
  data: function () {
    return {
      userinfo: null,
    };
  },
  mounted: function () {
    this.userinfo = this.$store.getters.userInfo;
  },
  methods: {
    goTo(type) {
      this.$store.dispatch("doUpdatePreAlertDetailData", null);
      this.$store.dispatch("doShowPreAlertShowData", null);
      if (type == 1) {
        this.$router.push({
          name: "request",
          params: { type: "new" },
        });
      }
      if (type == 2) {
        this.$router.push({ name: "list" });
      }
      if (type == 3) {
        this.$router.push({ name: "upload" });
      }
    },
  },
};
</script>
