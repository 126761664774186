
function checkDate(strDate) {
    if (!strDate.match(/^\d{4}-\d{2}-\d{2}$/)) {
        return false;
    }
    var y = strDate.split("-")[0];
    var m = strDate.split("-")[1] - 1;
    var d = strDate.split("-")[2];
    var date = new Date(y, m, d);
    if (date.getFullYear() != y || date.getMonth() != m || date.getDate() != d) {
        return false;
    }
    return true;
}

function checkNumber(strNumber) {
    return isFinite(strNumber);
}

function checkMail(strMail){
    var reg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (reg.test(strMail)) {
        return true;
    } else {
        return false;
    }
}

export {checkDate,checkNumber,checkMail}